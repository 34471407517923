import React, { FC } from 'react';
import { connect } from 'react-redux';

import InfoModal from './InfoModal/InfoModal';
import { hideModal } from '../../store/modal/modalActions';
import { SuccessColor } from './ModalButtonsBottom/ModalButtonsBottom';

interface Props {
  payload?: string;
  rightButtonLabel: string;
  title: string;
  submitAction?(): void;
  successColor?: SuccessColor;
}

interface DispatchProps {
  closeModal(): void;
}

export const SimpleInfoModalContent: FC<Props & DispatchProps> = ({
  title,
  closeModal,
  rightButtonLabel,
  submitAction,
  payload,
  successColor = submitAction && 'destructive'
}) => (
  <InfoModal
    title={title}
    closeModal={submitAction ? closeModal : undefined}
    onSuccess={submitAction || closeModal}
    successLabel={rightButtonLabel}
    successColor={successColor}
  >
    <div>{payload}</div>
  </InfoModal>
);

const mapDispatchToProps: DispatchProps = {
  closeModal: hideModal
};

export default connect(null, mapDispatchToProps)(SimpleInfoModalContent);
