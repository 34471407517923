import { combineReducers } from 'redux';

import { errorPageReducer } from '@helper-hooks/error-pages-state-redux-action';

import user from './user/userReducer';
import users from './users/usersReducer';
import loadingReducer from './loading/loadingReducer';
import loadingNestedReducer from './loadingNested/loadingNestedReducer';
import errorReducer from './error/errorReducer';
import insertTask from './insertTask/insertTaskReducer';
import staticData from './staticData/staticDataReducer';
import modal from './modal/modalReducer';
import task from './task/taskReducer';
import toast from './toast/toastReducer';
import match from './match/matchReducer';
import searchTerm from './searchTerm/searchTermReducer';
import artistName from './artistName/artistNameReducer';
import { USER_LOGOUT_SUCCESS } from './user/userActionTypes';
import queryParams from './queryParams/queryParamsReducer';
import notifications from './notifications/notificationsReducer';
import featureFlags from './featureFlags/featureFlagsReducer';
import worklist from './worklist/worklistReducer';
import worklistQueryParams from './worklist/worklistQueryParamsReducer';
import availableTerms from './availableTerms/availableTermsReducer';
import reassignList from './reassignment/reassignReducer';
import UsePreferencesRedux from '@helper-hooks/user-preferences-redux-action';
import alerts from './alerts/alertsReducer';
import userPreferencesReducer from './userPreferences/userPreferencesReducer';

const appReducer = combineReducers({
  user,
  users,
  staticData,
  insertTask,
  modal,
  match,
  searchTerm,
  loadingReducer,
  loadingNestedReducer,
  errorReducer,
  task,
  toast,
  artistName,
  queryParams,
  notifications,
  featureFlags,
  worklist,
  worklistQueryParams,
  error: errorPageReducer,
  availableTerms,
  reassignList,
  userPreferences: UsePreferencesRedux.userPreferencesReducer,
  userPreferencesModal: userPreferencesReducer,
  alerts
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
