import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isUserAuthenticated } from '../store/user/userSelector';
import { ROUTES } from '../common';
import { updateMatch } from '../store/match/matchAction';
import LoaderWithText from '../components/LoaderWithText';

class GuestRoute extends React.Component {
  componentDidMount() {
    const { computedMatch, changeMatch } = this.props;
    changeMatch(computedMatch);
  }

  render() {
    const { isAuthenticated, component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) =>
          !isAuthenticated ? (
            <Suspense fallback={<LoaderWithText />}>
              <Component {...props} />
            </Suspense>
          ) : (
            <Redirect to={ROUTES.worklist} />
          )
        }
      />
    );
  }
}

GuestRoute.propTypes = {
  component: PropTypes.shape().isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  changeMatch: PropTypes.func.isRequired,
  computedMatch: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: isUserAuthenticated(state)
});

const mapDispatchToProps = {
  changeMatch: updateMatch
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestRoute);
