import { SetWorklistQueryParamsTypes, WorklistActionTypes } from './worklistActionTypes';

const initialState = {};

export default function worklistQueryParamsReducer(
  state = initialState,
  action: WorklistActionTypes
) {
  switch (action.type) {
    case SetWorklistQueryParamsTypes.SET_WORKLIST_QUERY_PARAMS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}
