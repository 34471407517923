import * as Effect from 'redux-saga/effects';
import { setError } from '@helper-hooks/error-pages-state-redux-action';

import {
  GetInfiniteWorklistRequestAction,
  GetInfiniteWorklistTypes,
  GetSortWorklistActionTypes,
  GetSortWorklistRequestAction,
  GetWorklistActionTypes,
  GetWorklistTasksRequestAction,
  ExportWorklistRequestAction,
  ReportWorklistRequestAction,
  ExportWorklistTypes,
  ReportWorklistTypes,
  ImportWorklistRequestAction,
  ValidateWorklistRequestAction,
  ImportWorklistTypes,
  ValidateWorklistTypes,
  GetWorklistTasksRequestV2FiltersPayload
} from './worklistActionTypes';
import api from '../../api/api';
import {
  getInfiniteWorklistSuccess,
  getSortWorklistSuccess,
  getWorklistSuccess,
  setWorklistQueryParams,
  reportWorklistSuccess,
  exportWorklistSuccess,
  importWorklistSuccess,
  validateWorklistSuccess,
  getWorklistFailure,
  getInfiniteWorklistFailure,
  setSelectedWorklistTasks
} from './worklistActions';
import {
  // getFilterQueryStringWorklist,
  getQueryParamsWorklist
} from '../queryParams/queryParamsSelector';
import {
  getSelectedWorklistItemsByWorkflowType,
  getWorkflowQueryParamsCursor,
  getWorkflowQueryParamsFilters,
  getWorkflowQueryParamsQueryString,
  getWorkflowQueryParamsSortDirection,
  getWorkflowQueryParamsSortField
} from '../../store/worklist/worklistSelector';
import { worklistSelectedTasks } from './worklistSelector';
import {
  saveExportedFile,
  sortWorklistIds,
  isSingleWorkflowType
} from '../../utils/worklistParams';
import { getPageSize } from '../../store/userPreferences/userPreferencesSelector';

// FIXME: rdar://77932128
const call: any = Effect.call;
const all: any = Effect.all;
const takeLatest: any = Effect.takeLatest;
const select: any = Effect.select;
const put: any = Effect.put;

type GetWorklistTasksRequestActionType = GetWorklistTasksRequestAction;

export function* getWorklistTasksRequest(action: GetWorklistTasksRequestActionType) {
  const sagaNamespace = 'getWorklistTaskRequest';
  const pageSize = yield select(getPageSize);
  try {
    const actionPayload = action.payload as GetWorklistTasksRequestV2FiltersPayload;
    const sortField = yield select(getWorkflowQueryParamsSortField);
    const sortDirection = yield select(getWorkflowQueryParamsSortDirection);
    const cursor = '';
    const payload = {
      queryString: actionPayload.query,
      sortField,
      sortDirection,
      cursor,
      pageSize
    };
    const res = yield call(api.worklist.tasksV2Filters, payload);
    yield put(
      setWorklistQueryParams({
        sortDirection,
        sortField,
        cursor: res.data.cursor,
        query: actionPayload.query,
        filters: actionPayload.filters
      })
    );
    yield put(getWorklistSuccess(res.data));
    yield put(setSelectedWorklistTasks({ selectedIds: [] }));
  } catch (err) {
    console.log(err);
    const { status = '' } = err && err.response;
    const correlationKey = err.response.config.headers['x-apple-jingle-correlation-key'];
    yield put(getWorklistFailure(err));
    yield put(
      setError({
        errorCode: status,
        correlationKey,
        errorMessage: `${err.message} - ${sagaNamespace}`,
        componentStack: err.stack
      })
    );
  }
}

export function* getInfiniteWorklistRequest(action: GetInfiniteWorklistRequestAction) {
  const sagaNamespace = 'getInfiniteWorklistRequest';
  try {
    const sortField = yield select(getWorkflowQueryParamsSortField);
    const sortDirection = yield select(getWorkflowQueryParamsSortDirection);
    const cursor = yield select(getWorkflowQueryParamsCursor);
    const pageSize = yield select(getPageSize);
    const query = yield select(getWorkflowQueryParamsQueryString);
    const filters = yield select(getWorkflowQueryParamsFilters);
    const payload = {
      queryString: query,
      sortField,
      sortDirection,
      cursor,
      pageSize
    };
    const res = yield call(api.worklist.tasksV2Filters, payload);

    yield put(
      setWorklistQueryParams({
        sortDirection,
        sortField,
        cursor: res.data.cursor,
        query,
        filters
      })
    );
    yield put(getInfiniteWorklistSuccess(res.data));
  } catch (err) {
    const correlationKey = err.response.config.headers['x-apple-jingle-correlation-key'];
    yield put(getInfiniteWorklistFailure(err));
    yield put(
      setError({
        errorCode: err.response.status,
        correlationKey,
        errorMessage: `${err.message} - ${sagaNamespace}`,
        componentStack: err.stack
      })
    );
  }
}

export function* getSortWorklistRequest(action: GetSortWorklistRequestAction) {
  const sagaNamespace = 'getSortWorklistRequest';
  try {
    const { sortField, sortDirection } = action.payload;
    const cursor = '';
    const pageSize = yield select(getPageSize);
    const query = yield select(getWorkflowQueryParamsQueryString);
    const filters = yield select(getWorkflowQueryParamsFilters);

    const payload = {
      queryString: query,
      sortField,
      sortDirection,
      cursor,
      pageSize
    };

    const res = yield call(api.worklist.tasksV2Filters, payload);

    yield put(
      setWorklistQueryParams({
        sortDirection,
        sortField,
        cursor: res.data.cursor,
        query,
        filters
      })
    );
    yield put(getSortWorklistSuccess(res.data));
    yield put(setSelectedWorklistTasks({ selectedIds: [] }));
  } catch (err) {
    const correlationKey = err.response.config.headers['x-apple-jingle-correlation-key'];
    yield put(getInfiniteWorklistFailure(err));
    yield put(
      setError({
        errorCode: err.response.status,
        correlationKey,
        errorMessage: `${err.message} - ${sagaNamespace}`,
        componentStack: err.stack
      })
    );
  }
}

export function* reportWorklistRequest(action: ReportWorklistRequestAction) {
  const sagaNamespace = 'reportWorklistRequest';
  try {
    const tasks = yield select(worklistSelectedTasks);
    const { filter } = yield select(getQueryParamsWorklist);
    const {
      selectedMusicTaskIds,
      selectedVideoTaskIds,
      selectedAppsCanvasTaskIds,
      selectedCopyWorkflowTaskIds
    } = sortWorklistIds(tasks);

    const payload = {
      filterParams: filter
    };

    let blob;
    let blobArray = [];

    if (selectedMusicTaskIds.length > 0) {
      blob = yield call(api.worklist.reportTasks, {
        ...payload,
        type: 'MUSIC',
        selectedTasks: selectedMusicTaskIds
      });
      blobArray.push(blob.data);
    }

    if (selectedVideoTaskIds.length > 0) {
      blob = yield call(api.worklist.reportTasks, {
        ...payload,
        type: 'VIDEO',
        selectedTasks: selectedVideoTaskIds
      });
      blobArray.push(blob.data);
    }

    if (selectedAppsCanvasTaskIds.length > 0) {
      blob = yield call(api.worklist.reportTasks, {
        ...payload,
        type: 'APP',
        selectedTasks: selectedAppsCanvasTaskIds
      });
      blobArray.push(blob.data);
    }

    if (selectedCopyWorkflowTaskIds.length > 0) {
      blob = yield call(api.worklist.reportTasks, {
        ...payload,
        type: 'COPY_WORKFLOW',
        selectedTasks: selectedCopyWorkflowTaskIds
      });
      blobArray.push(blob.data);
    }

    const { contentType, fileName } = blob;
    saveExportedFile(blobArray, contentType, fileName);
    yield put(reportWorklistSuccess());
  } catch (err) {
    const { status = '', config = {} } = err && err.response;
    const correlationKey = config && config.headers['x-apple-jingle-correlation-key'];
    yield put(
      setError({
        errorCode: status,
        correlationKey,
        errorMessage: `${err.message} - ${sagaNamespace}`,
        componentStack: err.stack
      })
    );
  }
}

export function* exportWorklistRequest(action: ExportWorklistRequestAction) {
  const sagaNamespace = 'exportWorklistRequest';
  try {
    const tasks = yield select(worklistSelectedTasks);
    const workflowTypes = yield select(getSelectedWorklistItemsByWorkflowType);
    const { filter } = yield select(getQueryParamsWorklist);

    const isSingleWorkflow = isSingleWorkflowType(tasks);

    // Validate if it's single workflow or not
    if (isSingleWorkflow) {
      const type = workflowTypes[0];
      const selectedTasksIds = tasks.map((task: any) => task.workflowGuuid);

      let blobArray = [];
      let blob = yield call(api.worklist.exportTasks, {
        filterParams: filter,
        type,
        selectedTasks: selectedTasksIds
      });
      blobArray.push(blob.data);

      const { contentType, fileName } = blob;
      saveExportedFile(blobArray, contentType, fileName);
      yield put(exportWorklistSuccess());
    }
  } catch (err) {
    const { status = '', config = {} } = err && err.response;
    const correlationKey = config && config.headers['x-apple-jingle-correlation-key'];
    yield put(
      setError({
        errorCode: status,
        correlationKey,
        errorMessage: `${err.message} - ${sagaNamespace}`,
        componentStack: err.stack
      })
    );
  }
}

export function* importWorklistRequest(action: ImportWorklistRequestAction) {
  const sagaNamespace = 'importWorklistRequest';
  try {
    const { payload } = action;
    const res = yield call(api.worklist.importTasks, {
      ...payload
    });
    yield put(importWorklistSuccess(res.data));
  } catch (err) {
    const { status = '', config = {} } = err && err.response;
    const correlationKey = config && config.headers['x-apple-jingle-correlation-key'];
    yield put(
      setError({
        errorCode: status,
        correlationKey,
        errorMessage: `${err.message} - ${sagaNamespace}`,
        componentStack: err.stack
      })
    );
  }
}

export function* validateWorklistRequest(action: ValidateWorklistRequestAction) {
  const sagaNamespace = 'validateWorklistRequest';
  try {
    const { payload } = action;
    const res = yield call(api.worklist.validateTasks, {
      ...payload
    });
    yield put(validateWorklistSuccess(res.data));
  } catch (err) {
    const { status = '', config = {} } = err && err.response;
    const correlationKey = config && config.headers['x-apple-jingle-correlation-key'];
    yield put(
      setError({
        errorCode: status,
        correlationKey,
        errorMessage: `${err.message} - ${sagaNamespace}`,
        componentStack: err.stack
      })
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GetWorklistActionTypes.GET_WORKLIST_REQUEST, getWorklistTasksRequest),
    takeLatest(GetInfiniteWorklistTypes.GET_INFINITE_WORKLIST_REQUEST, getInfiniteWorklistRequest),
    takeLatest(GetSortWorklistActionTypes.GET_SORT_WORKLIST_REQUEST, getSortWorklistRequest),
    takeLatest(ReportWorklistTypes.REPORT_WORKLIST_REQUEST, reportWorklistRequest),
    takeLatest(ExportWorklistTypes.EXPORT_WORKLIST_REQUEST, exportWorklistRequest),
    takeLatest(ImportWorklistTypes.IMPORT_WORKLIST_REQUEST, importWorklistRequest),
    takeLatest(ValidateWorklistTypes.VALIDATE_WORKLIST_REQUEST, validateWorklistRequest)
  ]);
}
