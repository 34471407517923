import { FilterRequest } from './FilterRequest';
import { SortRequest } from './SortRequest';
import { SearchRequest } from './SearchRequest';

const defaultPageLimit = 50;

export class SearchRequestBuilder {
  private nextCursor: string = '';
  private limit: number = 0;
  private sort: SortRequest[] = [];
  private filter: FilterRequest[] = [];

  withLimit(limit: number): SearchRequestBuilder {
    this.limit = limit;
    return this;
  }

  withNextCursor(nextCursor: string): SearchRequestBuilder {
    this.nextCursor = nextCursor;
    return this;
  }

  withSort(sortItems: SortRequest[]): SearchRequestBuilder {
    sortItems && sortItems.forEach((sortItem) => this.sort.push(sortItem));
    return this;
  }

  withFilter(filterItems: FilterRequest[]): SearchRequestBuilder {
    filterItems && filterItems.forEach((filterItem) => this.filter.push(filterItem));
    return this;
  }

  build() {
    if (this.limit <= 0) this.limit = defaultPageLimit;

    return new SearchRequest(this.limit, this.nextCursor, this.sort, this.filter);
  }
}
