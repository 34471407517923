import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@tidbits/react-tidbits';
import _ from 'lodash';
import { withModal } from '@providers/modal-provider';
import InfoModal from '@ui-components/info-modal';
import { FilterConfigType } from '@providers/types-provider';
import UserPreferencesRedux from '@helper-hooks/user-preferences-redux-action';
import { PresetType } from '@ui-components/table-filters';
import ImportFilters from './ImportFilters';
import { getUserPreferences } from '../../../store/userPreferences/userPreferencesSelector';
import { TOAST_TYPES } from '../../../common';
import { showToast } from '../../../store/toast/toastActions';

interface Props {
  filterConfig: FilterConfigType[];
  onClose: () => void;
}

const ImportFiltersModal: FC<Props> = ({ filterConfig, onClose }) => {
  const dispatch = useDispatch();
  const currentUserPreferences = useSelector(getUserPreferences);
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>([]);
  const [importedPresets, setImportedPresets] = useState<PresetType[]>([]);

  const handleOnSuccessButton = () => {
    const selectedImportedPresets = importedPresets.filter((preset: PresetType) => {
      return selectedFilterIds.includes(preset.id);
    });
    const { filterPresets } = currentUserPreferences;
    const filterPresetsCopy = _.cloneDeep(filterPresets.list).concat(selectedImportedPresets);
    const preferences = {
      list: filterPresetsCopy
    };
    dispatch(
      UserPreferencesRedux.userPreferencesAction.putUserPreferenceRequest(
        'workflow',
        'filterPresets',
        preferences,
        currentUserPreferences
      )
    );
    onClose();
    setTimeout(() => {
      dispatch(
        showToast(true, {
          toastType: TOAST_TYPES.success,
          toastMessage: {
            translateKey: 'Presets successfully imported.',
            translateData: null
          }
        })
      );
    }, 1000);
  };

  return (
    <InfoModal
      isFullHeight
      title={'Import Filter Presets'}
      closeModal={onClose}
      closeLabel="Cancel"
      onSuccess={handleOnSuccessButton}
      successLabel={'Import'}
    >
      <Box>
        <ImportFilters
          selectedFilterIds={selectedFilterIds}
          setSelectedFilterIds={setSelectedFilterIds}
          importedPresets={importedPresets}
          setImportedPresets={setImportedPresets}
          filterConfig={filterConfig}
        />
      </Box>
    </InfoModal>
  );
};

export default withModal(ImportFiltersModal);
