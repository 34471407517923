import { call, put, all, select, takeLatest } from 'redux-saga/effects';

import { updateBulkTaskFailure, updateBulkTaskSuccess } from './taskActions';
import api from '../../api/api';

import { TOAST_TYPES } from '../../common';
import TRANSLATE_KEYS from '../../public/locales/translateKeys';
import { generateToastMessage } from '../../utils/generalUtils';
import { UPDATE_BULK_TASK_REQUEST } from './taskActionTypes';
import { worklistDataSelector, getWorklistSelectedIds } from '../worklist/worklistSelector';
import { updateSelectedWorklistItemsByAttribute } from '../../components/NewTableFilter/utils';
import { updateWorklistItemsSuccess } from '../worklist/worklistActions';

const getSuccessToastMessage = (attribute: string, updateValue?: boolean) => {
  switch (attribute) {
    case 'isActive':
      const archiveStatus = updateValue ? 'ARCHIVED' : 'UNARCHIVED';
      return `The selected tasks were successfully ${archiveStatus}`;
    case 'readFlag':
      const readStatus = updateValue ? 'READ' : 'UNREAD';
      return `The selected tasks were successfully marked as ${readStatus}`;
    case 'retry':
      return 'Retry action was successful for all selected tasks';
    default:
      return TRANSLATE_KEYS.toast_messages.successful.successful_reassign_task;
  }
};

const getFailureToastMessage = (attribute: string) => {
  switch (attribute) {
    case 'isActive':
      return 'The Archive status was not updated for all selected tasks';
    case 'readFlag':
      return 'The Read status was not updated for all selected tasks';
    case 'retry':
      return 'Retry action failed for all selected tasks';
    default:
      return '';
  }
};

const isAttributeArchiveOrReadStatusOrRetry = (attribute: string) => {
  switch (attribute) {
    case 'isActive':
    case 'readFlag':
    case 'retry':
      return true;
    default:
      return false;
  }
};

const getTaskItemProperty = (attribute: string) => {
  if (attribute === 'readFlag') {
    return 'isRead_bool';
  }
  return attribute;
};

const getTaskItemUpdateValue = (attribute: string, updateValue: string | boolean) => {
  if (attribute === 'readFlag') {
    return String(updateValue);
  } else if (attribute === 'isActive') {
    return Boolean(updateValue);
  } else {
    return updateValue;
  }
};

export function* updateBulkTaskRequest(action: any) {
  const attribute = action.payload.updateAttribute;
  const updateValue = action.payload.updateValue;
  try {
    const worklistItems = yield select(worklistDataSelector);
    const selectedTaskIds = yield select(getWorklistSelectedIds);
    let res;

    if (attribute === 'retry') {
      const payload = action.payload.updateItems.map(
        ({ namespace, taskId }: { namespace: string; taskId: string }) => {
          return { namespace, taskId, action: 'RETRY', command: {} };
        }
      );
      res = yield call(api.task.retryBulkTasks, payload);
    } else {
      res = yield call(api.task.updateBulkTasks, action.payload.updateItems);
    }

    if (isAttributeArchiveOrReadStatusOrRetry(attribute) && res.status === 200) {
      const taskListItemProperty = getTaskItemProperty(attribute);
      const taskListItemUpdateValue = getTaskItemUpdateValue(attribute, updateValue);
      const updatedWorklistItemsByAttribute = updateSelectedWorklistItemsByAttribute(
        worklistItems,
        selectedTaskIds,
        taskListItemProperty,
        taskListItemUpdateValue
      );
      yield put(updateBulkTaskSuccess());
      yield put(updateWorklistItemsSuccess({ items: updatedWorklistItemsByAttribute }));
      yield put(
        generateToastMessage(TOAST_TYPES.success, getSuccessToastMessage(attribute, updateValue))
      );
    } else if (attribute === 'reassign') {
      yield put(generateToastMessage(TOAST_TYPES.success, getSuccessToastMessage('reassign')));
    }
  } catch (err) {
    if (isAttributeArchiveOrReadStatusOrRetry(attribute)) {
      yield put(generateToastMessage(TOAST_TYPES.error, getFailureToastMessage(attribute)));
    }
    // @ts-ignore -- present error if avaialable
    yield put(updateBulkTaskFailure(err?.response?.data?.message));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(UPDATE_BULK_TASK_REQUEST, updateBulkTaskRequest)]);
}
