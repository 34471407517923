import React, { FC, ReactNode } from 'react';
import { Table } from 'semantic-ui-react';
import classNames from 'classnames';

import '../../style/tableCheckboxCells.scss';

import CustomCheckbox from './CustomCheckbox/CustomCheckbox';
import RadioButton from '@ui-components/radio-button';

interface Props {
  checkBoxCustomFilter(dataRow: object): ReactNode;
  dataRow: { id: number; [key: string]: any };
  onCheckboxChange(): void;
  onRadioButtonChange(): void;
  isChecked: boolean;
  stopPropagation(): void;
  isRadioButton?: boolean;
}

const TableCheckboxCells: FC<Props> = ({
  checkBoxCustomFilter,
  dataRow,
  onCheckboxChange,
  onRadioButtonChange,
  isChecked,
  stopPropagation,
  isRadioButton
}) => {
  const classes = classNames('mpe-table__checkbox-cell', {
    'mpe-table__checkbox-cell--no-ellipsis': isRadioButton
  });
  return (
    <Table.Cell onClick={stopPropagation} className={classes}>
      {isRadioButton ? (
        <RadioButton onChange={onRadioButtonChange} value={dataRow.id} checked={isChecked} />
      ) : (
        checkBoxCustomFilter(dataRow) && (
          <CustomCheckbox onChange={onCheckboxChange} value={dataRow.id} checked={isChecked} />
        )
      )}
    </Table.Cell>
  );
};

export default TableCheckboxCells;
