import React, { FC } from 'react';
import styled from 'styled-components';
import { Text, Box } from '@tidbits/react-tidbits';
import { FilterConfigType } from '@providers/types-provider';
import { ActiveFilter } from '@ui-components/table-filters';

interface Props {
  filterKey: string;
  filterValue: ActiveFilter;
  filterConfig: FilterConfigType[];
}

const PresetsDisplayName = styled.span`
  font-weight: 500;
`;

const PresetsFilter = styled(Box)`
  padding-bottom: 5px;
`;

const FilterEntry: FC<Props> = ({ filterKey, filterConfig, filterValue }) => {
  const filterConfigDetails = filterConfig.filter((config: FilterConfigType) => {
    return config.key === filterKey;
  });

  if (filterConfigDetails.length === 1) {
    const filterConfigDetail = filterConfigDetails[0];
    let valueDisplayString = '';
    if (Array.isArray(filterValue.value)) {
      const displayedValues = filterValue.value.map((selectedValue: string) => {
        if (filterConfigDetail.value.optionRenderer) {
          return filterConfigDetail.value.optionRenderer(selectedValue);
        }
        return selectedValue;
      });
      valueDisplayString = displayedValues.join(', ');
    } else {
      valueDisplayString = filterValue.value;
    }

    return (
      <PresetsFilter>
        <Text as="span" textStyle="bodyRegular">
          <PresetsDisplayName>{filterConfigDetails[0].displayName}</PresetsDisplayName>{' '}
          {filterValue.operator} {valueDisplayString}
        </Text>
      </PresetsFilter>
    );
  }
  return null;
};

export default FilterEntry;
