import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import LineClamp from '@ui-components/line-clamp';

const DEFAULT_CLAMPING = 2;
const HYPHENS_DEFAULT_VALUE = 'auto';

const TableCells = ({ columns, dataRow, indexData }) =>
  columns.map((column, indexColumn) => (
    <Table.Cell
      key={`dataRowCell${indexData.toString()}${indexColumn.toString()}`}
      id={`${column.name}${indexData.toString()}${indexColumn.toString()}`}
      className={column.name}
    >
      {/* transform is optional field in table cell header and it can transform for certain cell,
    we want to transform dates to be in format, ect. */}
      <LineClamp
        numberOfLines={column.numberOfClampedLines ? column.numberOfClampedLines : DEFAULT_CLAMPING}
        hyphens={column.addHyphens ? column.addHyphens : HYPHENS_DEFAULT_VALUE}
      >
        {column.transform
          ? column.transform(dataRow[column.name], indexData, dataRow)
          : dataRow[column.name]}
      </LineClamp>
    </Table.Cell>
  ));

TableCells.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  dataRow: PropTypes.shape({}).isRequired,
  indexData: PropTypes.number.isRequired
};

export default TableCells;
