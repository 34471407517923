import {
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  CREATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  GET_USERS_INFINITE_SUCCESS,
  GET_USERS_FOR_REASSIGN_SUCCESS
} from './usersActionTypes';
import { JOB_TYPES } from '../../common';

const initialState = {
  results: [],
  totalItems: 0
};

export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USERS_SUCCESS:
    case GET_USERS_FOR_REASSIGN_SUCCESS:
      return {
        ...state,
        results: [...action.data.items],

        totalItems: action.data.totalItems
      };
    case GET_USERS_FAILURE:
      return {
        ...state
      };
    case GET_USERS_INFINITE_SUCCESS:
      return {
        ...state,
        results: [...state.results, ...action.data.items],

        totalItems: action.data.totalItems
      };
    case CREATE_USER_SUCCESS: {
      const newTotalItems = state.totalItems + 1;
      return {
        ...state,
        results: [...state.results, action.user],
        totalItems: newTotalItems
      };
    }
    case DELETE_USER_SUCCESS: {
      const mapUsers = state.results.map((user) => {
        if (user.id === action.id) {
          const userToChange = user;
          userToChange.role = JOB_TYPES.dormant;
          return userToChange;
        }
        return user;
      });
      return { ...state, results: [...mapUsers] };
    }
    case EDIT_USER_SUCCESS: {
      const mapUsers = state.results.map((user) => {
        if (user.id !== action.user.id) return user;
        return { ...action.user };
      });
      return { ...state, results: [...mapUsers] };
    }

    default:
      return state;
  }
}
