import React, { FC, ReactNode } from 'react';
import { Table } from 'semantic-ui-react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

import TableCheckboxCell from './TableCheckboxCells';
import TableCells from './TableCells';
import TableActionCells from './TableActionCells';

interface Props {
  data: any; // TODO: Update with type once we realise what type this should be
  columns: object[];
  addCustomClassForIndex(indexData: number, dataRow: object): ClassValue;
  activeIndex: number;
  checkBoxHeader: boolean;
  checkBoxCustomFilter(): ReactNode;
  checkedDataIds: number[];
  actionColumns: (() => void)[];
  setActive(indexData: number): void;
  onRowClick(indexData: number, dataRow: object): void;
  onRowDoubleClick(indexData: number, dataRow: object): void;
  onCheckboxChange(): void;
  onRadioButtonChange(): void;
  actionButton(): void;
  stopPropagation(): void;
  allowMultipleSelect: boolean;
  isAssignment: boolean;
  actionColumnsVisible?: boolean;
  isRadioButton?: boolean;
}

const TableRows: FC<Props> = ({
  data,
  columns,
  addCustomClassForIndex,
  activeIndex,
  checkBoxHeader,
  checkBoxCustomFilter,
  checkedDataIds,
  actionColumns,
  setActive,
  onRowClick,
  onRowDoubleClick,
  onCheckboxChange,
  onRadioButtonChange,
  actionButton,
  stopPropagation,
  isAssignment,
  actionColumnsVisible = true,
  isRadioButton
}) =>
  data.map((dataRow: any, indexData: number) => {
    const dataColumnId = dataRow.id || 0;
    const tableRowClasses = classNames(
      addCustomClassForIndex(indexData, dataRow),
      'mpe-table-body__row'
    );

    return (
      <Table.Row
        key={dataColumnId + indexData.toString()}
        id={dataColumnId}
        className={tableRowClasses}
        onMouseEnter={() => setActive(indexData)}
        onMouseLeave={() => setActive(-1)}
        onClick={() => onRowClick(indexData, dataRow)}
        onDoubleClick={() => onRowDoubleClick(indexData, dataRow)}
        active={indexData === activeIndex}
      >
        {checkBoxHeader && isAssignment && (
          <TableCheckboxCell
            checkBoxCustomFilter={checkBoxCustomFilter}
            onCheckboxChange={onCheckboxChange}
            onRadioButtonChange={onRadioButtonChange}
            dataRow={dataRow}
            stopPropagation={stopPropagation}
            isChecked={checkedDataIds.indexOf(dataColumnId) >= 0}
            isRadioButton={isRadioButton}
          />
        )}
        <TableCells dataRow={dataRow} columns={columns} indexData={indexData} />
        {actionColumnsVisible && (
          <TableActionCells
            actionColumns={actionColumns}
            actionButton={actionButton}
            activeIndex={activeIndex}
            dataRow={dataRow}
            indexData={indexData}
            dataColumnId={dataColumnId}
          />
        )}
      </Table.Row>
    );
  });

export default TableRows;
