import {
  GET_TASKS_SUCCESS,
  GET_TASKS_FAILURE,
  REASSIGN_TASK_SUCCESS,
  SET_SELECTED_TASKS,
  GET_TASKS_INFINITE_SUCCESS,
  GET_TASKS_RECORD_SUCCESS,
  GET_TASKS_RECORD_FAILURE,
  CREATE_NEW_TASKS_FAILURE,
  RESET_CREATE_TASK_ERROR
} from './insertTaskActionTypes';

const initialState = {
  results: [],
  selectedIndexes: [],
  totalItems: 0,
  totalRecordItems: 0,
  createTaskError: false
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SELECTED_TASKS:
      return { ...state, selectedIndexes: action.selectedIndexes };
    case GET_TASKS_RECORD_SUCCESS:
      return { ...state, totalRecordItems: action.data.total };
    case GET_TASKS_RECORD_FAILURE:
      return { ...state };
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        results: [...action.data.items],

        totalItems: action.data.totalItems
      };
    case GET_TASKS_INFINITE_SUCCESS:
      return {
        ...state,
        results: [...state.results, ...action.data.items],

        totalItems: action.data.totalItems
      };
    case GET_TASKS_FAILURE:
      return { ...state };
    case REASSIGN_TASK_SUCCESS:
      // run over changed tasks from api
      // update current state in store
      let results = [...state.results]; // eslint-disable-line
      for (const task of action.data.data.data) {
        results = results.map((result) => {
          const taskId = Number(task.id);
          if (result.id === taskId) {
            return {
              ...result,
              assignee: task.assignee,
              priority: task.priority === 1
            };
          }
          return result;
        });
      }
      return { ...state, results };
    case CREATE_NEW_TASKS_FAILURE:
      return { ...state, createTaskError: true };
    case RESET_CREATE_TASK_ERROR:
      return { ...state, createTaskError: false };
    default:
      return state;
  }
}
