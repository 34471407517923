import { GetAlertsActionType } from './alertsActionTypes';

export default function alerts(state = [], action = { type: '', payload: [] }) {
  switch (action.type) {
    case GetAlertsActionType.GET_ALERTS_SUCCESS:
      return [...action.payload];
    case GetAlertsActionType.GET_ALERTS_FAILURE:
      return [...state];
    default:
      return state;
  }
}
