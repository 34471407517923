import React, { FC } from 'react';
import IconTemplate, { IconType } from '@ui-components/icon-template';

import './infoModalIcon.scss';

export interface InfoModalIconProps {
  iconName?: IconType;
  iconColor?:
    | 'black'
    | 'white'
    | 'grey'
    | 'grey-dark'
    | 'grey-light'
    | 'red'
    | 'green'
    | 'blue'
    | 'yellow'
    | string;
}

const InfoModalIcon: FC<InfoModalIconProps> = ({ iconName = 'attention', iconColor }) => (
  <div className="mpe-info-modal_icon" id="InfoModalIcon">
    <IconTemplate name={iconName} color={iconColor} className="mpe-info-modal-wrapper-class" />
  </div>
);

export default InfoModalIcon;
