import { UserToReassignV2 } from '@providers/types-provider';
import {
  GetReassignListRequestAction,
  GetReassignListSuccessAction,
  TaskReassignActionTypes,
  ClearReassignListAction
} from './reassignTypes';

export const getReassignListRequest = (
  payload: GetReassignListRequestAction['payload']
): GetReassignListRequestAction => ({
  type: TaskReassignActionTypes.GET_REASSIGN_LIST_REQUEST,
  payload
});

export const getReassignListSuccess = (
  payload: UserToReassignV2[]
): GetReassignListSuccessAction => ({
  type: TaskReassignActionTypes.GET_REASSIGN_LIST_SUCCESS,
  payload
});

export const clearReassignList = (): ClearReassignListAction => ({
  type: TaskReassignActionTypes.CLEAR_REASSIGN_LIST
});
