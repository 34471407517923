import { AvailableTerms, AvailableTerm } from '../../common/types';
import { AvailableTermsActionTypes } from './availableTermsActionTypes';
import { AvailableTermsActions } from './availableTermsActions';

const initialState = {} as AvailableTerms;

export default function availableTerms(
  state: AvailableTerms = initialState,
  action: AvailableTermsActions
): AvailableTerms {
  switch (action.type) {
    case AvailableTermsActionTypes.GET_AVAILABLE_TERMS_SUCCESS:
      return [...(action.data as AvailableTerm[])];
    case AvailableTermsActionTypes.GET_AVAILABLE_TERMS_FAILURE:
    default:
      return state;
  }
}
