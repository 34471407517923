import { PERMISSIONS } from '../../common';
import { isAllowedByPermissions } from '../../utils/generalUtils';

const userChangePermissions = [PERMISSIONS.canAddViewEditDeactivateVendorEmployee];

const addCustomClassForData = (user) => {
  if (!user.isEnabled) {
    return 'mpe-table__row-red';
  }
  if (!user.available) {
    return 'mpe-table__row-gray';
  }
  return '';
};
const canChangeUser = (userPermissions) =>
  isAllowedByPermissions(userChangePermissions, userPermissions);

const isUserActionDisabled = (userPermissions) => !canChangeUser(userPermissions);

const canUserBeDeleted = (user) => user.rejectedTasks > 0 || user.assignedTasks > 0;

const isDeleteButtonDisabled = (userPermissions) => isUserActionDisabled(userPermissions);

const isEditButtonDisabled = (userPermissions) => isUserActionDisabled(userPermissions);

const usersListUtils = {
  isDeleteButtonDisabled,
  isUserActionDisabled,
  addCustomClassForData,
  canUserBeDeleted,
  isEditButtonDisabled
};

export default usersListUtils;
