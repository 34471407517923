import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import IconTemplate from '@ui-components/icon-template';

import './notificationsDropdown.scss';

import NotificationsHeader from '../NotificationsHeader/NotificationsHeader';
import NotificationsList from '../NotificationsList/NotificationsList';
import { getNotificationsRequest } from '../../../../store/notifications/notificationsAction';
import { Notification } from '../../../../common/types';
import createLoadingSelector from '../../../../store/loading/loadingSelector';
import { GET_NOTIFICATIONS } from '../../../../store/notifications/notificationsActionTypes';

export interface NotificationsResponse {
  items: Notification[];
  unreadCount: number;
  totalItems: number;
  nextCursor: string;
}

interface State {
  isOpen: boolean;
}

type Props = StateProps & DispatchProps;

export class NotificationsDropdown extends Component<Props, State> {
  state = {
    isOpen: false
  };

  openDropdown = () => {
    this.setState({ isOpen: true });
  };

  closeDropdown = () => {
    this.setState({ isOpen: false });
  };

  onBellClick = () => {
    const { isOpen } = this.state;

    isOpen ? this.closeDropdown() : this.openDropdown();
  };

  onNotificationClick = () => {
    this.closeDropdown();
  };

  onUnreadClick = () => {
    this.openDropdown();
    // TODO should not close the dropdown
    // eslint-disable-next-line
    console.log('onUnreadClick');
  };

  navigationNotificationsClasses = (isOpen: boolean) =>
    classNames('mpe-navigation_notifications', {
      'mpe-navigation_notifications-open': isOpen
    });

  componentDidMount() {
    const { fetchNotifications } = this.props;
    fetchNotifications();
  }

  render() {
    const { isOpen } = this.state;
    const { notifications, loading } = this.props;

    return (
      <div className={this.navigationNotificationsClasses(isOpen)}>
        <Dropdown
          id="notificationsIcon"
          className="mpe-navigation_notifications-dropdown"
          trigger={
            <IconTemplate
              name="bell"
              color="black"
              pointer
              onClick={this.onBellClick}
              className="mpe-navigation_notifications-dropdown_bell"
            />
          }
          pointing="top left"
          icon={false}
          direction="left"
          loading={loading}
          item
          open={isOpen}
          onBlur={this.closeDropdown}
        >
          <Dropdown.Menu className="mpe-navigation_notifications-dropdown-menu">
            <NotificationsHeader />
            <Dropdown.Divider className="mpe-navigation_notifications-dropdown-menu-divider" />
            <NotificationsList
              onNotificationClick={this.onNotificationClick}
              onUnreadClick={this.onUnreadClick}
              notifications={notifications}
            />
          </Dropdown.Menu>
        </Dropdown>
        {notifications.unreadCount > 0 && (
          <div className="mpe-navigation_notification-unread-messages" onClick={this.onBellClick} />
        )}
      </div>
    );
  }
}

interface ReduxState {
  notifications: NotificationsResponse;
}

interface DispatchProps {
  fetchNotifications(): void;
}

interface StateProps {
  notifications: NotificationsResponse;
  loading?: boolean;
}

const loadingNotificationsSelector = createLoadingSelector([GET_NOTIFICATIONS]);

const mapStateToProps = (state: ReduxState): StateProps => ({
  notifications: state.notifications,
  loading: loadingNotificationsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  fetchNotifications: getNotificationsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDropdown);
