import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ToastMessages from '@ui-components/toast-message';

import { toastOpenSelector, toastPropsSelector } from '../../store/toast/toastSelector';
import { hideToast } from '../../store/toast/toastActions';
import i18n from '../../i18n';

const DISAPPEAR_AFTER = 4000;

export const ToastMessageContainer = (): JSX.Element => {
  const dispatch = useDispatch();

  const dismissToast = useCallback(() => {
    dispatch(hideToast());
  }, [dispatch]);

  const open = useSelector(toastOpenSelector);
  const toastProps = useSelector(toastPropsSelector);

  useEffect(() => {
    setTimeout(() => {
      dismissToast();
    }, DISAPPEAR_AFTER);
  }, [dismissToast, open]);

  const { toastType, toastMessage } = toastProps;
  const { translateKey, translateData='' } = toastMessage;

  let message = translateKey ? i18n.t(translateKey, translateData) : translateData;

  return (
    <>
      {open && (
        <ToastMessages toastType={toastType} message={message} />
      )}
    </>
  );
};

export default ToastMessageContainer;
