import React, { FC, FormEvent } from 'react';
import { Form, Input, Text } from '@tidbits/react-tidbits';
import { SettingOptions } from '../../../../../common/types/UserPreferences';
import styled from 'styled-components';

interface Props {
  defaultValue: string;
  options: SettingOptions[]
  handleOnChange: (value: string) => void;
}

const RadioSelectionContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`
export const RadioSelection: FC<Props> = ({
  defaultValue,
  options,
  handleOnChange,
}) => {

  return (
    <RadioSelectionContainer>
      <form>
        {
          options.map(({ value, label }) => {
            return (
              <Form.Label inline sb="spacer15">
                <Input.Radio
                  style={{verticalAlign: 'middle'}}
                  value={value}
                  onClick={(e: FormEvent) => {
                    handleOnChange(value as string);
                  }}
                  checked={value === defaultValue}
                /> <Text as="span">{ label }</Text>
              </Form.Label>
            )
          })
        }
      </form>
    </RadioSelectionContainer>
  )

}