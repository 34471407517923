import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import CustomCheckbox from './CustomCheckbox/CustomCheckbox';

const TableCheckboxMultiSelectHeaders = ({
  checkBoxHeader,
  allowMultipleSelect,
  toggleAllCheckBoxes,
  isChecked
}) =>
  checkBoxHeader ? (
    <Table.HeaderCell collapsing className="no-arrows">
      {allowMultipleSelect && <CustomCheckbox onChange={toggleAllCheckBoxes} checked={isChecked} />}
    </Table.HeaderCell>
  ) : null;

TableCheckboxMultiSelectHeaders.propTypes = {
  checkBoxHeader: PropTypes.bool.isRequired,
  allowMultipleSelect: PropTypes.bool.isRequired,
  toggleAllCheckBoxes: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired
};
export default TableCheckboxMultiSelectHeaders;
