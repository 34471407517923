import { WORKLIST } from '../worklist/worklistActionTypes';

export const SET_SORT = 'SET_SORT';
export const SET_FILTER = 'SET_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const DELETE_FILTER = 'DELETE_FILTER';

export const SET_SORT_WORKLIST = `${SET_SORT}_${WORKLIST}`;

export const SET_SELECTED_FILTERS_WORKLIST = `${SET_SELECTED_FILTERS}_${WORKLIST}`;

export const SET_FILTER_WORKLIST = `${SET_FILTER}_${WORKLIST}`;

export const DELETE_FILTER_WORKLIST = `${DELETE_FILTER}_${WORKLIST}`;

export const CLEAR_ALL_FILTERS_WORKLIST = `${CLEAR_ALL_FILTERS}_${WORKLIST}`;

export const INIT_QUERY_PARAMS = 'INIT_QUERY_PARAMS';
