"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useInterval(callback, delay, condition) {
    const savedCallback = react_1.useRef(callback);
    react_1.useEffect(() => {
        if (delay === 0 || !condition) {
            return;
        }
        const id = setInterval(() => savedCallback.current(), delay);
        if (!condition) {
            clearInterval(id);
        }
        return () => clearInterval(id);
    }, [delay, condition]);
}
exports.default = useInterval;
