import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import classNames from 'classnames';

import '../style/sortTable.scss';

import TableCheckboxHeaderMultiSelect from './components/TableCheckboxMultiSelectHeaders';
import TableHeaders from './components/TableHeaders';
import TableActionHeaders from './components/TableActionHeaders';
import TableRows from './components/TableRows';
import debounce from '../utils/debounce';
import { DEBOUNCE_PERIOD_CLICK, DEBOUNCE_PERIOD_TABLE_RESIZE, DEBOUNCE_PERIOD } from './index';

export class SortTableInternal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      isTableCellClickable: true,
      resizeHeaderIndex: -1,
      isHeaderMoving: false,
      element: null,
      sibling: null,
      startSiblingWidth: 0,
      startWidth: 0,
      startX: 0
    };
    this.updateOnHeaderMove = debounce(this.onHeaderMove, DEBOUNCE_PERIOD_CLICK);
    this.updateServerAfterDebounce = debounce(props.setHeaders, DEBOUNCE_PERIOD_TABLE_RESIZE);
    this.fetchDataDebounced = debounce(props.fetchData, DEBOUNCE_PERIOD);
  }

  componentDidUpdate(prevProps) {
    const { searchTerm } = this.props;
    if (prevProps.searchTerm !== searchTerm) {
      this.fetchDataDebounced();
    }
  }

  onHeaderMove = (isHeaderMoving) => {
    this.setState({
      isHeaderMoving
    });
  };

  onDown = (e) => {
    const element = this.findDraggingTableHeader(e);
    if (!element) return;
    const { checkBoxHeader } = this.props;
    const checkBoxHeaderColumnCount = checkBoxHeader ? 1 : 0;
    const resizeHeaderIndex =
      [...element.parentNode.children].indexOf(element) - checkBoxHeaderColumnCount;
    const siblingElement = element.nextElementSibling;
    const startWidth = element.offsetWidth;
    const startSiblingWidth = siblingElement.offsetWidth;
    const startX = e.pageX;
    this.setState({
      element,
      sibling: siblingElement,
      resizeHeaderIndex,
      startWidth,
      startSiblingWidth,
      startX
    });
    this.updateOnHeaderMove(true);
  };

  onMove = (e) => {
    const { isHeaderMoving, element, startWidth, startX, startSiblingWidth, sibling } = this.state;
    if (isHeaderMoving) {
      const calculatedWidth = Math.min(
        Math.max(startWidth + e.pageX - startX, 100),
        startSiblingWidth + startWidth - 100
      );
      const calculateWidthSibling = startSiblingWidth + startWidth - calculatedWidth;
      element.style.width = `${calculatedWidth}px`;
      sibling.style.width = `${calculateWidthSibling}px`;
    }
  };

  onUp = () => {
    const { isHeaderMoving, element } = this.state;
    let isTableCellClickable = true;

    if (isHeaderMoving && element) {
      const parent = element.closest('tr');
      const columnId = element.getAttribute('data-column-name');
      const columnSize = element.offsetWidth;
      const siblingElement = element.nextElementSibling;
      const siblingColumnId = siblingElement.getAttribute('data-column-name');
      const siblingSize = siblingElement.offsetWidth;
      isTableCellClickable = false;
      this.updateServerAfterDebounce({
        [columnId]: Math.round((columnSize / parent.offsetWidth) * 10000) / 100,
        [siblingColumnId]: Math.round((siblingSize / parent.offsetWidth) * 10000) / 100
      });
    }
    this.setState({
      element: null,
      sibling: null,
      resizeHeaderIndex: -1,
      isTableCellClickable
    });
    this.updateOnHeaderMove(false);
    this.onHeaderMove(false);
  };

  findDraggingTableHeader = (e) => {
    let element = e.target;
    element = element.classList.contains('mpe-table_header-resize')
      ? element
      : element.closest('.mpe-table_header-resize');
    return element;
  };

  getColumnDataForColumn = (columnName) => {
    const { columns } = this.props;
    return columns.find(
      (column) => column.name === columnName || column.sortFieldKey === columnName
    );
  };

  prepareDataForSort = (field) => {
    const sortableField = this.getColumnDataForColumn(field);
    return sortableField && sortableField.sortFieldKey ? sortableField.sortFieldKey : field;
  };

  prepareDataForTable = (sort) => {
    const columnData = this.getColumnDataForColumn(sort);
    return columnData ? columnData.name : sort;
  };

  handleSort = (clickedColumn, isSortable) => () => {
    const { isTableCellClickable } = this.state;
    if (!isSortable) return;
    if (!isTableCellClickable) {
      this.setState({
        isTableCellClickable: true
      });
      return;
    }
    const { fetchData, applySort, sortedColumn, setSelectedIds } = this.props;
    const sortField = this.prepareDataForSort(clickedColumn);
    const nextDirection = this.findNextDirection(sortedColumn, sortField);

    const sort = {
      field: sortField,
      direction: nextDirection
    };
    applySort(sort);
    setSelectedIds([]);
    fetchData();
  };

  getSortColumnName = (sortedColumn) =>
    sortedColumn && sortedColumn.length && sortedColumn[0].field ? sortedColumn[0].field : '';

  getSortDirection = (sortedColumn) => {
    const direction =
      sortedColumn && sortedColumn.length && sortedColumn[0].direction
        ? sortedColumn[0].direction
        : '';
    if (direction === 'asc') return 'ascending';
    if (direction === 'desc') return 'descending';
    return direction;
  };

  findNextDirection = (sort, field) => {
    if (sort) {
      const sortItem = sort.find((s) => s.field === field);
      if (sortItem) {
        return sortItem.direction === 'asc' ? 'desc' : 'asc';
      }
    }
    return 'asc';
  };

  onRowClick = (index, data) => {
    const { onRowClick } = this.props;
    onRowClick(index, data);
  };

  onRowDoubleClick = (index, data) => {
    const { onRowDoubleClick, location, handleAssigneeFilters } = this.props;
    const TASKS_DETAILS_PATH = '/tasks';
    const locationPath = location.path;

    if (locationPath === TASKS_DETAILS_PATH) return;
    onRowDoubleClick(index, data);
    handleAssigneeFilters(data);
  };

  onCheckboxChange = (event, changed) => {
    event.stopPropagation();
    const { setSelectedIds, allowMultipleSelect, checkedDataIds } = this.props;
    const indexOfRemoved = checkedDataIds.indexOf(changed.value);
    if (!allowMultipleSelect) {
      setSelectedIds([changed.value]);
      return;
    }
    if (indexOfRemoved >= 0) {
      const checkedDataIdsCopy = [...checkedDataIds];
      checkedDataIdsCopy.splice(indexOfRemoved, 1);
      setSelectedIds(checkedDataIdsCopy);
    } else {
      setSelectedIds([...checkedDataIds, changed.value]);
    }
  };

  onRadioButtonChange = (event) => {
    event.stopPropagation();
    const { setSelectedIds } = this.props;
    setSelectedIds(event.target.value);
  };

  // eslint-disable-next-line
  setActive = (activeIndex) => {
    // TODO: quick fix for slow loading, there is a bug to fix this
    // this.setState({
    //   activeIndex
    // });
  };

  stopPropagation = (e) => e.stopPropagation();

  // TO DO
  wrapButton = (WrappedButton) => (props) => <WrappedButton {...props} />;

  actionButton = (trigger, indexData, dataRow, activeIndex, id) => {
    const ActionButton = this.wrapButton(trigger);
    const { unselectAll } = this.props;
    return (
      <ActionButton
        className="mpe-table__cell-centered_content"
        indexData={indexData}
        selectedItem={dataRow}
        id={id + indexData}
        onClick={() => unselectAll()}
      />
    );
  };

  render() {
    const {
      data,
      loading,
      columns,
      checkBoxHeader,
      checkBoxCustomFilter,
      allowMultipleSelect,
      addCustomClassForIndex,
      actionColumns,
      className,
      checkedDataIds,
      sortedColumn,
      areAllSelected,
      toggleAllCheckBoxes,
      stickyHeaderSizes,
      actionColumnsVisible,
      isRadioButton,
      isModal
    } = this.props;
    const { activeIndex, isHeaderMoving, resizeHeaderIndex } = this.state;
    const tableBodyClasses = classNames({
      'mpe-table_body': true,
      'mpe-table_loading-opacity': loading
    });

    const tableClasses = classNames({
      [className]: true,
      'mpe-table-resizing': isHeaderMoving
    });

    const wrapperClasses = classNames('mpe-table_wrapper', {
      'mpe-table-modal-overflow': isModal
    });

    const sortedColumnField = this.getSortColumnName(sortedColumn);
    const sortedColumnFieldId = this.prepareDataForTable(sortedColumnField);
    const direction = this.getSortDirection(sortedColumn);

    return (
      !!data.length && (
        <div className={wrapperClasses}>
          <Table
            className={tableClasses}
            sortable
            selectable
            unstackable
            fixed
            striped
            basic="very"
          >
            <Table.Header className="mpe-table_header">
              <Table.Row
                onMouseDown={this.onDown}
                onMouseMove={this.onMove}
                onMouseLeave={this.onUp}
                onMouseUp={this.onUp}
              >
                <TableCheckboxHeaderMultiSelect
                  checkBoxHeader={checkBoxHeader}
                  allowMultipleSelect={allowMultipleSelect}
                  toggleAllCheckBoxes={toggleAllCheckBoxes}
                  isChecked={areAllSelected}
                />
                <TableHeaders
                  columns={columns}
                  sortedColumn={sortedColumnFieldId}
                  direction={direction}
                  onHeaderClick={this.handleSort}
                  resizeIndex={resizeHeaderIndex}
                  stickyHeaderSizes={stickyHeaderSizes}
                />
                {actionColumnsVisible && (
                  <TableActionHeaders actionColumnsLength={actionColumns.length} />
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body className={tableBodyClasses}>
              <TableRows
                isAssignment
                data={data}
                columns={columns}
                addCustomClassForIndex={addCustomClassForIndex}
                activeIndex={activeIndex}
                checkBoxHeader={checkBoxHeader}
                checkBoxCustomFilter={checkBoxCustomFilter}
                checkedDataIds={checkedDataIds}
                actionColumns={actionColumns}
                setActive={this.setActive}
                onRowClick={this.onRowClick}
                onRowDoubleClick={this.onRowDoubleClick}
                onCheckboxChange={this.onCheckboxChange}
                onRadioButtonChange={this.onRadioButtonChange}
                actionButton={this.actionButton}
                stopPropagation={this.stopPropagation}
                actionColumnsVisible={actionColumnsVisible}
                isRadioButton={isRadioButton}
              />
            </Table.Body>
          </Table>
        </div>
      )
    );
  }
}

SortTableInternal.defaultProps = {
  loading: false,
  allowMultipleSelect: false,
  className: '',
  checkBoxHeader: false,
  checkBoxCustomFilter: () => true,
  actionColumns: [],
  onRowClick: () => {},
  onRowDoubleClick: () => {},
  checkedDataIds: [],
  addCustomClassForIndex: () => '',
  setSelectedIds: () => {},
  areAllSelected: false,
  toggleAllCheckBoxes: () => {},
  unselectAll: null,
  sortedColumn: null,
  stickyHeaderSizes: {},
  searchTerm: '',
  isAssignment: false,
  actionColumnsVisible: true,
  isRadioButton: false,
  handleAssigneeFilters: () => {},
  location: '',
  isModal: false
};
SortTableInternal.propTypes = {
  loading: PropTypes.bool,
  allowMultipleSelect: PropTypes.bool,
  checkBoxHeader: PropTypes.bool,
  className: PropTypes.string,
  checkBoxCustomFilter: PropTypes.func,
  addCustomClassForIndex: PropTypes.func,
  checkedDataIds: PropTypes.arrayOf(PropTypes.number),
  data: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  actionColumns: PropTypes.arrayOf(PropTypes.func.isRequired),
  setSelectedIds: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  fetchData: PropTypes.func.isRequired,
  sortedColumn: PropTypes.arrayOf(
    PropTypes.shape({ field: PropTypes.string, direction: PropTypes.string })
  ),
  areAllSelected: PropTypes.bool,
  toggleAllCheckBoxes: PropTypes.func,
  unselectAll: PropTypes.func,
  stickyHeaderSizes: PropTypes.shape({}),
  setHeaders: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  applySort: PropTypes.func.isRequired,
  isAssignment: PropTypes.bool,
  actionColumnsVisible: PropTypes.bool,
  isRadioButton: PropTypes.bool,
  handleAssigneeFilters: PropTypes.func,
  location: PropTypes.string,
  isModal: PropTypes.bool
};

export default SortTableInternal;
