import { GlobalAlerts } from '@ui-components/alert-banner';

export const sortAlerts = (alertsData: GlobalAlerts[]) => {
  const sortedData =
    alertsData.sort((prev: GlobalAlerts, current: GlobalAlerts) => {
      if (new Date(prev.startTime ?? 0).getTime() > new Date(current.startTime ?? 0).getTime()) {
        return 1;
      }
      return -1;
    }) ?? [];
  return sortedData;
};
