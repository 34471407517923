import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import './infoModalContent.scss';

export interface InfoModalContentProps {
  title: string;
  children: string | ReactNode;
  isModalWithTable?: boolean;
  successButtonDisabled?: boolean;
}

const InfoModalContent: FC<InfoModalContentProps> = ({
  title,
  children,
  isModalWithTable = false
}) => {
  const infoModalContentClasses = classNames('mpe-info-modal_content', {
    'mpe-info-modal_content-modal-with-table': isModalWithTable
  });
  const infoModalContentTitleClasses = classNames('mpe-info-modal_content-title', {
    'mpe-info-modal_content-title-modal-with-table': isModalWithTable
  });
  const infoModalContentBodyClasses = classNames('mpe-info-modal_content-body', {
    'mpe-info-modal_content-body-with-table': isModalWithTable
  });
  return (
    <div className={infoModalContentClasses} id="InfoModalContent">
      <h2 className={infoModalContentTitleClasses} id="InfoModalContentTitle">
        {title}
      </h2>
      <div className={infoModalContentBodyClasses} id="InfoModalContentBody">
        {children}
      </div>
    </div>
  );
};

export default InfoModalContent;
