import { capitalizeFirstLetter } from './generalUtils';
import { isEmpty } from 'lodash';
import fileSaver from 'file-saver';

export const getFilterQueryParams = (filters) => {
  let filterQuery = '';
  if (filters) {
    const usableFilters = filters.filter((filter) => filter.value.length > 0);

    usableFilters.forEach((filterItem, index) => {
      filterQuery += getFilterString(filterItem);

      if (filterItem.value.length > 0 && index !== usableFilters.length - 1) {
        filterQuery += ';';
      }
    });
  }

  return filterQuery;
};

export const joinValues = (values) => {
  const joinedValues = values.map((value) => `${value}`).join(',');
  return joinedValues;
};

export const removeSpaceEscapes = (string) => {
  return string.replace(/%20/g, ' ');
};

export const escapeApostrophes = (string) => {
  return string?.replace(/'/g, '%27');
};

export const getUrlQueryParamsFilterV2 = (sortField, sortDirection, cursor, queryString) => {
  let queryUrl = '';
  if (queryString) {
    queryUrl += `q=${queryString}`;
  }

  if (sortField && sortDirection) {
    if (queryUrl) {
      queryUrl += '&';
    }
    queryUrl += `sort=${sortField}%2B${sortDirection}`;
  }

  if (cursor) {
    if (queryUrl) {
      queryUrl += '&';
    }
    queryUrl += `cursor=${encodeURIComponent(cursor)}`;
  }
  return queryUrl;
};

export const getUrlQueryParams = (filterParams, searchTerm, sortField, sortDirection, cursor) => {
  let filterQueryParams = getFilterQueryParams(filterParams);

  const whitespaceRegex = new RegExp(/\s/);
  const filterQueryString = whitespaceRegex.test(filterQueryParams)
    ? filterQueryParams
    : escapeApostrophes(filterQueryParams);
  const parsedSearchTerm = searchTerm?.trim()?.length ? searchTerm : null;
  const searchQueryString = parsedSearchTerm
    ? `text=="${removeSpaceEscapes(parsedSearchTerm)}"`
    : '';

  let query = '';
  if (searchQueryString) {
    if (filterQueryString) {
      query = `(${searchQueryString};${filterQueryString})`;
    } else {
      query = `(${searchQueryString})`;
    }
  } else if (filterQueryString) {
    query = `(${filterQueryString})`;
  }

  let queryUrl = '';
  if (searchTerm || filterQueryParams?.length > 0) {
    queryUrl += `q=${encodeURIComponent(query)}`;
  }

  if (sortField && sortDirection) {
    if (queryUrl) {
      queryUrl += '&';
    }
    queryUrl += `sort=${sortField}%2B${sortDirection}`;
  }

  if (cursor) {
    if (queryUrl) {
      queryUrl += '&';
    }
    queryUrl += `cursor=${encodeURIComponent(cursor)}`;
  }
  return queryUrl;
};

const getFilterString = (filterItem) => {
  const { field, value } = filterItem;
  if (value.length === 0) {
    return '';
  }

  let filterQuery = '';
  // Workaround for: rdar://84128696 (PROD: P2: Conductor+Wrkflow.app: Can't filter on user where task is assigned, also mismatch between assignees)
  if (field === 'assignee') {
    const values = filterItem.value.map((value) => "'" + value.text + "'");
    const joinedValues = joinValues(values);
    filterQuery += `assigneeName=in=(${escapeRegex(joinedValues)})`;
  } else if (field === 'status') {
    const joinedValues = joinValues(value);
    filterQuery += `taskStatus=in=(${joinedValues})`;
  } else if (field === 'dueDate') {
    const possibleDates = value[0];
    const startDate = possibleDates.split(',')[0];
    const endDate = possibleDates.split(',')[1];
    if (startDate === endDate) {
      let newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate() + 1);
      const escapedStartDate = escapeRegex(startDate);
      const escapedNewEndDate = escapeRegex(newEndDate.toISOString());
      filterQuery += `${field}=gt=${escapedStartDate};${field}=lt=${escapedNewEndDate}`;
    } else {
      const escapedStartDate = escapeRegex(startDate);
      const escapedEndDate = escapeRegex(endDate);
      filterQuery += `${field}=gt=${escapedStartDate};${field}=lt=${escapedEndDate}`;
    }
  } else if (field === 'read_flag') {
    filterQuery += `isRead_bool=in=${String(value)}`;
  } else {
    const joinedValues = joinValues(value);
    const escapedJoinedValues = escapeRegex(joinedValues);
    const whitespaceRegex = new RegExp(/\s/);
    let withQuotations = escapedJoinedValues;
    // TODO: rdar://90659731 (Shakespeare UI: Worklist Params Refactor)
    // Tested with double quotations and works the same as single quotations,
    // however RSQL defaults to single quotes
    if (whitespaceRegex.test(escapedJoinedValues)) {
      if (withQuotations.includes("'")) {
        withQuotations = `"${escapedJoinedValues}"`;
      } else {
        withQuotations = `'${escapedJoinedValues}'`;
      }
    }
    filterQuery += `${filterItem.field}=in=(${withQuotations})`;
  }

  return filterQuery;
};

export function escapeRegex(string) {
  //rdar://86109160 ([PROD] [P2] Shakespeare - Bug: Locale-Filter does not return any Results)
  // prettier-ignore
  //eslint-disable-next-line
  let newString = string.replace(/[-^$*+:?()[\]{}]/g, "\$&");
  return newString;
}

/**
 * Converts available terms api shape to dashboard fields shape
 * @param {*} fields
 * @returns
 */
export const mapAvailableTermsToDashboardFields = (fields) => {
  return !isEmpty(fields) && fields.filter(filterField).map(mapField);
};

/**
 * Filters out any fields that doesn't have any pre-existing filters
 * @param {*} param
 * @returns
 */
const filterField = ({ field }) => displayNameMap.hasOwnProperty(field);

/**
 * Massages data to fit the correct shape including converting the options
 * @param {*} param0
 * @returns
 */
const mapField = ({ field, results }) => {
  const options = optionsParser(field, results);
  const updatedField = fieldMap(field);
  const displayName = displayNameMap[field];

  return {
    key: field,
    field: updatedField,
    displayName,
    visible: true,
    filterable: true,
    sortable: true,
    options,
    type: 'string'
  };
};

const fieldMap = (field) => {
  if (field === 'taskStatus') {
    return 'status';
  }
  return field;
};

/**
 * Converts the options into the { value, text } format
 * @param {*} field
 * @param {*} results
 * @returns
 */
const optionsParser = (field, results) => {
  const keys = Object.keys(results);
  switch (field) {
    case 'taskStatus':
      return keys.map((key) => ({ value: key, text: taskStatusParser(key) }));
    case 'state':
      return keys.map((key) => ({
        value: key,
        text: removeUnderscoreAndCapitalize(key)
      }));
    case 'workflowType':
      return keys.map((key) => ({
        value: key,
        text: removeUnderscoreAndCapitalize(key)
      }));
    case 'genres':
    default:
      return keys.map((key) => ({ value: key, text: key }));
  }
};

const taskStatusParser = (key) => {
  return capitalizeFirstLetter(key);
};

const removeUnderscoreAndCapitalize = (key) => {
  return key
    .split('_')
    .map((s) => capitalizeFirstLetter(s))
    .join(' ');
};

const displayNameMap = {
  email: 'Assignee',
  genres: 'Genres',
  locale: 'Locale',
  taskStatus: 'Task Status',
  state: 'Progress',
  workflowType: 'Workflow Type',
  placementTypes_text_full_match: 'Placement Type',
  keyword_text_general: 'Keyword',
  classifier_text_general: 'Classifier',
  project_text_general: 'Project',
  request_type_text_general: 'Request Type',
  lob_text_full_match: 'LOB',
  revision_round_integer: 'Revision',
  backstage_task_id_text_general: 'ID'
};

export const sortWorklistIds = (tasks) => {
  let selectedVideoTaskIds = [];
  let selectedMusicTaskIds = [];
  let selectedAppsCanvasTaskIds = [];
  let selectedCopyWorkflowTaskIds = [];

  tasks.forEach((task) => {
    if (task.workflowType === 'MUSIC_CONTENT') {
      selectedMusicTaskIds.push(task.workflowGuuid);
    } else if (task.workflowType === 'VIDEO_CONTENT') {
      selectedVideoTaskIds.push(task.workflowGuuid);
    } else if (task.workflowType === 'APPS_CANVAS') {
      selectedAppsCanvasTaskIds.push(task.workflowGuuid);
    } else if (task.workflowType === 'COPY_WORKFLOW') {
      selectedCopyWorkflowTaskIds.push(task.workflowGuuid);
    }
  });
  return {
    selectedMusicTaskIds,
    selectedVideoTaskIds,
    selectedAppsCanvasTaskIds,
    selectedCopyWorkflowTaskIds
  };
};

export const saveExportedFile = (blobArray, contentType, fileName) => {
  const blob = new Blob(blobArray, {
    type: contentType
  });

  fileSaver.saveAs(blob, fileName);
};

export const removeFilters = (filterToRemove, currentFilters) => {
  return currentFilters.filter((fil) => fil.field !== filterToRemove.name);
};
/**
 * This function validates if there is only one workflowType in the entire list of tasks.
 */
export const isSingleWorkflowType = (tasks) => {
  const musicTasks = tasks.find((task) => task === 'MUSIC_CONTENT');
  const videoTasks = tasks.find((task) => task === 'VIDEO_CONTENT');
  const appsTasks = tasks.find((task) => task === 'APPS_CANVAS');

  let workflowTypes = 0;
  if (musicTasks) workflowTypes++;
  if (videoTasks) workflowTypes++;
  if (appsTasks) workflowTypes++;
  return workflowTypes > 1 ? false : true;
};

export const getFilterSearchParams = (queryParams) => {
  let params = '';
  if (queryParams.searchTerm?.length) params += `filterTerm=${queryParams.searchTerm}&`;
  if (queryParams.nextCursor) params += `offset=${queryParams.nextCursor}&`;
  return params;
};

export const isAlphabeticalField = (field) => {
  switch (field) {
    case 'assignee':
    case 'genres':
    case 'taskStatuses':
    case 'workflowTypes':
    case 'locales':
      return true;
    case 'read_flag':
    default:
      return false;
  }
};
