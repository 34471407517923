import { call, put, takeLatest, all } from 'redux-saga/effects';
import { availableTermsSuccess, availableTermsFailure } from './availableTermsActions';
import { AvailableTermsActionTypes } from './availableTermsActionTypes';
import api from '../../api/api';

export function* availableTermsRequest() {
  try {
    const availableTerms = yield call(api.worklist.availableTerms);
    yield put(availableTermsSuccess(availableTerms));
  } catch (err) {
    console.log(err);
    yield put(availableTermsFailure(err.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(AvailableTermsActionTypes.GET_AVAILABLE_TERMS_REQUEST, availableTermsRequest)
  ]);
}
