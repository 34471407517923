import { createSelector } from 'reselect';
import { ReduxState } from '../../common/types';

export const featureFlagSelector = (state: ReduxState) => state.featureFlags;

export const getFeatureFlags = (featureName: string) =>
  createSelector(featureFlagSelector, (featureFlags) =>
    featureFlags.find((featureFlag) => featureFlag.featureFlagName === featureName)
  );

export const makeFeatureFlagSelector = (featureName: string) =>
  createSelector(getFeatureFlags(featureName), (featureFlag) =>
    featureFlag ? featureFlag.active : false
  );
