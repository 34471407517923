import shelfReducer from './shelfReducer';
import { GET_COLLABORATION_SUMMARY_SUCCESS } from './taskActionTypes';

const remapShelves = (shelves, action) => {
  const remappedShelves = shelves
    ? shelves.map((shelf) => {
        if (shelf.id === action.shelfId || !action.shelfId) return shelfReducer(shelf, action);
        return shelf;
      })
    : [];
  return remappedShelves;
};

const addSummaryToShelf = (shelf, collaborationSummaries) => {
  const collaborationSummary =
    collaborationSummaries &&
    collaborationSummaries.find((item) => item.sessionId === shelf.collaborationSummary.sessionId);
  if (collaborationSummary) {
    return shelfReducer(shelf, {
      collaborationSummary,
      type: GET_COLLABORATION_SUMMARY_SUCCESS
    });
  }
  return shelf;
};

export const addCollaborationSummaryToShelves = (shelves, action) => {
  const remappedShelves = shelves
    ? shelves.map((shelf) => addSummaryToShelf(shelf, action.data))
    : [];
  return remappedShelves;
};

export default remapShelves;
