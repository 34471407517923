import React, { FC } from 'react';
import { Form, Input, Box } from '@tidbits/react-tidbits';
import { SettingOptions } from '../../../../../common/types/UserPreferences';
import styled from 'styled-components';

interface Props {
  label: string;
  defaultValue: string | number;
  options: SettingOptions[];
  handleOnChange: (value: string | number) => void;
}

const DropdownSelectionBox = styled(Box)`
  width: 50%;
  margin-bottom: 20px;
`

export const DropdownSelection: FC<Props> = ({
  label,
  defaultValue,
  options,
  handleOnChange
}) => {
  return (
    <DropdownSelectionBox>
      <Form.Label htmlFor="countries" mb="spacer10">
        { label }
      </Form.Label>
      <Input.DropdownSelect value={defaultValue} onChange={handleOnChange}>
        {
          options.map(({ value, label }) => 
            <option value={value}>{ label } </option>
          )
        }
      </Input.DropdownSelect>
    </DropdownSelectionBox>
  )
}