import { SET_SEARCH_TERM, RESET_SEARCH_TERM, INIT_SEARCH_TERM } from './searchTermActionTypes';

const initialState = '';

export default function searchTerm(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH_TERM:
    case INIT_SEARCH_TERM:
      return action.data;
    case RESET_SEARCH_TERM:
      return '';
    default:
      return state;
  }
}
