import { FilterRequest, FilterRequestOperation, ContainsAllFilterRequest } from './FilterRequest';
import { SortRequest } from './SortRequest';
import { SearchRequestBuilder } from './SearchRequestBuilder';
import { SearchRequestForSuperScalar } from './SearchRequestForSuperScalar';
import { RSQLFilterFormatSimple } from './RSQLFilterFormatSimple';
import { RSQLFilterFormatComplex } from './RSQLFilterFormatComplex';
import { transformToRSQL } from '@molgenis/rsql';

export class SearchRequest {
  limit: number;
  nextCursor: string;
  sort: SortRequest[];
  filter: FilterRequest[];

  constructor(limit: number, nextCursor: string, sort: SortRequest[], filter: FilterRequest[]) {
    this.limit = limit;
    this.nextCursor = nextCursor;
    this.sort = sort;
    this.filter = filter;
  }

  static builder(): SearchRequestBuilder {
    return new SearchRequestBuilder();
  }

  toSuperScalarRequest(): SearchRequestForSuperScalar {
    const q = this.getQuery();
    const sort = this.getSort();
    return {
      ...(q && { q }),
      ...(sort && sort.length && { sort }),
      pageSize: this.limit,
      cursor: this.nextCursor
    };
  }

  private getSort(): string[] {
    return this.sort.map((sortItem) => sortItem.field + '+' + sortItem.direction);
  }

  private getQuery(): string {
    const filter = this.filter.filter(
      (filterItem) => filterItem.value && filterItem.value.length > 0
    );
    if (filter.length === 0) return '';

    if (filter.length === 1)
      // @ts-ignore
      return transformToRSQL(this.toSimpleRsqlRequest(filter[0]));
    // @ts-ignore
    return transformToRSQL(this.toComplexRsqlRequest(filter));
  }

  private toComplexRsqlRequest(filter: FilterRequest[]): RSQLFilterFormatComplex {
    const operator = 'AND';
    const operands = filter.map((filterItem) => this.toSimpleRsqlRequest(filterItem));
    return new RSQLFilterFormatComplex(operator, operands);
  }

  // TODO: BE to remove workflowType==null, then remove this method;
  private containsMusic(argumentsToFilter: string[]) {
    const indexOfMusic = argumentsToFilter.indexOf('MUSIC');
    const indexOfNull = argumentsToFilter.indexOf('null');
    return indexOfMusic !== -1 && indexOfNull === -1;
  }

  private toSimpleRsqlRequest(
    filterItem: FilterRequest
  ): RSQLFilterFormatSimple | RSQLFilterFormatComplex {
    if (filterItem.operation === FilterRequestOperation.BETWEEN) {
      return this.toBetweenRsqlRequest(filterItem);
    } else if (filterItem.operation === FilterRequestOperation.CONTAINS_ALL) {
      return this.toContainsAllRsqlRequest(filterItem);
    } else {
      const selector = filterItem.field;

      // TODO: radar on BE to remove workflowType==null, then remove if statement;

      // This is introduced because of the legacy code we have for TaskList, some shakespeare tasks (old ones) have workflowType == null in SOLR
      // With BE team we introduced this code in order to send right query for this type that can be:
      // for AUTOTUNE: workflowType==AUTOTUNE
      // for MUSIC workflowType=in=(null,MUSIC)
      // if both filters are selected workflowType=in=(null,AUTOTUNE,MUSIC)
      const queryArray = [...filterItem.value];
      if (selector === 'workflowType' && this.containsMusic(filterItem.value)) {
        queryArray.unshift('null');
      }
      const comparison = queryArray.length > 1 ? '=in=' : '==';
      const argumentsToFilter = queryArray.length > 1 ? queryArray : queryArray[0];
      return new RSQLFilterFormatSimple(selector, comparison, argumentsToFilter);
    }
  }
  private toBetweenRsqlRequest(filterItem: FilterRequest): RSQLFilterFormatComplex {
    const operator = 'AND';
    const dates = filterItem.value[0].toString().split(',');
    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[1]);
    return new RSQLFilterFormatComplex(operator, [
      new RSQLFilterFormatSimple(filterItem.field, '=gt=', startDate.toISOString()),
      new RSQLFilterFormatSimple(filterItem.field, '=lt=', endDate.toISOString())
    ]);
  }

  private toContainsAllRsqlRequest(filterItem: ContainsAllFilterRequest): RSQLFilterFormatComplex {
    const operator = 'AND';
    const operands = filterItem.value.map(
      (value) => new RSQLFilterFormatSimple(filterItem.field, '==', value)
    );
    return new RSQLFilterFormatComplex(operator, operands);
  }
}
