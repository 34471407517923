import React, { FC } from 'react';
import { Loader } from 'semantic-ui-react';

import TRANSLATE_KEYS from '../../public/locales/translateKeys';
import i18n from '../../i18n';

interface Props {
  translateKey?: string;
  wrapperClass?: string;
  innerClass?: string;
}

const LoaderWithText: FC<Props> = ({
  translateKey = TRANSLATE_KEYS.loader,
  wrapperClass = '',
  innerClass = ''
}) => (
  <div className={wrapperClass}>
    <div className={innerClass}>
      <Loader active>{i18n.t(translateKey)}</Loader>
    </div>
  </div>
);

export default LoaderWithText;
