import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Table } from 'semantic-ui-react';

const TableHeaders = ({
  columns,
  resizeIndex,
  sortedColumn,
  direction,
  onHeaderClick,
  stickyHeaderSizes
}) =>
  columns.map((column, columnIndex) => {
    const tableHeaderClasses = classNames({
      sorted: column.isSortable,
      'un-sortable': !column.isSortable,
      'mpe-table_header-resize': columnIndex !== columns.length - 1,
      'mpe-table_header-resizing': columnIndex === resizeIndex
    });
    let width;
    if (stickyHeaderSizes[column.name] || column.defaultColumnWidth) {
      width = {
        width: `${stickyHeaderSizes[column.name] || column.defaultColumnWidth}%`
      };
    }
    return (
      <Table.HeaderCell
        className={tableHeaderClasses}
        style={width || {}}
        key={column.name}
        id={column.name}
        data-column-name={column.name}
        sorted={sortedColumn === column.name ? direction : null}
        onClick={onHeaderClick(column.name, column.isSortable)}
      >
        <span role="presentation">{column.itemName}</span>
      </Table.HeaderCell>
    );
  });

TableHeaders.defaultProps = {
  sortedColumn: '',
  resizeIndex: -1,
  stickyHeaderSizes: {}
};

TableHeaders.propTypes = {
  resizeIndex: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      itemName: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  sortedColumn: PropTypes.string,
  direction: PropTypes.string.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  stickyHeaderSizes: PropTypes.shape({})
};

export default TableHeaders;
