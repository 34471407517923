import { createSelector } from 'reselect';
import { ReduxState } from '../../common/types';
import { taskCanBeReassigned } from '../../components/tasks/TaskListUtils';

import { TaskListItem } from '../../common/types/TaskList';
import { ImportExportItem } from '../../common/types/ImportExportItem';

interface WorklistState {
  worklist: {
    batchSize: number;
    items: TaskListItem[];
    cursor: string;
    totalItems: number;
    selectedIds: Array<string>;
    tasksLoaded: boolean;
    importExportTasks: ImportExportItem[];
    validateComplete: boolean;
    importComplete: boolean;
    isLoading: boolean;
  };
}

export const workflowsDataSelector = (state: ReduxState) => state.worklist;

export const workflowQueryParamsSelector = (state: ReduxState) => state.worklistQueryParams;

export const worklistDataSelector = (state: ReduxState) => state.worklist.items;

export const appGlobalStore = (state: ReduxState) => state;

export const getWorklistTotalItems = createSelector(
  workflowsDataSelector,
  (worklist) => worklist.totalItems
);

export const getWorkflowQueryParams = createSelector(
  workflowQueryParamsSelector,
  (worklistQueryParams) => worklistQueryParams
);

export const getWorkflowQueryParamsSortField = createSelector(
  workflowQueryParamsSelector,
  (worklistQueryParams) => worklistQueryParams.sortField
);

export const getWorkflowQueryParamsSortDirection = createSelector(
  workflowQueryParamsSelector,
  (worklistQueryParams) => worklistQueryParams.sortDirection
);

export const getWorkflowQueryParamsCursor = createSelector(
  workflowQueryParamsSelector,
  (worklistQueryParams) => worklistQueryParams.cursor
);

export const getWorkflowQueryParamsQueryString = createSelector(
  workflowQueryParamsSelector,
  (worklistQueryParams) => worklistQueryParams.query
);

export const getWorkflowQueryParamsFilters = createSelector(
  workflowQueryParamsSelector,
  (worklistQueryParams) => worklistQueryParams.filters
);

export const getWorklistNumber = createSelector(workflowsDataSelector, (tasks) => tasks.length);

export const getWorklistRecordCount = createSelector(
  worklistDataSelector,
  (worklistItems) => worklistItems.length
);

export const worklistTasksLoaded = (state: WorklistState) => {
  state.worklist.tasksLoaded = !!state.worklist.items?.length;
  return state.worklist.tasksLoaded;
};

export const getWorklistSelectedIds = createSelector(workflowsDataSelector, (worklist) => {
  return worklist.selectedIds;
});

export const worklistSelectedTasks = createSelector(
  worklistDataSelector,
  getWorklistSelectedIds,
  (worklist, selectedIds) => {
    return worklist.filter((task: any) => {
      return selectedIds.includes(task.taskGuuid);
    });
  }
);

export const getNumberOfSelectedTasksReadStatus = createSelector(
  worklistSelectedTasks,
  (selectedTasks) => {
    const tasksWithReadStatus = selectedTasks.filter(
      (selectedTask: any) => selectedTask.isRead_bool === 'true'
    );
    return tasksWithReadStatus.length;
  }
);

export const getNumberOfSelectedUnreadStatus = createSelector(
  worklistSelectedTasks,
  (selectedTasks) => {
    const tasksWithReadStatus = selectedTasks.filter(
      (selectedTask: any) => selectedTask.isRead_bool === 'false'
    );
    return tasksWithReadStatus.length;
  }
);

export const getNumberOfArchivedTasksStatus = createSelector(
  worklistSelectedTasks,
  (selectedTasks: any) => {
    const tasksWithArchivedStatus = selectedTasks.filter(
      (selectedTask: any) => !selectedTask.isActive
    );
    return tasksWithArchivedStatus.length;
  }
);

export const getNumberOfUnarchivedTasksStatus = createSelector(
  worklistSelectedTasks,
  (selectedTasks: any) => {
    const tasksWithUnarchivedStatus = selectedTasks.filter(
      (selectedTask: any) => selectedTask.isActive
    );
    return tasksWithUnarchivedStatus.length;
  }
);

export const getWorklistSelectedIdsActiveStatus = createSelector(
  worklistSelectedTasks,
  (selectedTasks) => {
    return selectedTasks.length && selectedTasks.find((selectedTask: any) => selectedTask.isActive);
  }
);

export const getWorklistSelectedIdsInactiveStatus = createSelector(
  worklistSelectedTasks,
  (selectedTasks) => {
    return (
      selectedTasks.length && selectedTasks.find((selectedTask: any) => !selectedTask.isActive)
    );
  }
);

export const worklistTasksAssignable = createSelector(worklistSelectedTasks, (selectedTasks) => {
  return (
    !selectedTasks.length ||
    selectedTasks.some((selectedTask: any) => !taskCanBeReassigned(selectedTask))
  );
});

export const getSelectedWorklistItemsByWorkflowType = (state: WorklistState) => {
  const selectedTaskIds = state.worklist.selectedIds;
  return state.worklist.items
    ?.filter((task) => {
      return selectedTaskIds.includes(task.taskGuuid);
    })
    .map((task) => task.workflowType);
};

export const getImportExportTasks = (state: WorklistState) => {
  return state.worklist.importExportTasks;
};

export const getValidateComplete = (state: WorklistState) => {
  return state.worklist.validateComplete;
};

export const getImportComplete = (state: WorklistState) => {
  return state.worklist.importComplete;
};

export const getIsLoading = (state: WorklistState) => {
  return state.worklist.isLoading;
};
export const getIsAvailableTermsLoading = createSelector(
  appGlobalStore,
  (state) => state.loadingReducer.GET_AVAILABLE_TERMS
);

export const getIsAssigneeNamesLoading = createSelector(appGlobalStore, (state) => {
  return state.loadingReducer.GET_ASSIGNEES;
});

export const getAvailableTermsSelector = createSelector(appGlobalStore, (state) => {
  return state.availableTerms;
});
