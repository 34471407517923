import { createSelector } from 'reselect';

import { mapTaskStateToJobType, getDataForWorkflowType } from '../../utils/generalUtils';
import taskListUtils from '../../components/tasks/TaskListUtils';
import { getShelvesLimit } from '../staticData/staticDataSelector';

export const taskSelector = (state) => state.task;

export const taskIdSelector = createSelector(taskSelector, (task) => task.id);
export const taskStateSelector = createSelector(taskSelector, (task) =>
  task.taskState.key ? task.taskState.key : ''
);

export const taskStatusKeySelector = createSelector(taskSelector, (task) => task.statusKey);

export const workflowIdSelector = createSelector(taskSelector, (task) => task.workflowId);
export const isLocaleConfidenceScoreEnabledSelector = createSelector(
  taskSelector,
  (task) => task.isLocaleConfidenceScoreEnabled
);
export const taskConfidenceScoreSelector = createSelector(
  taskSelector,
  (task) => task.confidenceScore
);
export const isTaskConfidenceScoreCalculated = createSelector(
  taskConfidenceScoreSelector,
  (taskConfidenceScore) => !!taskConfidenceScore
);
export const localeSelector = createSelector(
  taskSelector,
  (selectedTask) => selectedTask && [selectedTask?.locale?.key]
);
export const jobTypeSelector = createSelector(taskSelector, (selectedTask) => {
  if (selectedTask) {
    const jobType = mapTaskStateToJobType(selectedTask.taskState);
    return jobType ? [jobType] : [];
  }
  return [];
});
export const selectedFiltersForReassign = createSelector(
  localeSelector,
  jobTypeSelector,
  taskListUtils.filtersForTaskReassign
);
export const taskNameSelector = createSelector(taskSelector, (task) => (task ? task.name : ''));
export const rejectionReasonsSelector = createSelector(taskSelector, (task) =>
  task ? task.editorRejectionReasons : []
);
export const shelvesSelector = createSelector(taskSelector, (task) =>
  task && task.shelves ? task.shelves : []
);
export const getTaskWorkflowType = createSelector(taskSelector, (selectedTask) =>
  selectedTask ? selectedTask.workflowType : undefined
);

export const makeFindShelfWithId = (shelfId) =>
  createSelector(shelvesSelector, (shelves) => shelves.find((shelf) => shelf.id === shelfId));

// ================= SPOKEN NAMES MANIPULATION - START ================

export const makeTaskCommentsSelector = (shelfId) =>
  createSelector(makeFindShelfWithId(shelfId), (shelfWithId) =>
    shelfWithId && shelfWithId.conversation ? shelfWithId.conversation : []
  );

export const makeTotalCommentsCountSelector = (shelfId) =>
  createSelector(makeFindShelfWithId(shelfId), (shelfWithId) =>
    shelfWithId ? shelfWithId.collaborationSummary.totalCount : 0
  );

export const makeUnreadCommentsCountSelector = (shelfId) =>
  createSelector(makeFindShelfWithId(shelfId), (shelfWithId) =>
    shelfWithId ? shelfWithId.collaborationSummary.unreadCount : 0
  );

export const makeGetConfidenceScore = (shelfId) =>
  createSelector(makeFindShelfWithId(shelfId), (shelfWithId) =>
    !!shelfWithId && !!shelfWithId.scoresAndIssues ? shelfWithId.scoresAndIssues.score : 0
  );

export const makeGetShelfLocalization = (shelfId) =>
  createSelector(makeFindShelfWithId(shelfId), (shelfWithId) =>
    shelfWithId && shelfWithId.localization ? shelfWithId.localization : {}
  );

export const makeGetShelfText = (shelfId) =>
  createSelector(makeGetShelfLocalization(shelfId), (localization) =>
    localization.shelfText ? localization.shelfText : ''
  );

const shelfOverLimit = (shelf, shelvesLimit) => {
  const { shelfType, localization } = shelf;
  const shelfLimit = shelvesLimit[shelfType];
  if (shelfLimit) {
    return localization.shelfPlainTextLength > shelfLimit;
  }
  return false;
};

export const areDescriptionsOverLimit = createSelector(
  shelvesSelector,
  getShelvesLimit,
  (shelves, shelvesLimit) =>
    !!shelves.length && shelves.some((shelf) => shelfOverLimit(shelf, shelvesLimit))
);

export const areActionButtonsDisabled = createSelector(
  taskSelector,
  (state) => state,
  (task, state) => {
    const data = {
      shakespeare: areDescriptionsOverLimit
    };
    return getDataForWorkflowType(task.workflowType, data)(state);
  }
);

export const makeGetShelfHighlight = (shelfId) =>
  createSelector(makeGetShelfLocalization(shelfId), (localization) =>
    localization.shelfTextHighlights ? localization.shelfTextHighlights : []
  );

export const makeGetShelfSnapshotHighlight = (shelfId) =>
  createSelector(makeGetShelfLocalization(shelfId), (localization) =>
    localization.snapshotHighlightId ? localization.snapshotHighlightId : ''
  );

export const makeGetShelfLoading = (shelfId) =>
  createSelector(makeFindShelfWithId(shelfId), (shelfWithId) =>
    shelfWithId && shelfWithId.isLoading ? shelfWithId.isLoading : false
  );

export const makeGetShelfError = (shelfId) =>
  createSelector(
    makeFindShelfWithId(shelfId),
    (shelfWithId) => !!(shelfWithId && !!shelfWithId.isError)
  );

export const makeGetShelfIssues = (shelfId) =>
  createSelector(makeFindShelfWithId(shelfId), (shelfWithId) =>
    shelfWithId && shelfWithId.scoresAndIssues ? shelfWithId.scoresAndIssues.issues : []
  );

export const makeGetActiveShelfIssueId = (shelfId) =>
  createSelector(makeFindShelfWithId(shelfId), (shelfWithId) =>
    shelfWithId && shelfWithId.scoresAndIssues && shelfWithId.scoresAndIssues.activeIssueId
      ? shelfWithId.scoresAndIssues.activeIssueId
      : ''
  );

export const makeGetActiveShelfIssue = (shelfId) =>
  createSelector(makeFindShelfWithId(shelfId), (shelfWithId) =>
    shelfWithId && shelfWithId.scoresAndIssues && shelfWithId.scoresAndIssues.activeIssue
      ? shelfWithId.scoresAndIssues.activeIssue
      : { issueId: '' }
  );

export const getTaskMediaType = createSelector(taskSelector, (selectedTask) =>
  selectedTask ? selectedTask.mediaType : undefined
);

export const getTaskContentType = createSelector(taskSelector, (selectedTask) =>
  selectedTask ? selectedTask.contentType : undefined
);
