import { StaticDataActionTypes } from './staticDataActionTypes';
import { getInitialStateForRedux } from '../../utils/localStorageData';
import { StaticData } from '../../common/types';
import { StaticDataActions } from './staticDataActions';

const initialState = {
  ...getInitialStateForRedux(),
  isDone: false
};

export default function user(
  state: StaticData = initialState,
  action: StaticDataActions
): StaticData {
  switch (action.type) {
    case StaticDataActionTypes.GET_STATIC_DATA_SUCCESS:
      return { ...action.data, isDone: true };
    case StaticDataActionTypes.GET_STATIC_DATA_FAILURE:
      return { ...getInitialStateForRedux(), isDone: true };
    default:
      return state;
  }
}
