import React from 'react';
import Moment from 'react-moment';

import { DATE_FORMAT } from '../../common/index';
import UserRating from './UserRating';
import TRANSLATE_KEYS from '../../public/locales/translateKeys';
import i18n from '../../i18n';
import { getUserStatusValueLabel } from '../../utils/generalUtils';

const headers = [
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.display_name),
    name: 'displayName',
    isSortable: true
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.email),
    name: 'email',
    isSortable: true,
    numberOfClampedLines: 1,
    addHyphens: 'none'
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.jobTypes),
    name: 'jobTypes',
    sortFieldKey: 'jobType',
    isSortable: true,
    transform: (items) => items.map((item) => item.displayName).join(', ')
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.vendorName),
    name: 'vendorName',
    isSortable: false,
    transform: (item) => item || '-'
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.locales),
    name: 'locales',
    sortFieldKey: 'locale',
    isSortable: true,
    transform: (items) => items.map((item) => item.key).join(', ')
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.available),
    name: 'available',
    sortFieldKey: 'status',
    isSortable: true,
    transform: (item) => getUserStatusValueLabel(item)
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.favoriteGenre),
    name: 'favoriteGenre',
    isSortable: true,
    sortFieldKey: 'genre',
    transform: (items) => items.map((item) => item.displayName).join(', ')
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.favoriteContentType),
    name: 'favoriteContentType',
    isSortable: true,
    sortFieldKey: 'contentType',
    transform: (items) => items.map((item) => item.displayName).join(', ')
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.createdAt),
    name: 'createdAt',
    isSortable: false,
    transform: (item) => <Moment format={DATE_FORMAT.shortDate}>{item}</Moment>
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.publishedTasks),
    name: 'publishedTasks',
    isSortable: true,
    sortFieldKey: 'completedTaskNumber',
    transform: (item) => (item < 0 ? '-' : item)
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.assignedTasks),
    name: 'assignedTasks',
    isSortable: true,
    sortFieldKey: 'pendingTaskNumber',
    transform: (item) => (item < 0 ? '-' : item)
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.rejectedTasks),
    name: 'rejectedTasks',
    sortFieldKey: 'rejectedTaskNumber',
    transform: (item) => (item < 0 ? '-' : item)
  },
  {
    itemName: i18n.t(TRANSLATE_KEYS.user_details_page.table_headers.averageStarRating),
    name: 'averageStarRating',
    isSortable: true,
    sortFieldKey: 'rating',
    transform: (item) => <UserRating rating={item} />
  }
];

export default headers;
