import React, { FC } from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n';

import TRANSLATE_KEYS from '../../public/locales/translateKeys';
import { hideModal } from '../../store/modal/modalActions';
import { getSelectedTasksIds, getTaskNumber } from '../../store/insertTask/insertTaskSelector';
import { getWorklistSelectedIds, getWorklistNumber } from '../../store/worklist/worklistSelector';
import { ReduxState } from '../../common/types';
import InfoModal from '../modals/InfoModal/InfoModal';

interface Props {
  closeModal: () => void;
  submitAction: (ids: Object) => void;
  selectedTasks: number[];
  selectedWorklistTasks: string[];
  taskNumber: number;
  worklistNumber: number;
  exportSelectedTasks: boolean;
}

interface StateProps {
  selectedTasks: number[];
  selectedWorklistTasks: string[];
  taskNumber: number;
  worklistNumber: number;
}

interface DispatchProps {
  closeModal: () => void;
}

type ComponentProps = Props & StateProps & DispatchProps;

export const ExportValidModalContent: FC<ComponentProps> = (props) => {
  const { closeModal, submitAction, selectedWorklistTasks, worklistNumber, exportSelectedTasks } =
    props;

  const selected = selectedWorklistTasks;
  const number = worklistNumber;

  const numberOfTasksToExport =
    selected.length === number
      ? i18n.t(TRANSLATE_KEYS.export_valid.export_all_tasks)
      : selected.length;

  const title =
    numberOfTasksToExport === 1
      ? TRANSLATE_KEYS.export_valid.export_valid_one_task_headline
      : TRANSLATE_KEYS.export_valid.export_valid_headline;

  const subtitle =
    numberOfTasksToExport === 1
      ? TRANSLATE_KEYS.export_valid.export_valid_one_task_sub_headline
      : TRANSLATE_KEYS.export_valid.export_valid_sub_headline;

  const subtitleExportSelected = TRANSLATE_KEYS.export_valid.export_and_update_valid_sub_headline;

  return (
    <InfoModal
      title={i18n.t(title, {
        numberOfTasksToExport
      })}
      closeModal={closeModal}
      onSuccess={() => {
        if (selected.length === number) {
          submitAction({ ids: [] });
        } else {
          submitAction({ ids: selected });
        }
        closeModal();
      }}
      successLabel={i18n.t(TRANSLATE_KEYS.export.export_success_button_label)}
    >
      {exportSelectedTasks ? i18n.t(subtitleExportSelected) : i18n.t(subtitle)}
    </InfoModal>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  selectedTasks: getSelectedTasksIds(state),
  selectedWorklistTasks: getWorklistSelectedIds(state),
  taskNumber: getTaskNumber(state),
  worklistNumber: getWorklistNumber(state)
});

const mapDispatchToProps: DispatchProps = {
  closeModal: hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportValidModalContent);
