import { createSelector } from 'reselect';
import { ReduxState } from '../../common/types';

export const userSelector = (state: ReduxState) => state.user;
export const isUserAuthenticated = (state: ReduxState) => !!state.user.accessToken;

// TODO: does this still exist?
export const isUserConfirmed = createSelector(
  userSelector,
  // @ts-ignore
  (user) => !!user.confirmed
);
export const getUserPermissions = createSelector(userSelector, (user) =>
  user.permissions ? user.permissions : []
);
export const getUsersPreferences = createSelector(userSelector, (user) => { }
);
export const makeGetCollectionPreferences = (collection: string) =>
  createSelector(getUsersPreferences, (userPreferences) => { }
  );

const getUserColumns = createSelector(userSelector, (user) => []);
export const getTaskHeadersSelector = createSelector(getUserColumns, (userColumns) =>
  []
);
export const getUserHeadersReassignSelector = createSelector(
  getUserColumns,
  (userColumns) => []
);
