import TRANSLATE_KEYS from '../public/locales/translateKeys';
import i18n from '../i18n';
import { IconType } from '@ui-components/icon-template';

/**
 * authenticated route is accessible for user who is already logged in
 * unauthenticated route is accessible for user who isn't logged in
 * public is publiclly accessed
 */
export const PROTECTION = {
  authenticated: 'authenticated',
  unauthenticated: 'unauthenticated',
  public: 'public'
};

export const JOB_TYPES = {
  editor: 'EDITOR',
  author: 'AUTHOR',
  writer: 'WRITER', // Added because request for reassign modal expects WRITER instead of AUTHOR
  admin: 'ADMIN',
  vendorPm: 'VENDOR_MANAGER',
  reviewer: 'REVIEWER',
  dormant: 'DORMANT',
  geo_lead: 'GEO_LEAD',
  all: ['EDITOR', 'AUTHOR', 'ADMIN', 'VENDOR_MANAGER', 'REVIEWER']
};

export const PERMISSIONS = {
  // Vendor
  canViewEditVendors: 'VIEW_EDIT_VENDORS',
  // User List
  canAddViewEditDeactivateVendorEmployee: 'ADD_VIEW_EDIT_DEACTIVATE_VENDOR_EMPLOYEE',
  // User
  hasVacationMode: 'VACATION_MODE',
  // Single Task
  // Task Collaboration
  canAddActionItem: 'ADD_ACTION_ITEM',
  canResolveActionItem: 'RESOLVE_ACTION_ITEM',
  // Task Information
  canEditTaskMetadata: 'EDIT_TASK_METADATA',
  canSeeAdamId: 'VIEW_ADAM_ID',
  // Task Advance
  canAddViewInternalReview: 'ADD_VIEW_INTERNAL_REVIEW',
  // Functionality
  canExportTasks: 'EXPORT_TASKS',
  canAccessAnalytics: 'CAN_ACCESS_ANALYTICS',
  canCreateTaskManually: 'CREATE_TASK',
  canDoWorkflowWorklistImport: 'CAN_DO_WORKFLOW_WORKLIST_IMPORT',
  canDoWorkflowWorklistExport: 'CAN_DO_WORKFLOW_WORKLIST_EXPORT',
  vcwCanAccessNamespace: 'VCW_CAN_ACCESS_NAMESPACE',
  mcwCanAccessNamespace: 'MCW_CAN_ACCESS_NAMESPACE',
  acwCAnAccessNAmespace: 'ACW_CAN_ACCESS_NAMESPACE',
  canDoWorkflowWorklistReassign: 'CAN_DO_WORKFLOW_WORKLIST_REASSIGN',
  canViewWorkflowBetaFeatures: 'CAN_VIEW_WORKFLOW_BETA_FEATURES',
  canEditWorklistGlobalAlerts: 'CAN_EDIT_WORKLIST_GLOBAL_ALERTS'
};

export const JOB_TYPES_DROPDOWN_OPTIONS = [
  { text: 'Reviewer', value: 'REVIEWER' },
  { text: 'Editor', value: 'EDITOR' },
  { text: 'Author', value: 'AUTHOR' }
];

export const COMPONENT_PAGES = {
  login: 'LoginPage',
  confirmUser: 'ConfirmUserPage',
  expirationPage: 'ExpirationPage',
  moduleTaskDetails: 'ModuleTaskDetailsPage',
  welcomePage: 'WelcomePage',
  releaseDetailsPage: 'ReleaseDetailsPage',
  worklistPage: 'WorklistPage',
  copyManagementPage: 'CopyManagementPage'
};

export const ROUTES = {
  login: '/login',
  logout: '/logout', // FIXME: Does not exists in pages
  worklist: '/worklist',
  confirmUser: '/confirm-user', //FIXME: Does not exists in pages
  expirationPage: '/expiration-page',
  moduleTaskDetails: '/:type/workflows/:id/latestTask',
  welcomePage: '/welcome',
  releaseDetailsPage: '/release-details',
  copyManagementPage: '/copy-management'
};

export const NAVIGATION_BUTTONS = {
  reassignTaskButton: 'ReassignTaskButton',
  exportTaskButton: 'ExportTaskButton',
  exportTaskDropdownButton: 'ExportTaskDropdownButton',
  filterTasksButton: 'FilterTasksButton',
  mtarButton: 'MarkTaskAsReadButton',
  archiveButton: 'ArchiveTaskButton'
};

export const TASK_DETAILS_TABS = {
  inspect: 'inspect'
};

export const TAB_ITEM_TYPES = {
  accordion: 'accordion'
};

export const TASK_ACCORDION_TYPES = {
  title: 'title',
  preview: 'preview',
  titleWithHeader: 'titleWithHeader'
};

export const TASK_ACCORDION_COMPONENTS = {
  musicPlayer: 'TaskDetailsMusicPlayer',
  taskInformation: 'TaskInformation',
  taskHistory: 'TaskHistory',
  confidenceScore: 'ConfidenceScore',
  shelfInformation: 'ShelfInformation'
};

export const TOAST_TYPES = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success'
};

export const ROUTE_COMPONENTS = [
  {
    name: ROUTES.welcomePage,
    protected: PROTECTION.unauthenticated,
    buttons: [],
    component: 'WelcomePage'
  },
  {
    name: ROUTES.login,
    protected: PROTECTION.unauthenticated,
    buttons: [],
    component: 'LoginPage'
  },
  {
    name: ROUTES.moduleTaskDetails,
    buttons: [],
    protected: PROTECTION.authenticated,
    permissions: [],
    component: 'ModuleTaskDetailsPage'
  },
  {
    name: ROUTES.confirmUser,
    buttons: [],
    protected: PROTECTION.unauthenticated,
    component: 'ConfirmUserPage'
  },
  {
    name: ROUTES.expirationPage,
    buttons: [],
    protected: PROTECTION.unauthenticated,
    component: 'ExpirationPage'
  },
  {
    name: ROUTES.releaseDetailsPage,
    buttons: [],
    protected: PROTECTION.unauthenticated,
    component: 'ReleaseDetailsPage'
  },
  {
    name: ROUTES.copyManagementPage,
    protected: PROTECTION.unauthenticated,
    buttons: [],
    component: 'CopyManagementPage'
  },
  {
    name: ROUTES.worklist,
    buttons: [
      // {
      //   component: NAVIGATION_BUTTONS.exportTaskDropdownButton,
      //   permissions: [PERMISSIONS.canDoWorkflowWorklistExport],
      //   props: {}
      // },
      // {
      //   component: NAVIGATION_BUTTONS.reassignTaskButton,
      //   permissions: [PERMISSIONS.canDoWorkflowWorklistReassign],
      //   props: {}
      // },
      // {
      //   component: NAVIGATION_BUTTONS.mtarButton,
      //   permissions: [],
      //   props: {
      //     // TODO: rdar://103050123 will remove once BE changes are updated
      //     isDevEnabled: true,
      //     isUpdatebutton: true
      //   }
      // },
      // {
      //   component: NAVIGATION_BUTTONS.archiveButton,
      //   permissions: [],
      //   props: {
      //     // TODO: rdar://103050123 will remove once BE changes are updated
      //     isDevEnabled: true,
      //     isUpdatebutton: true
      //   }
      // }
    ],
    protected: PROTECTION.authenticated,
    component: 'WorklistPage',
    itemName: TRANSLATE_KEYS.navigation_bar.analytics,
    hasSearch: true
  }
];

export const DATE_FORMAT = {
  shortDate: 'LL',
  shortDateWithTime: 'LLL',
  dateWithTimeNoYear: 'MMMM Do, hh:mm A',
  shortDateWithAbbreviatedMonthName: 'MMM DD, YYYY',
  numbersOnly: 'MM/DD/YY',
  taskHistoryDate: 'DD MMM YYYY',
  taskHistoryTodayDate: '[Today] LT',
  minutesAndSeconds: 'mm:ss',
  year: 'YYYY'
};

export const TASK_STATUSES = {
  INTERNAL_REVIEW: 'INTERNAL_REVIEW',
  EXTERNAL_ASSIGNED: 'EXTERNAL_ASSIGNED',
  EXTERNAL_DRAFT: 'EXTERNAL_DRAFT',
  EXTERNAL_REJECTED: 'EXTERNAL_REJECTED',
  EXTERNAL_REVIEW: 'EXTERNAL_REVIEW',
  EXTERNAL_COMPLETED: 'EXTERNAL_COMPLETED',
  READY_FOR_EXPORT: 'READY_FOR_EXPORT'
};

export const TASK_QUERY_FIELDS = {
  id: 'id',
  countryCodes: 'countryCodes',
  locale: 'locale',
  dueDate: 'dueDate',
  taskStatus: 'taskStatus',
  assigneeInfoFirstName: 'assigneeInfo.firstName',
  assigneeInfoLastName: 'assigneeInfo.lastName',
  assigneeInfoDisplayName: 'assigneeInfo.displayName',
  assigneeInfoEmail: 'assigneeInfo.email',
  assigneeInfoLocales: 'assigneeInfo.locales',
  assigneeInfoType: 'assigneeInfo.type',
  assigneeInfoJobType: 'assigneeInfo.jobType',
  assigneeInfoRating: 'assigneeInfo.rating',
  assigneeInfoPendingTaskNumber: 'assigneeInfo.pendingTaskNumber',
  assigneeInfoCompletedTaskNumber: 'assigneeInfo.completedTaskNumber',
  shelfType: 'shelfType',
  contentInfoContentType: 'contentInfo.contentType',
  contentInfoContentId: 'contentInfo.contentId'
};

export const TASK_STATUS_FRIENDLY: { [key: string]: string } = {
  UNASSIGNED: 'Unassigned',
  ASSIGNED: 'Assigned',
  REJECTED: 'Rejected',
  RETURNED_BY_EDITOR: 'Returned by editor',
  RETURNED_BY_REVIEWER: 'Returned by reviewer',
  IN_REVIEW: 'Editor review',
  APPROVED_BY_EDITOR: 'Approved by editor',
  APPROVED_BY_REVIEWER: 'Approved by reviewer',
  READY_FOR_EXPORT: 'Ready for export',
  EXPORTED: 'Exported',
  EXTERNAL_ASSIGNED: 'Assigned to Author',
  EXTERNAL_REJECTED: 'Rejected by Editor',
  EXTERNAL_REVIEW: 'Review',
  EXTERNAL_COMPLETED: 'Completed',
  EXTERNAL_DRAFT: 'Draft',
  REWRITE: 'Rewrite'
};

export const TASK_STATUS_PREPOSITIONS = {
  ASSIGNED: 'to',
  REJECTED: 'by',
  RETURNED_BY_EDITOR: 'to',
  RETURNED_BY_REVIEWER: 'to',
  IN_REVIEW: 'by',
  APPROVED_BY_EDITOR: '',
  APPROVED_BY_REVIEWER: '',
  READY_FOR_EXPORT: 'by ',
  EXPORTED: 'by'
};

export const APPLE_MUSIC_ALBUM = 'APPLE_MUSIC_ALBUM';

export const TASK_STATES = {
  delegate_to_copy_writer: 'delegate_to_copy_writer',
  end: 'end',
  external_review: 'external_review',
  onsite_review: 'onsite_review',
  review_copy: 'review_copy',
  vendor_review: 'vendor_review',
  submit_copy: 'submit_copy',
  geo_lead_review: 'geo_lead_review',
  gate_keeper: 'gate_keeper',
  adapt_review: 'adapt_review',
  adapt_internal_review: 'adapt_internal_review',
  adaptation_edit: 'adaptation_edit',
  write: 'write',
  review: 'review',
  edit: 'edit'
};

// TODO: update TASK_STATES and TASK_STATES_NEW once we have all clear on BE side
export const TASK_STATES_NEW = {
  INTERNAL_REVIEW: ':workflowState/INTERNAL_REVIEW',
  EXTERNAL_REVIEW: ':workflowState/EXTERNAL_REVIEW',
  SUBMIT_COPY: ':workflowState/SUBMIT_COPY',
  SUBMIT: ':workflowState/SUBMIT',
  END: ':workflowState/END'
};

export const ADVANCE_OPTION = {
  SUBMIT: 'SUBMIT',
  APPROVE: 'APPROVE',
  EXTERNAL_APPROVE: 'EXTERNAL_APPROVE',

  REJECT: 'REJECT',
  EXTERNAL_REJECT: 'EXTERNAL_REJECT'
};

export const COLLABORATION_MODES = {
  NORMAL: 'NORMAL',
  COLLABORATION: 'COLLABORATION'
};

export const RATING_STAR_AVERAGE_SCORE = {
  boundary: 0
};

export const DEBOUNCE_PERIOD = 200;
export const DEBOUNCE_PERIOD_CLICK = 200;
export const DEBOUNCE_PERIOD_TABLE_RESIZE = 600;

export const DESCRIPTION_STRINGS: { [key: string]: string } = {
  name: 'NAME',
  short: 'SHORT',
  long: 'LONG',
  comment: 'COMMENT'
};

export const CONFIDENCE_SCORE_STATUS: { [key: string]: string } = {
  ALL_PASSED: 'ALL_PASSED',
  NO_SCORE: 'NO_SCORE',
  LOW_SCORE: 'LOW_SCORE',
  NOT_SUPPORTED_LOCALE: 'NOT_SUPPORTED_LOCALE'
};

export const DESCRIPTION_WARNING_LIMIT = 5;

export const REGISTERED_SHELVES: {
  [key: string]: {
    icon: IconType;
    menuLabel: string;
    dynamicNavigationLabel: boolean;
  };
} = {
  SHORT: {
    icon: 'shortDescription',
    menuLabel: i18n.t(TRANSLATE_KEYS.task_details_page.copy.short_description),
    dynamicNavigationLabel: false
  },
  LONG: {
    icon: 'longDescription',
    menuLabel: i18n.t(TRANSLATE_KEYS.task_details_page.copy.long_description),
    dynamicNavigationLabel: false
  },
  NAME: {
    icon: 'nameShallow',
    menuLabel: i18n.t(TRANSLATE_KEYS.task_details_page.copy.name),
    dynamicNavigationLabel: false
  },
  AUTO_TUNE_SONG: {
    icon: 'note',
    menuLabel: i18n.t(TRANSLATE_KEYS.task_details_page.copy.song),
    dynamicNavigationLabel: true
  },
  AUTO_TUNE_ALBUM: {
    icon: 'album',
    menuLabel: i18n.t(TRANSLATE_KEYS.task_details_page.copy.album),
    dynamicNavigationLabel: true
  },
  AUTO_TUNE_ARTIST: {
    icon: 'microphone',
    menuLabel: i18n.t(TRANSLATE_KEYS.task_details_page.copy.artist),
    dynamicNavigationLabel: true
  }
};

export const CONTENT_TYPES: { [key: string]: string } = {
  appleMusicPlaylist: 'APPLE_MUSIC_PLAYLIST',
  appleMusicAlbum: 'ALBUM' // TODO: remove ALBUM from here as it's content types will be used in it's package
};

export const WORKFLOW_TYPES = {
  video: 'VIDEO_CONTENT',
  music: 'MUSIC_CONTENT',
  appscanvas: 'APPS_CANVAS'
};

export const WORKFLOW_NAMESPACES = {
  musiccontent: 'ed-music-content-workflow',
  videocontent: 'ed-video-content-workflow',
  appscanvas: 'ed-apps-canvas-workflow',
  copyworkflow: 'ed-copy-workflow',
  musiccontentOld: 'editorial.musiccontent',
  videocontentOld: 'editorial.videocontent',
  appscanvasOld: 'editorial.appscanvas'
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ON_VACATION: 'ON_VACATION'
};

export const TASK_HISTORY_ITEM_TYPE = {
  STATUS: 'STATUS',
  PRIORITY: 'PRIORITY',
  DUE_DATE: 'DUE_DATE',
  ASSIGNEE: 'ASSIGNEE',
  REJECTION_REASON: 'REJECTION_REASON'
};

export const TASK_HISTORY_ITEM_STATE = {
  PENDING: 'Pending',
  REVIEW: 'Review',
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  ASSIGNED: 'Assigned',
  PENDING_POPULARITY_CHECK: 'Pending Popularity Check'
};
