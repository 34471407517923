const debounce = (fn, delay) => {
  let timer = null;
  const func = (...args) => {
    // eslint-disable-next-line
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
  return func;
};
export default debounce;
