import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NoResultPage from '@ui-components/no-result-page';

import UsersListHeaders from './UsersListHeaders';
import { usersData, getUsersTotalItems } from '../../store/users/usersSelector';
import TRANSLATE_KEYS from '../../public/locales/translateKeys';
import SortTable from '../../common/SortTable';
import createLoadingSelector from '../../store/loading/loadingSelector';
import {
  fetchUsersRequest,
  fetchUsersForReassignRequest,
  fetchUsersInfiniteRequest
} from '../../store/users/usersActions';

import {
  GET_USERS,
  CREATE_USER,
  EDIT_USER,
  USERS,
  GET_USERS_INFINITE,
  GET_USERS_FOR_REASSIGN
} from '../../store/users/usersActionTypes';

import { getUserDashboardFields } from '../../store/staticData/staticDataSelector';

import {
  getUserPermissions,
  makeGetCollectionPreferences,
  getUserHeadersReassignSelector
} from '../../store/user/userSelector';
import UsersListUtils from './UsersListUtils';
import UsersListDataMapper from './UsersListDataMapper';
import withInfiniteScroll from '../../common/components/InfiniteScrollWrapper/WithInfiniteScroll';
import { getSortUsers } from '../../store/queryParams/queryParamsSelector';
import composedQueryWrapper from '../../common/components/QueryParamsWrapper';
import { getTableColumnsConfig } from '../../utils/generalUtils';
import i18n from '../../i18n';
import { getSelectedTasks } from '../../store/insertTask/insertTaskSelector';
import { taskSelector } from '../../store/task/taskSelector';

class UsersReassignList extends Component {
  componentDidMount() {
    const { fetchUsersForReassign } = this.props;
    fetchUsersForReassign();
  }

  requestDataFromServer = () => {
    const { fetchUsersForReassign } = this.props;
    fetchUsersForReassign();
  };

  setSelectedIndexes = (selectedIndexes) => {
    const { setSelected } = this.props;
    setSelected(selectedIndexes);
  };

  render() {
    const {
      results,
      loading,
      checkBoxHeader,
      selectedUsers,
      sortedColumn,
      applySort,
      applyFilter,
      updateTableHeader,
      stickyHeaderSizes,
      searchTerm,
      userHeaders,
      userDashboardFields,
      isRadioButton
    } = this.props;

    const data = results.map((user) => UsersListDataMapper(user));

    const userColumns = getTableColumnsConfig(userHeaders, userDashboardFields, UsersListHeaders);

    return (
      <>
        <SortTable
          isModal
          isAssignment
          loading={loading}
          data={data}
          columns={userColumns}
          actionColumns={[]}
          addCustomClassForIndex={(index, user) => UsersListUtils.addCustomClassForData(user)}
          checkedDataIds={selectedUsers}
          checkBoxHeader={checkBoxHeader}
          setSelectedIds={this.setSelectedIndexes}
          fetchData={this.requestDataFromServer}
          applySort={applySort}
          applyFilter={applyFilter} // TODO: prepared for future purposes
          sortedColumn={sortedColumn}
          setHeaders={(columnResized) => updateTableHeader(USERS, columnResized)}
          stickyHeaderSizes={stickyHeaderSizes}
          searchTerm={searchTerm}
          isRadioButton={isRadioButton}
        />

        {!loading && !results.length && (
          <NoResultPage headline={i18n.t(TRANSLATE_KEYS.user_details_page.empty_user_list)} />
        )}
      </>
    );
  }
}

UsersReassignList.defaultProps = {
  results: [],
  checkBoxHeader: false,
  setSelected: () => {},
  searchTerm: '',
  selectedUsers: [],
  sortedColumn: '',
  stickyHeaderSizes: {},
  isRadioButton: false
};

UsersReassignList.propTypes = {
  searchTerm: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({})),
  fetchUsersForReassign: PropTypes.func.isRequired,
  checkBoxHeader: PropTypes.bool,
  setSelected: PropTypes.func,
  selectedUsers: PropTypes.arrayOf(PropTypes.string),
  sortedColumn: PropTypes.arrayOf(
    PropTypes.shape({ field: PropTypes.string, direction: PropTypes.string })
  ),
  applySort: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  updateTableHeader: PropTypes.func.isRequired,
  stickyHeaderSizes: PropTypes.shape({}),
  userHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  userDashboardFields: PropTypes.shape({
    type: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  isRadioButton: PropTypes.bool
};

const loadingSelector = createLoadingSelector([
  GET_USERS,
  CREATE_USER,
  EDIT_USER,
  GET_USERS_FOR_REASSIGN
]);
const loadingInfiniteSelector = createLoadingSelector([GET_USERS_INFINITE]);

const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    const userPreferenceSelector = makeGetCollectionPreferences(USERS);

    return {
      results: usersData(state),
      totalItems: getUsersTotalItems(state),
      loading: loadingSelector(state),
      loadingInfinite: loadingInfiniteSelector(state),
      userPermissions: getUserPermissions(state),
      sortedColumn: getSortUsers(state),
      stickyHeaderSizes: userPreferenceSelector(state),
      userHeaders: getUserHeadersReassignSelector(state),
      userDashboardFields: getUserDashboardFields(state),
      selectedTasks: getSelectedTasks(state),
      task: taskSelector(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchUsers: fetchUsersRequest,
  fetchUsersForReassign: fetchUsersForReassignRequest,
  fetchInfinite: fetchUsersInfiniteRequest
};

const infiniteScrollUsersList = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withInfiniteScroll(UsersReassignList));

export default composedQueryWrapper(infiniteScrollUsersList, USERS);
