import { put, takeLatest, all, call } from 'redux-saga/effects';
import api from '../../api/api';

import {
  createAlertFailure,
  createAlertSuccess,
  deleteAlertFailure,
  getAlertsFailure,
  getAlertsSuccess,
  updateAlertFaillure
} from './alertsAction';
import {
  CreateAlertRequestAction,
  UpdateAlertRequestAction,
  DeleteAlertRequestAction,
  CreateAlertActionTypes,
  UpdateAlertActionTypes,
  DeleteAlertActionTypes,
  GetAlertsActionType
} from './alertsActionTypes';
import { sortAlerts } from '../../utils/alerts';

export function* getAlertsRequest(): any {
  try {
    const alertsData = yield call(api.alerts.getAlerts, 'ACTIVE');
    const sortedData = sortAlerts(alertsData);
    yield put(getAlertsSuccess(sortedData));
  } catch (error) {
    //@ts-ignore
    yield put(getAlertsFailure(error.response?.data.message));
  }
}

export function* createAlertRequest(action: CreateAlertRequestAction): Generator<any, any, any> {
  try {
    yield call(api.alerts.createAlert, { ...action.payload });
    yield put(createAlertSuccess());
    yield call(getAlertsRequest);
  } catch (error) {
    yield put(createAlertFailure(error as Error));
  }
}

export function* updateAlertRequest(action: UpdateAlertRequestAction): Generator<any, any, any> {
  try {
    yield call(api.alerts.updateAlert, action.payload);
    yield call(getAlertsRequest);
  } catch (error) {
    yield put(updateAlertFaillure(error as Error));
  }
}

export function* deleteAlertRequest(action: DeleteAlertRequestAction): any {
  try {
    yield call(api.alerts.deleteAlert, action.payload);
    yield call(getAlertsRequest);
  } catch (error) {
    yield put(deleteAlertFailure(error as Error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GetAlertsActionType.GET_ALERTS_REQUEST, getAlertsRequest),
    takeLatest(CreateAlertActionTypes.CREATE_ALERT_REQUEST, createAlertRequest),
    takeLatest(UpdateAlertActionTypes.UPDATE_ALERT_REQUEST, updateAlertRequest),
    takeLatest(DeleteAlertActionTypes.DELETE_ALERT_REQUEST, deleteAlertRequest)
  ]);
}
