import { SHOW_MODAL, HIDE_MODAL } from './modalActionTypes';

export const showModal = (modalProps, modalType) => ({
  type: SHOW_MODAL,
  modalType,
  modalProps
});

export const hideModal = (modal) => ({
  type: HIDE_MODAL,
  modal
});
