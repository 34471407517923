import {
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE,
  GET_COLLABORATION_REQUEST,
  GET_COLLABORATION_SUCCESS,
  GET_COLLABORATION_FAILURE,
  GET_COLLABORATIONS_REQUEST,
  GET_COLLABORATIONS_SUCCESS,
  GET_COLLABORATIONS_FAILURE,
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
  UPDATE_BULK_TASK_REQUEST,
  UPDATE_BULK_TASK_SUCCESS,
  UPDATE_BULK_TASK_FAILURE,
  UPDATE_SHELF_TEXT_TO_UPDATE_REQUEST,
  UPDATE_SHELF_TEXT_TO_UPDATE_SUCCESS,
  UPDATE_SHELF_TEXT_TO_UPDATE_FAILURE,
  ADD_TASK_COMMENT_REQUEST,
  ADD_TASK_COMMENT_SUCCESS,
  ADD_TASK_COMMENT_FAILURE,
  EDIT_TASK_COMMENT_REQUEST,
  EDIT_TASK_COMMENT_SUCCESS,
  EDIT_TASK_COMMENT_FAILURE,
  ADVANCE_TASK_REQUEST,
  ADVANCE_TASK_SUCCESS,
  ADVANCE_TASK_FAILURE,
  GET_CONFIDENCE_SCORE_REQUEST,
  GET_CONFIDENCE_SCORE_SUCCESS,
  GET_CONFIDENCE_SCORE_FAILURE,
  GET_TASK_HISTORY_REQUEST,
  GET_TASK_HISTORY_SUCCESS,
  GET_TASK_HISTORY_FAILURE,
  SET_NEW_HIGHLIGHT,
  REMOVE_HIGHLIGHT,
  CLEAR_TASK,
  GET_TASK_CONFIDENCE_SCORE_REQUEST,
  GET_TASK_CONFIDENCE_SCORE_SUCCESS,
  GET_TASK_CONFIDENCE_SCORE_FAILURE,
  CANCEL_CONFIDENCE_SCORE_REQUEST,
  ACCEPT_ISSUE_REQUEST,
  ACCEPT_ISSUE_SUCCESS,
  ACCEPT_ISSUE_FAILURE,
  SET_ACTIVE_ISSUE,
  REJECT_ISSUE_REQUEST,
  REJECT_ISSUE_SUCCESS,
  REJECT_ISSUE_FAILURE,
  INVALIDATE_CONFIDENCE_SCORE,
  MARK_AS_READ_REQUEST,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
  REMOVE_ACTIVE_ISSUE,
  UPDATE_PLAIN_TEXT_LENGTH,
  REMOVE_TASK_CONFIDENCE_SCORE,
  UPDATE_SHELF_INFO,
  UPDATE_SHELF_INFO_FAILURE,
  UPDATE_SHELF_INFO_SUCCESS,
  UPDATE_ALIAS_CARD_REQUEST,
  UPDATE_ALIAS_CARD_SUCCESS,
  UPDATE_ALIAS_CARD_FAILURE,
  UPDATE_SPOKEN_NAME_CARD_REQUEST,
  UPDATE_SPOKEN_NAME_CARD_SUCCESS,
  UPDATE_SPOKEN_NAME_CARD_FAILURE,
  UPDATE_PRONUNCIATION_CARD_REQUEST,
  UPDATE_PRONUNCIATION_CARD_SUCCESS,
  UPDATE_PRONUNCIATION_CARD_FAILURE,
  CREATE_ALIAS_CARD_REQUEST,
  CREATE_ALIAS_CARD_SUCCESS,
  CREATE_ALIAS_CARD_FAILURE,
  DELETE_ALIAS_CARD_REQUEST,
  DELETE_ALIAS_CARD_SUCCESS,
  DELETE_ALIAS_CARD_FAILURE,
  RESERVE_PRONUNCIATION_REQUEST,
  RESERVE_PRONUNCIATION_FAILURE,
  COMMIT_PRONUNCIATION_REQUEST,
  COMMIT_PRONUNCIATION_FAILURE,
  REJECT_EQUIVALENCIES_CARD_REQUEST,
  REJECT_EQUIVALENCIES_CARD_SUCCESS,
  REJECT_EQUIVALENCIES_CARD_FAILURE,
  UPDATE_PRONUNCIATION_CARD_SHELF_REQUEST,
  UPDATE_TASK_REASSIGNMENT_SUCCESS
} from './taskActionTypes';

export const getTaskRequest = (taskId) => ({
  type: GET_TASK_REQUEST,
  taskId
});

export const getTaskSuccess = (data) => ({
  type: GET_TASK_SUCCESS,
  data
});

export const getTaskFailure = (error) => ({
  type: GET_TASK_FAILURE,
  error
});

export const getCollaborationRequest = (workflowId, sessionId, shelfId) => ({
  type: GET_COLLABORATION_REQUEST,
  workflowId,
  sessionId,
  shelfId
});

export const getCollaborationSuccess = (shelfId, data) => ({
  type: GET_COLLABORATION_SUCCESS,
  shelfId,
  data
});

export const getCollaborationFailure = (error) => ({
  type: GET_COLLABORATION_FAILURE,
  error
});

export const getCollaborationsRequest = (workflowId) => ({
  type: GET_COLLABORATIONS_REQUEST,
  workflowId
});

export const getCollaborationsSuccess = (data) => ({
  type: GET_COLLABORATIONS_SUCCESS,
  data
});

export const getCollaborationsFailure = (error) => ({
  type: GET_COLLABORATIONS_FAILURE,
  error
});

export const advanceTaskRequest = (advanceOption, task) => ({
  type: ADVANCE_TASK_REQUEST,
  advanceOption,
  task
});

export const advanceTaskSuccess = () => ({
  type: ADVANCE_TASK_SUCCESS
});

export const advanceTaskFailure = (error) => ({
  type: ADVANCE_TASK_FAILURE,
  error
});

export const updateTaskRequest = (task) => ({
  type: UPDATE_TASK_REQUEST,
  task
});

export const updateTaskSuccess = (taskId, task) => ({
  type: UPDATE_TASK_SUCCESS,
  taskId,
  task
});

export const updateAssigneeReassignSuccess = (assignee) => ({
  type: UPDATE_TASK_REASSIGNMENT_SUCCESS,
  assignee
});

export const updateTaskFailure = (error) => ({
  type: UPDATE_TASK_FAILURE,
  error
});

export const updateBulkTaskRequest = (payload) => ({
  type: UPDATE_BULK_TASK_REQUEST,
  payload
});

export const updateBulkTaskSuccess = () => ({
  type: UPDATE_BULK_TASK_SUCCESS
});

export const updateBulkTaskFailure = (error) => ({
  type: UPDATE_BULK_TASK_FAILURE,
  error
});

export const updateShelfTextToUpdateRequest = (taskId, shelfId, shelfLocalization) => ({
  type: UPDATE_SHELF_TEXT_TO_UPDATE_REQUEST,
  taskId,
  shelfId,
  shelfLocalization
});

export const updateShelfTextToUpdateSuccess = (taskId, shelfId, shelfLocalization) => ({
  type: UPDATE_SHELF_TEXT_TO_UPDATE_SUCCESS,
  taskId,
  shelfId,
  shelfLocalization
});

export const updateShelfTextToUpdateFailure = (error) => ({
  type: UPDATE_SHELF_TEXT_TO_UPDATE_FAILURE,
  error
});

export const addTaskCommentRequest = (workflowId, shelfId, comment) => ({
  type: ADD_TASK_COMMENT_REQUEST,
  workflowId,
  shelfId,
  comment
});

export const addTaskCommentSuccess = (shelfId, data) => ({
  type: ADD_TASK_COMMENT_SUCCESS,
  shelfId,
  data
});

export const addTaskCommentFailure = (error) => ({
  type: ADD_TASK_COMMENT_FAILURE,
  error
});

export const editTaskCommentRequest = (workflowId, shelfId, comment) => ({
  type: EDIT_TASK_COMMENT_REQUEST,
  workflowId,
  shelfId,
  comment
});

export const editTaskCommentSuccess = (shelfId, data) => ({
  type: EDIT_TASK_COMMENT_SUCCESS,
  shelfId,
  data
});

export const editTaskCommentFailure = (error) => ({
  type: EDIT_TASK_COMMENT_FAILURE,
  error
});

export const markAsReadRequest = (workflowId, sessionId, commentIds) => ({
  type: MARK_AS_READ_REQUEST,
  workflowId,
  sessionId,
  commentIds
});

export const markAsReadSuccess = (data) => ({
  type: MARK_AS_READ_SUCCESS,
  data
});

export const markAsReadFailure = (error) => ({
  type: MARK_AS_READ_FAILURE,
  error
});

export const cancelConfidenceScoreRequest = () => ({
  type: CANCEL_CONFIDENCE_SCORE_REQUEST
});

export const getConfidenceScoreRequest = (taskId, shelfId) => ({
  type: GET_CONFIDENCE_SCORE_REQUEST,
  taskId,
  shelfId
});

export const getConfidenceScoreSuccess = (data) => ({
  type: GET_CONFIDENCE_SCORE_SUCCESS,
  data
});

export const getConfidenceScoreFailure = (error, shelfId) => ({
  type: GET_CONFIDENCE_SCORE_FAILURE,
  error,
  shelfId
});

export const getTaskConfidenceScoreRequest = (taskId, callback) => ({
  type: GET_TASK_CONFIDENCE_SCORE_REQUEST,
  taskId,
  callback
});

export const getTaskConfidenceScoreSuccess = (data) => ({
  type: GET_TASK_CONFIDENCE_SCORE_SUCCESS,
  data
});

export const getTaskConfidenceScoreFailure = (error, shelfId) => ({
  type: GET_TASK_CONFIDENCE_SCORE_FAILURE,
  error,
  shelfId
});

export const removeTaskConfidenceScore = () => ({
  type: REMOVE_TASK_CONFIDENCE_SCORE
});

export const getTaskHistoryRequest = (taskId) => ({
  type: GET_TASK_HISTORY_REQUEST,
  taskId
});

export const getTaskHistorySuccess = (data) => ({
  type: GET_TASK_HISTORY_SUCCESS,
  data
});

export const getTaskHistoryFailure = (error) => ({
  type: GET_TASK_HISTORY_FAILURE,
  error
});

export const setNewHighlightRequest = (shelfId, highlight) => ({
  type: SET_NEW_HIGHLIGHT,
  shelfId,
  highlight
});

export const removeHighlightRequest = (shelfId) => ({
  type: REMOVE_HIGHLIGHT,
  shelfId
});

export const setActiveIssue = (shelfId, activeIssue) => ({
  type: SET_ACTIVE_ISSUE,
  shelfId,
  activeIssue
});

export const removeActiveIssue = (shelfId) => ({
  type: REMOVE_ACTIVE_ISSUE,
  shelfId
});

export const invalidateConfidenceScore = (shelfId) => ({
  type: INVALIDATE_CONFIDENCE_SCORE,
  shelfId
});

export const updateShelfPlainTextLength = (taskId, shelfId, length) => ({
  type: UPDATE_PLAIN_TEXT_LENGTH,
  taskId,
  shelfId,
  length
});

export const updateShelfInfo = (taskId, shelfId, info) => ({
  type: UPDATE_SHELF_INFO,
  taskId,
  shelfId,
  info
});

export const updateShelfInfoSuccess = () => ({
  type: UPDATE_SHELF_INFO_SUCCESS
});

export const updateShelfInfoFailure = (error) => ({
  type: UPDATE_SHELF_INFO_FAILURE,
  error
});

export const acceptIssueRequest = (
  taskId,
  shelfId,
  shelfLocalizationId,
  issueId,
  acceptedSuggestion
) => ({
  type: ACCEPT_ISSUE_REQUEST,
  taskId,
  shelfId,
  shelfLocalizationId,
  issueId,
  acceptedSuggestion
});

export const acceptIssueSuccess = (shelfId, data) => ({
  type: ACCEPT_ISSUE_SUCCESS,
  shelfId,
  data
});

export const acceptIssueFailure = (error) => ({
  type: ACCEPT_ISSUE_FAILURE,
  error
});

export const rejectIssueRequest = (taskId, shelfId, shelfLocalizationId, issueId) => ({
  type: REJECT_ISSUE_REQUEST,
  taskId,
  shelfId,
  shelfLocalizationId,
  issueId
});

export const rejectIssueSuccess = (shelfId, data) => ({
  type: REJECT_ISSUE_SUCCESS,
  shelfId,
  data
});

export const rejectIssueFailure = (error) => ({
  type: REJECT_ISSUE_FAILURE,
  error
});

export const clearTaskRequest = () => ({
  type: CLEAR_TASK
});

export const updateAliasCardRequest = (shelfId, actionUrl, cardId, payload) => ({
  type: UPDATE_ALIAS_CARD_REQUEST,
  shelfId,
  actionUrl,
  cardId,
  payload
});

export const updateAliasCardSuccess = (shelfId, card) => ({
  type: UPDATE_ALIAS_CARD_SUCCESS,
  shelfId,
  card
});

export const updateAliasCardFailure = (error) => ({
  type: UPDATE_ALIAS_CARD_FAILURE,
  error
});

export const updateSpokenNameCardRequest = (shelfId, actionUrl, cardId, payload) => ({
  type: UPDATE_SPOKEN_NAME_CARD_REQUEST,
  shelfId,
  actionUrl,
  cardId,
  payload
});

export const updateSpokenNameCardSuccess = (shelfId, card) => ({
  type: UPDATE_SPOKEN_NAME_CARD_SUCCESS,
  shelfId,
  card
});

export const updateSpokenNameCardFailure = (error) => ({
  type: UPDATE_SPOKEN_NAME_CARD_FAILURE,
  error
});

export const updatePronunciationCardShelfRequest = (shelfId, actionUrl, payload) => ({
  type: UPDATE_PRONUNCIATION_CARD_SHELF_REQUEST,
  shelfId,
  actionUrl,
  payload
});

export const updatePronunciationCardRequest = (cardId) => ({
  type: UPDATE_PRONUNCIATION_CARD_REQUEST,
  cardId
});

export const updatePronunciationCardSuccess = (shelfId, card) => ({
  type: UPDATE_PRONUNCIATION_CARD_SUCCESS,
  shelfId,
  card
});

export const updatePronunciationCardFailure = (error) => ({
  type: UPDATE_PRONUNCIATION_CARD_FAILURE,
  error
});

export const createAliasCardRequest = (shelfId, workflowId, payload) => ({
  type: CREATE_ALIAS_CARD_REQUEST,
  shelfId,
  workflowId,
  payload
});

export const createAliasCardSuccess = (shelfId, card) => ({
  type: CREATE_ALIAS_CARD_SUCCESS,
  shelfId,
  card
});

export const createAliasCardFailure = (error) => ({
  type: CREATE_ALIAS_CARD_FAILURE,
  error
});

export const deleteAliasCardRequest = (shelfId, cardId, actionUrl, postSuccessAction) => ({
  type: DELETE_ALIAS_CARD_REQUEST,
  shelfId,
  cardId,
  actionUrl,
  postSuccessAction
});

export const deleteAliasCardSuccess = (shelfId, cardId) => ({
  type: DELETE_ALIAS_CARD_SUCCESS,
  shelfId,
  cardId
});

export const deleteAliasCardFailure = (error) => ({
  type: DELETE_ALIAS_CARD_FAILURE,
  error
});

export const reservePronunciationRequest = (data) => ({
  type: RESERVE_PRONUNCIATION_REQUEST,
  data
});

export const reservePronunciationError = (err) => ({
  type: RESERVE_PRONUNCIATION_FAILURE,
  err
});

export const commitPronunciationRequest = (data) => ({
  type: COMMIT_PRONUNCIATION_REQUEST,
  data
});

export const commitPronunciationError = (err) => ({
  type: COMMIT_PRONUNCIATION_FAILURE,
  err
});

export const rejectEquivalenciesCardRequest = (actionUrl, shelfId) => ({
  type: REJECT_EQUIVALENCIES_CARD_REQUEST,
  actionUrl,
  shelfId
});

export const rejectEquivalenciesCardSuccess = (shelfId, cardId, card) => ({
  type: REJECT_EQUIVALENCIES_CARD_SUCCESS,
  shelfId,
  cardId,
  card
});

export const rejectEquivalenciesCardFailure = (error) => ({
  type: REJECT_EQUIVALENCIES_CARD_FAILURE,
  error
});
