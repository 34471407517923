import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const TableActionCells = ({
  actionColumns,
  dataRow,
  activeIndex,
  indexData,
  dataColumnId,
  actionButton
}) =>
  actionColumns.map((ac, actionColumnIndex) => (
    <Table.Cell
      key={`dataRowActionCell${indexData.toString()}${actionColumnIndex.toString()}`}
      className="mpe-table__cell-no-padding"
    >
      {actionButton(ac, indexData, dataRow, activeIndex, dataColumnId)}
    </Table.Cell>
  ));

TableActionCells.propTypes = {
  actionColumns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  dataRow: PropTypes.shape().isRequired,
  indexData: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  dataColumnId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  actionButton: PropTypes.func.isRequired
};

export default TableActionCells;
