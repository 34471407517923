import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { isUserAuthenticated, getUserPermissions } from '../store/user/userSelector';
import { ROUTES } from '../common';
import { updateMatch } from '../store/match/matchAction';
import { staticDataSuccess, staticDataRequest } from '../store/staticData/staticDataActions';
import { isAllowedByPermissions } from '../utils/generalUtils';
import LoaderWithText from '../components/LoaderWithText';

class UserRoute extends React.Component {
  componentDidMount() {
    const { computedMatch, changeMatch, setStaticData, fetchStaticData, isAuthenticated } =
      this.props;
    changeMatch(computedMatch);
    const staticDataFromCache = localStorage.staticData
      ? JSON.parse(localStorage.staticData)
      : null;
    if (staticDataFromCache) {
      setStaticData(staticDataFromCache);
      localStorage.staticData = JSON.stringify(staticDataFromCache);
      return;
    }
    if (isAuthenticated) fetchStaticData();
  }

  isAllowedByPermissions = (isAuthenticated, allowedPermissions, userPermissions) =>
    isAuthenticated && isAllowedByPermissions(allowedPermissions, userPermissions);

  render() {
    const {
      isAuthenticated,
      userPermissions,
      allowedPermissions,
      component: Component,
      ...rest
    } = this.props;
    const isUserAllowedByPermissions = this.isAllowedByPermissions(
      isAuthenticated,
      allowedPermissions,
      userPermissions
    );
    return (
      <Route
        {...rest}
        render={(props) =>
          isUserAllowedByPermissions ? (
            <Suspense fallback={<LoaderWithText />}>
              <Component {...props} />
            </Suspense>
          ) : (
            <Redirect to={ROUTES.welcomePage} />
          )
        }
      />
    );
  }
}

UserRoute.propTypes = {
  component: PropTypes.shape().isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  setStaticData: PropTypes.func.isRequired,
  fetchStaticData: PropTypes.func.isRequired,
  changeMatch: PropTypes.func.isRequired,
  computedMatch: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: isUserAuthenticated(state),
  userPermissions: getUserPermissions(state)
});

const mapDispatchToProps = {
  changeMatch: updateMatch,
  fetchStaticData: staticDataRequest,
  setStaticData: staticDataSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoute);
