import React, { FC, useState, SyntheticEvent } from 'react';
import { Modal } from '@tidbits/react-tidbits';
import { UserPreferencesContentBody } from './UserPreferencesContentBody';
import { UserPreferenceFooter } from './UserPreferencesFooter';
import { NavMenuOptions } from '../../../../../common/types/UserPreferences';

interface Props {
  navMenuOptions: NavMenuOptions;
  openTab: string;
  hide: (event: SyntheticEvent) => void;
  setRef: (refObject: any) => void;
}

const UserPreferencesContent: FC<Props>  = ({
  navMenuOptions,
  openTab,
  hide,
  setRef 
}) => {
  const [selectedKey, setSelectedKey] = useState(openTab || 'hotKeys');

  const handleOnClick = (key: string) => {
    setSelectedKey(key)
  }

  return (
    <Modal.Content>
      <UserPreferencesContentBody
        navMenuOptions={navMenuOptions}
        selectedKey={selectedKey}
        handleOnNavMenuClick={handleOnClick}
      />
      <UserPreferenceFooter
        selectedKey={selectedKey}
        hide={hide}
        setRef={setRef}
      />
    </Modal.Content>
  )
}

export default UserPreferencesContent;