import axiosInstance from '../utils/axiosInstance';
import { UserDetails, ErrorContext } from '@ui-components/error-page';

const BugReport = {
    submit: (correlationKey: string, userAgent: string, user: UserDetails, pageUrl: string, context: ErrorContext) => {
        return axiosInstance
            .post(`/api/api2/report-a-bug`, {
                correlationKey,
                userAgent,
                user,
                pageUrl,
                context
            })
            .then((res) => res.data);
    },
};

export default BugReport;
