import { User, mockUser } from '../../../common/types/User';
import ApiSimpleUser from '../models/ApiSimpleUser';
import { formatUnderscoreToTitleCase } from '../../generalUtils';

export const mapSimpleUsersFromApiToClient = (apiSimpleUsers: ApiSimpleUser[]): User[] => {
  const mappedUser = JSON.parse(JSON.stringify(mockUser));
  //TODO Fix for vendors and genres is because backend sometimes returns corrupted data
  return apiSimpleUsers.map((apiSimpleUser) => {
    const {
      identifier,
      email,
      displayName,
      vendor,
      status,
      locales,
      jobTypes,
      contentTypes,
      genres,
      rating,
      pendingTaskNumber,
      completedTaskNumber,
      rejectedTaskNumber
    } = apiSimpleUser;
    return {
      ...mappedUser,
      ...(identifier && { id: identifier }),
      ...(email && { email }),
      ...(displayName && { displayName }),
      ...(vendor && {
        vendorName: formatUnderscoreToTitleCase(vendor.displayName)
      }),
      ...(status && { available: status.key }),
      ...(status && { status }),
      ...(locales && { locales }),
      ...(locales && { localeCodes: locales.map((l) => l.key) }),
      ...(jobTypes && { jobTypes }),
      ...(contentTypes && { favoriteContentType: contentTypes }),
      ...(genres && {
        favoriteGenre: genres.map((genre) => ({
          ...genre,
          displayName: formatUnderscoreToTitleCase(genre.displayName)
        }))
      }),
      ...(rating && { averageStarRating: rating }),
      ...(pendingTaskNumber && { assignedTasks: pendingTaskNumber }),
      ...(completedTaskNumber && { publishedTasks: completedTaskNumber }),
      ...(rejectedTaskNumber && { rejectedTasks: rejectedTaskNumber })
    };
  });
};
