"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteStateEnum = exports.FilterDropDownView = exports.DateRangeOperator = exports.StateEnum = exports.FilterOperatorEnums = exports.FilterInputEnum = void 0;
var FilterInputEnum;
(function (FilterInputEnum) {
    FilterInputEnum["INPUT"] = "INPUT";
    FilterInputEnum["MULTIPLE_INPUT"] = "MULTIPLE_INPUT";
    FilterInputEnum["SELECT"] = "SELECT";
    FilterInputEnum["MULTI_VALUE_SELECT"] = "MULTI_VALUE_SELECT";
    FilterInputEnum["SEARCH_SELECT"] = "SEARCH_SELECT";
    FilterInputEnum["VALUE"] = "VALUE";
    FilterInputEnum["DATE"] = "DATE";
    FilterInputEnum["DATE_RANGE"] = "DATE_RANGE";
})(FilterInputEnum = exports.FilterInputEnum || (exports.FilterInputEnum = {}));
var FilterOperatorEnums;
(function (FilterOperatorEnums) {
    FilterOperatorEnums["INCLUDES_ALL"] = "Includes All";
    FilterOperatorEnums["EXCLUDES"] = "Excludes";
    FilterOperatorEnums["INCLUDES_ANY"] = "Includes Any";
    FilterOperatorEnums["IS"] = "Is";
    FilterOperatorEnums["IS_NOT"] = "Is Not";
    FilterOperatorEnums["CONTAINS"] = "Contains";
    FilterOperatorEnums["NOT_CONTAINS"] = "Does Not Contain";
    FilterOperatorEnums["INCLUDES"] = "Includes";
})(FilterOperatorEnums = exports.FilterOperatorEnums || (exports.FilterOperatorEnums = {}));
var StateEnum;
(function (StateEnum) {
    StateEnum["ACTIVE"] = "Active";
    StateEnum["EXPIRED"] = "Expired";
    StateEnum["FUTURE"] = "Future";
})(StateEnum = exports.StateEnum || (exports.StateEnum = {}));
var DateRangeOperator;
(function (DateRangeOperator) {
    DateRangeOperator["Is"] = "Is";
    DateRangeOperator["IsBefore"] = "Is Before";
    DateRangeOperator["IsAfter"] = "Is After";
    DateRangeOperator["IsBetween"] = "Is Between";
    DateRangeOperator["Today"] = "Today";
    DateRangeOperator["Yesterday"] = "Yesterday";
    DateRangeOperator["ThisWeek"] = "This Week";
    DateRangeOperator["ThisMonth"] = "This Month";
    DateRangeOperator["ThisYear"] = "This Year";
})(DateRangeOperator = exports.DateRangeOperator || (exports.DateRangeOperator = {}));
var FilterDropDownView;
(function (FilterDropDownView) {
    FilterDropDownView["FILTERS"] = "Filters";
    FilterDropDownView["PRESETS"] = "Presets";
})(FilterDropDownView = exports.FilterDropDownView || (exports.FilterDropDownView = {}));
var NoteStateEnum;
(function (NoteStateEnum) {
    NoteStateEnum["draft"] = "Draft";
    NoteStateEnum["draftSaveFailed"] = "Draft Saved Failed";
    NoteStateEnum["draftDeleted"] = "Draft Deleted";
    NoteStateEnum["live"] = "Live";
    NoteStateEnum["saved"] = "Saved";
    NoteStateEnum["changed"] = "Changed";
    NoteStateEnum["saveFailed"] = "Save Failed";
    NoteStateEnum["deleted"] = "Deleted";
    NoteStateEnum["deleteFailed"] = "Delete Failed";
    NoteStateEnum["active"] = "Active";
    NoteStateEnum["expired"] = "Expired";
    NoteStateEnum["future"] = "Future";
    NoteStateEnum["invalid"] = "Invalid";
})(NoteStateEnum = exports.NoteStateEnum || (exports.NoteStateEnum = {}));
