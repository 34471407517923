import React, { FC } from 'react';
import IconTemplate, { IconType } from '@ui-components/icon-template';

import '../../style/userRating.scss';

import { RATING_STAR_AVERAGE_SCORE } from '../../common';

interface RenderRatingStarProps {
  starRating: number;
}

const renderRatingStar: FC<RenderRatingStarProps> = ({ starRating }) => {
  const iconName: IconType =
    starRating > RATING_STAR_AVERAGE_SCORE.boundary ? 'fullStar' : 'emptyStar';
  return <IconTemplate name={iconName} color="yellow" className="mpe-icon_user_rating" />;
};

interface Props {
  rating: number;
}

const UserRating: FC<Props> = ({ rating }) => (
  <div className="mpe-user-rating">
    {renderRatingStar({ starRating: rating })}
    <span>{rating}</span>
  </div>
);

export default UserRating;
