// @ts-nocheck
// TODO: Fix ts-nocheck issues

import React, { Component } from 'react';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import {
  getSelectedTasks,
  selectedFiltersForReassign as selectedFiltersForMultipleReassign
} from '../../store/insertTask/insertTaskSelector';
import {
  taskSelector,
  selectedFiltersForReassign as selectedFiltersForSingleReassign
} from '../../store/task/taskSelector';
import { hideModal } from '../../store/modal/modalActions';
import { reassignTaskRequest } from '../../store/insertTask/insertTaskActions';
import InfoModal from './InfoModal/InfoModal';
import UnassignTask from '../../common/components/UnassignTask';
import i18n from '../../i18n';
import TRANSLATE_KEYS from '../../public/locales/translateKeys';
import { SimpleTask, Task, ReduxState, FilterParams } from '../../common/types';
import UsersReassignList from '../users/UsersReassignList';

interface ComponentProps {
  title: string;
  isRadioButton?: boolean;
  reassignedTask?: Task;
}

interface StateProps {
  selectedTasks: SimpleTask[];
  selectedTask: Task;
  selectedMultipleReassignFilters: FilterParams[];
  selectedSingleReassignFilter: FilterParams[];
}

interface DispatchProps {
  closeModal: () => void;
  reassignTasks: (tasks: SimpleTask[] | Task[], userId: any) => void;
  storeUsersFilter: (filter: FilterParams) => void;
}

type Props = ComponentProps & StateProps & DispatchProps;

interface State {
  selectedUserId: string;
  unassignTask: boolean;
}

const defaultState: State = {
  selectedUserId: '',
  unassignTask: false
};

export class ReassignUserModalContent extends Component<Props, State> {
  state = {
    ...defaultState
  };

  componentWillMount() {
    const { storeUsersFilter, selectedMultipleReassignFilters, selectedSingleReassignFilter } =
      this.props;
    this.isMultipleReassign()
      ? selectedMultipleReassignFilters.forEach((filter: FilterParams) => storeUsersFilter(filter))
      : selectedSingleReassignFilter.forEach((filter: FilterParams) => storeUsersFilter(filter));
  }

  submit = () => {
    const { selectedTasks, selectedTask, reassignTasks, closeModal, reassignedTask } = this.props;
    const { selectedUserId, unassignTask } = this.state;
    const userIdToReassign = unassignTask ? '' : selectedUserId;
    const canBeSubmitted = !!selectedUserId || unassignTask;
    if (canBeSubmitted) {
      if (reassignedTask) {
        reassignTasks([reassignedTask], userIdToReassign);
        closeModal();
      } else {
        selectedTasks.length
          ? reassignTasks(selectedTasks, userIdToReassign)
          : reassignTasks([selectedTask], userIdToReassign);
        closeModal();
      }
    }
  };

  onUnassignTaskCheckboxChange = () => {
    const { unassignTask } = this.state;
    this.setState({
      unassignTask: !unassignTask,
      selectedUserId: ''
    });
  };

  setSelectedUser = (selectedUserIds: string) => {
    const { unassignTask } = this.state;
    const uncheckUnassignTaskOption = unassignTask === true;
    const userId = selectedUserIds ? selectedUserIds : '';
    this.setState({
      selectedUserId: userId,
      unassignTask: uncheckUnassignTaskOption ? false : unassignTask
    });
  };

  isMultipleReassign() {
    const { selectedTasks } = this.props;
    return !!selectedTasks.length;
  }

  render() {
    const { title, closeModal } = this.props;
    const { selectedUserId, unassignTask } = this.state;
    return (
      <InfoModal
        title={title}
        closeModal={closeModal}
        onSuccess={this.submit}
        successButtonDisabled={!unassignTask && !selectedUserId}
        successLabel={i18n.t(TRANSLATE_KEYS.reassign_user.reassign_user_button)}
        isModalWithTable
      >
        <>
          <UsersReassignList
            checkBoxHeader
            selectedUsers={[selectedUserId]}
            isAssignment
            setSelected={this.setSelectedUser}
            isRadioButton
          />
          <UnassignTask
            onUnassignTaskCheckboxChange={this.onUnassignTaskCheckboxChange}
            unassignTask={unassignTask}
          />
        </>
      </InfoModal>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  selectedTasks: getSelectedTasks(state),
  selectedTask: taskSelector(state),
  selectedMultipleReassignFilters: selectedFiltersForMultipleReassign(state),
  selectedSingleReassignFilter: selectedFiltersForSingleReassign(state)
});

const mapDispatchToProps: DispatchProps = {
  closeModal: hideModal,
  reassignTasks: reassignTaskRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(ReassignUserModalContent);
