import React, { useState, useEffect, FC } from 'react';
import { Text, Input, Banner } from '@tidbits/react-tidbits';
import { Button, Modal, Spinner } from '@tidbits/react-tidbits';
import { validateWorklistRequest } from '../../store/worklist/worklistActions';
import { useDispatch, useSelector } from 'react-redux';
import { getValidateComplete } from '../../store/worklist/worklistSelector';

import './importExportModal.scss';

interface Props {
  hide: any;
  setPage: any;
  setRef: any;
  file: any;
  setFile: any;
  workflowType: string;
  label: string;
}

export const ImportValidationPage: FC<Props> = ({
  hide,
  setPage,
  setRef,
  file,
  setFile,
  workflowType,
  label
}) => {
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const validateComplete = useSelector(getValidateComplete);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (showSpinner && validateComplete) {
      onSuccessfulValidation();
    }

    const acceptableFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    if (!file[0]) return;

    if (file[0] && acceptableFiles.indexOf(file[0].type) === -1) {
      setShowError(true);
    } else {
      setShowError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, validateComplete, showSpinner]);

  const onSuccessfulValidation = () => {
    setShowSpinner(false);
    setPage('importTaskList');
  };

  const handleValidation = () => {
    setShowSpinner(true);
    dispatch(validateWorklistRequest({ type: workflowType, file: file[0] }));
  };

  const disableButton = () => {
    if (showError) return true;
    if (showSpinner) return true;
    return !!file.length && file[0] ? false : true;
  };

  return (
    <div>
      <Modal.Content>
        <Modal.Header>{label}</Modal.Header>
        <Modal.Body className="import--export-validation-page">
          {showError ? (
            <Banner.Inline
              className="import-export--error-banner"
              variant="error"
              spaceBelow="disassociate"
            >
              <Text>
                Wrong file format. Supported file formats are .xlsx .csv... Please upload file
                again.
              </Text>
            </Banner.Inline>
          ) : null}

          {showSpinner ? (
            <div className="import--text--in-progress">
              <Spinner opacity={1} size={25} visible={showSpinner} />
              <Text>{`Validating ${file[0].name}...`}</Text>
            </div>
          ) : (
            <>
              <Input.File className="import--file-input" onClick={(e: any) => e.stopPropagation()}>
                {({ files }: { files: any }) => (
                  <div className="import--file-input-inner">
                    <Text as="span" color="labelPlaceholsder">
                      Import File (.xlsx or .csv)
                    </Text>
                    <Text as="span" color="labelPlaceholder" mt="15px">
                      Or drag and drop a file here.
                    </Text>
                    {!!files.length && (
                      <>
                        <Text as="p" mt="15px">
                          {files[0].name}
                        </Text>
                      </>
                    )}
                    {!!files.length && setFile(files)}
                  </div>
                )}
              </Input.File>
            </>
          )}
        </Modal.Body>
      </Modal.Content>
      <Modal.Footer display="flex" flexDirection="row" justifyContent="flex-end">
        <Button ref={setRef} large standard type="button" disabled={false} onClick={hide} mr={10}>
          Cancel
        </Button>
        <Button large primary type="button" disabled={disableButton()} onClick={handleValidation}>
          Import
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default ImportValidationPage;
