import { all } from 'redux-saga/effects';
import availableTermsSaga from './availableTerms/availableTermsSaga';
import notificationsSaga from './notifications/notificationsSaga';
import reassignSaga from './reassignment/reassignSaga';
import staticDataSaga from './staticData/staticDataSaga';
import taskSaga from './task/taskSaga';
import userSaga from './user/userSaga';
import UserPreferencesRedux from '@helper-hooks/user-preferences-redux-action';
import usersSaga from './users/usersSaga';
import worklistSaga from './worklist/worklistSaga';
import alertSaga from './alerts/alertsSaga';
import api from '../api/api';

export default function* rootSaga() {
  yield all([
    notificationsSaga(),
    staticDataSaga(),
    taskSaga(),
    userSaga(),
    usersSaga(),
    worklistSaga(),
    availableTermsSaga(),
    reassignSaga(),
    UserPreferencesRedux.userPreferencesSaga(
      api.userPreferences.getPreferences,
      api.userPreferences.putPreferences
    ),
    alertSaga()
  ]);
}
