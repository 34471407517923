const mapFunction = (user) => ({
  name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : 'Unconfirmed user',
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  role: user.role, // TODO: this will probably become unnecessary soon
  jobTypes: user.jobTypes,
  vendorName: user.vendorName,
  locales: user.locales,
  localeCodes: user.localeCodes,
  available: user.available || false,
  favoriteGenre: user.favoriteGenre || [],
  favoriteContentType: user.favoriteContentType || [],
  favoriteMedia: user.favoriteMedia || [],
  averageStarRating: user.averageStarRating,
  vendorOrganization: user.vendorOrganization || '',
  createdAt: user.createdAt,
  publishedTasks: user.publishedTasks || 0,
  assignedTasks: user.assignedTasks || 0,
  rejectedTasks: user.rejectedTasks || 0,
  isEnabled: user.enabled,
  vendorId: user.vendorId,
  displayName: user.displayName,
  id: user.id
});

export default mapFunction;
