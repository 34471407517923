import { createSelector } from 'reselect';
import { mapAvailableTermsToDashboardFields } from '../../utils/worklistParams';
import { ReduxState } from '../../common/types';

export const getAvailableTerms = (state: ReduxState) => state.availableTerms;

export const getMappedAvailableTerms = createSelector(
  getAvailableTerms,
  (availableTerms) => availableTerms && mapAvailableTermsToDashboardFields(availableTerms)
);
