import React, { FC, Fragment } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Notification } from '../../../../common/types';
import i18n from '../../../../i18n';
import TRANSLATE_KEYS from '../../../../public/locales/translateKeys';
import { NotificationsResponse } from '../NotificationsDropdown/NotificationsDropdown';
import NotificationsMessageFactory from '../NotificationsMessageFactory/NotificationsMessageFactory';
import './notificationsList.scss';

interface Props {
  onNotificationClick(): void;
  onUnreadClick(): void;
  notifications: NotificationsResponse;
}

const NotificationsList: FC<Props> = ({ notifications, onNotificationClick, onUnreadClick }) => (
  <div className="mpe-navigation_notifications-list">
    <Dropdown.Item>
      <div className="mpe-navigation_notifications-list_info">
        <span className="mpe-navigation_notifications-list_total-notifications">
          {`${i18n.t(TRANSLATE_KEYS.notifications_panel.unread)}: ${notifications.unreadCount}`}
        </span>
      </div>
    </Dropdown.Item>
    <Dropdown.Divider className="mpe-navigation_notifications-list-divider" />
    {notifications.items &&
      notifications.items.map((notification: Notification, index: number) => (
        <Fragment key={`NotificationList${index}`}>
          <Dropdown.Item className="mpe-navigation_notifications-list-item">
            <NotificationsMessageFactory
              notification={notification}
              onNotificationClick={onNotificationClick}
              onUnreadClick={onUnreadClick}
            />
          </Dropdown.Item>
          <Dropdown.Divider className="mpe-navigation_notifications-list-divider" />
        </Fragment>
      ))}
  </div>
);

export default NotificationsList;
