import {
  SET_SORT,
  SET_FILTER,
  CLEAR_FILTERS,
  CLEAR_ALL_FILTERS,
  INIT_QUERY_PARAMS,
  SET_SELECTED_FILTERS,
  DELETE_FILTER
} from './queryParamsActionTypes';
import { SET_SEARCH_TERM } from '../searchTerm/searchTermActionTypes';

const initialState = {
  filterQueryString: '',
  nextCursor: '*',
  filter: [],
  sort: null,
  limit: null
};

export default function queryParams(queryName = '') {
  return function params(state = initialState, action = {}) {
    switch (action.type) {
      case `${SET_SORT}_${queryName}`: {
        if (action.sort) {
          return {
            ...state,
            sort: [action.sort],
            nextCursor: null
          };
        }
        return {
          ...state,
          sort: null
        };
      }
      case `${SET_SELECTED_FILTERS}_${queryName}`: {
        return {
          ...state,
          filter: [...action.filters]
        };
      }
      case `${SET_FILTER}_${queryName}`: {
        if(Array.isArray(action.filter)) {
          return {
            ...state,
            filterQueryString: action.query || '',
            filter: [...action.filter],
            nextCursor: null
          }
        }

        const index = state.filter.findIndex((fil) => fil.field === action.filter.field);
        if (index === -1) {
          return {
            ...state,
            filter: [...state.filter, action.filter],
            nextCursor: null
          };
        }
        return {
          ...state,
          filter: [
            ...state.filter.slice(0, index),
            action.filter,
            ...state.filter.slice(index + 1)
          ],
          nextCursor: null
        };
      }
      case `${DELETE_FILTER}_${queryName}`: {
        return {
          ...state,
          filter: state.filter.filter((filter) => filter.field !== action.filter.name)
        };
      }
      case `${CLEAR_FILTERS}_${queryName}`: {
        return {
          ...state,
          filter: [],
          nextCursor: null
        };
      }
      case `${CLEAR_ALL_FILTERS}_${queryName}`: {
        return { ...state, filter: [], nextCursor: null };
      }
      // Look into why it's triggering into an error
      case `GET_${queryName}_SUCCESS`: {
        const nextCursor = action.data?.nextCursor ? action.data.nextCursor : state.nextCursor;
        return { ...state, nextCursor };
      }
      case `GET_${queryName}_FAILURE`: {
        return { ...state, filter: [], sort: null };
      }
      case `GET_${queryName}_INFINITE_SUCCESS`: {
        return { ...state, nextCursor: action.data.nextCursor };
      }
      case SET_SEARCH_TERM: {
        return { ...state, nextCursor: initialState.nextCursor };
      }
      case INIT_QUERY_PARAMS: {
        return { ...state, ...action.queryParams[queryName] };
      }
      default:
        return state;
    }
  };
}
