import React, { FC, ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import { hostResolver } from '../../utils/envResolver';

interface Props {
  children: ReactNode;
  mediaIcon: ReactNode;
  logoIcon: ReactNode;
}

const HeaderNavigationLeftContainer = styled.div`
  display: flex;
`;

const ActionButtonsContainer = styled.div`
  min-width: 200px;
  display: flex;
`;

const ActionButton = styled.div<{ isUpdatebutton?: boolean }>`
  display: flex;
  width: ${({ isUpdatebutton }) => (isUpdatebutton ? `60px` : `50px`)};
  height: 50px;
  padding: 15px;
`;

const HeaderNavigationLeft: FC<Props> = ({ children, mediaIcon, logoIcon }) => {
  const actionButtons = React.Children.toArray(children);
  return (
    <>
      <HeaderNavigationLeftContainer>
        {logoIcon}
        {mediaIcon}
        <ActionButtonsContainer>
          {actionButtons.map((actionButton, i) => {
            const newActionButton = actionButton as ReactElement;
            // TODO: rdar://103050123 will remove once BE changes are updated
            if (
              !newActionButton.props.isDevEnabled ||
              ['localhost', 'itms7'].includes(hostResolver())
            ) {
              return (
                <ActionButton
                  key={i.toString()}
                  isUpdatebutton={newActionButton.props.isUpdatebutton}
                >
                  {actionButton}
                </ActionButton>
              );
            }
            return <></>;
          })}
        </ActionButtonsContainer>
      </HeaderNavigationLeftContainer>
    </>
  );
};

export default HeaderNavigationLeft;
