import { RSQLFilterFormatSimple } from './RSQLFilterFormatSimple';

export class RSQLFilterFormatComplex {
  operator: string;
  operands: (RSQLFilterFormatSimple | RSQLFilterFormatComplex)[];

  public constructor(
    operator: string,
    operands: (RSQLFilterFormatSimple | RSQLFilterFormatComplex)[]
  ) {
    this.operator = operator;
    this.operands = operands;
  }
}
