import { arrayToObject } from './generalUtils';
import debounce from './debounce';

const DEBOUNCE_DELAY = 500;
const KEY_FILTER = 'filter';
const KEY_SORT = 'sort';
const KEY_USER_PREFERENCES = 'userPreferences';

const STATIC_DATA_CALLS = [];

export const getInitialStateForRedux = () => {
  const mappedEmptyData = STATIC_DATA_CALLS.map((staticItem) => ({
    name: staticItem.name,
    data: []
  }));
  return arrayToObject(mappedEmptyData, 'name');
};

export const getPromisesForApiCall = (axiosInstance) =>
  STATIC_DATA_CALLS.map((dataCall) => axiosInstance.get(dataCall.apiLocation));

const adaptResponse = (rule, response) => (rule ? response.data : response);

export const setLocalStorageData = (staticData) => {
  const mappedData = staticData.map((staticItem, staticItemIndex) => ({
    name: STATIC_DATA_CALLS[staticItemIndex].name,
    data: adaptResponse(STATIC_DATA_CALLS[staticItemIndex].isSuperScalarResponse, staticItem)
  }));
  const dictionaryData = arrayToObject(mappedData, 'name');
  localStorage.staticData = JSON.stringify(dictionaryData);
  return dictionaryData;
};

export const removeStaticDataFromLocalStorage = () => {
  localStorage.removeItem('staticData');
};

export const removeAllFromLocalStorage = () => {
  localStorage.clear();
};

export const serializeFilterQueryParams = debounce((payload) => {
  localStorage.setItem(KEY_FILTER, JSON.stringify(payload));
}, DEBOUNCE_DELAY);

export const serializeSortQueryParams = debounce((payload) => {
  localStorage.setItem(KEY_SORT, JSON.stringify(payload));
}, DEBOUNCE_DELAY);

export const serializeUserPreferences = debounce((payload) => {
  localStorage.setItem(KEY_USER_PREFERENCES, JSON.stringify(payload));
}, DEBOUNCE_DELAY);

export const clearFilters = () => {
  localStorage.removeItem(KEY_FILTER);
};

export const serializeSelectedFilters = debounce((payload) => {
  localStorage.setItem('selectedFilters', JSON.stringify(payload));
}, DEBOUNCE_DELAY);

export const serializeSearchTerm = debounce((payload) => {
  sessionStorage.setItem('searchTerm', JSON.stringify(payload));
}, DEBOUNCE_DELAY);
