import { WorkflowUser } from '@providers/types-provider';

export interface ModalProps {
  title: string;
  size: ModalSize;
  open: boolean;
  submitAction: (data?: any) => void;
  leftButtonLabel: string;
  rightButtonLabel: string;
  data: WorkflowUser | null;
  payload?: any; // payloads are going to be different and dependent on modal
  exportSelectedTasks: boolean;
}

export enum ModalSize {
  MINI = 'mini',
  TINY = 'tiny',
  SMALL = 'small',
  LARGE = 'large',
  FULLSCREEN = 'fullscreen'
}
