"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranslationMemoryAlgorithmTypes = exports.TranslationMemoryTranslationStatus = void 0;
var TranslationMemoryTranslationStatus;
(function (TranslationMemoryTranslationStatus) {
    TranslationMemoryTranslationStatus["APPROVED"] = "APPROVED";
    TranslationMemoryTranslationStatus["PENDING_APPROVAL"] = "PENDING_APPROVAL";
    TranslationMemoryTranslationStatus["ARCHIVED"] = "ARCHIVED";
    TranslationMemoryTranslationStatus["FAILED"] = "FAILED";
})(TranslationMemoryTranslationStatus = exports.TranslationMemoryTranslationStatus || (exports.TranslationMemoryTranslationStatus = {}));
var TranslationMemoryAlgorithmTypes;
(function (TranslationMemoryAlgorithmTypes) {
    TranslationMemoryAlgorithmTypes["DENSE_VECTOR"] = "DENSE_VECTOR";
})(TranslationMemoryAlgorithmTypes = exports.TranslationMemoryAlgorithmTypes || (exports.TranslationMemoryAlgorithmTypes = {}));
