import { createSelector } from 'reselect';

import { DESCRIPTION_STRINGS } from '../../common';
import { ReduxState } from '../../common/types';

const getStaticData = (state: ReduxState) => state.staticData;

export const isStaticDataDone = createSelector(getStaticData, (staticData) => staticData.isDone);

export const makeGetStaticDataForKey = (
  key?: 'locales' | 'groupedLocales' | 'taskStatuses' | 'workflowTypes' | 'genres'
) =>
  createSelector(getStaticData, (staticData) =>
    key && staticData[key] ? staticData[key].data : []
  );

const getConfigData = createSelector(getStaticData, (staticData) => staticData.config.data);

export const getShelvesLimit = createSelector(getConfigData, (configData) => ({
  [DESCRIPTION_STRINGS.name]: configData.nameDescriptionLimit,
  [DESCRIPTION_STRINGS.short]: configData.shortDescriptionLimit
}));

export const makeGetShelfLimit = (shelfName: string) =>
  createSelector(getConfigData, (configData) => {
    switch (shelfName) {
      case DESCRIPTION_STRINGS.name:
        return configData.nameDescriptionLimit;
      case DESCRIPTION_STRINGS.short:
        return configData.shortDescriptionLimit;
      default:
        return 0;
    }
  });

export const getUserDashboardFields = createSelector(getStaticData, (staticData) =>
  staticData.dashboardFields.data.find((field: { type: string }) => field.type === 'user')
);
