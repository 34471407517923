import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';

import { Form, Box, Input, Button, InlineSpinner } from '@tidbits/react-tidbits';
import { useSelector } from 'react-redux';

import createLoadingSelector from '../../../../../store/loading/loadingSelector';

const FormContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 20px;
  padding-bottom: 15px;
`;

const FormTopRightContainer = styled(Box)`
  padding-right: 25px;
  maxwidth: 50%;
  flex: 1 0 50%;
`;

const FormTopLeftContainer = styled(Box)`
  flex: 1 0 50%;
  padding-left: 25px;
`;

const SpinnerContainer = styled(Box)`
  padding-right: 7px;
`;

export interface AlertDetails {
  title: string;
  type: string;
  description: string;
}

interface Props {
  alertDetails: AlertDetails;
  handleOnChange: (value: string, field: string) => void;
  handleOnPost: () => void;
}

const createAlertLoadingSelector = createLoadingSelector(['CREATE_ALERT']);

const GlobalAlertsForm: FC<Props> = ({ alertDetails, handleOnChange, handleOnPost }) => {
  const createAlertLoading = useSelector(createAlertLoadingSelector);
  return (
    <React.Fragment>
      <FormContainer>
        <FormTopRightContainer>
          <Form.Label>
            Title
            <Input.Text
              type="text"
              placeholder=""
              value={alertDetails.title}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleOnChange(e.target.value, 'title')
              }
            />
          </Form.Label>
        </FormTopRightContainer>
        <FormTopLeftContainer>
          <Form.Label htmlFor="alert-type" mb="spacer10">
            Type
          </Form.Label>
          <Input.DropdownSelect
            value={alertDetails.type}
            id="alert-type"
            onChange={(value: string) => handleOnChange(value, 'type')}
          >
            <option value="WARNING">Warning</option>
            <option value="OUTAGE">Outage</option>
          </Input.DropdownSelect>
        </FormTopLeftContainer>
      </FormContainer>
      <Form.Label>
        Description
        <Box position="relative">
          <Input.TextArea
            height="60px"
            defaultValue=""
            value={alertDetails.description}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleOnChange(e.target.value, 'description')
            }
          />
        </Box>
      </Form.Label>
      <ButtonContainer>
        <Button variant="standard" onClick={handleOnPost} disable={createAlertLoading}>
          Post
        </Button>
        {createAlertLoading && (
          <SpinnerContainer>
            <InlineSpinner visible={true} />
          </SpinnerContainer>
        )}
      </ButtonContainer>
    </React.Fragment>
  );
};

export default GlobalAlertsForm;
