import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import TableFilters, { PresetType } from '@ui-components/table-filters';
import UserPreferencesRedux from '@helper-hooks/user-preferences-redux-action';
import { NewTableFilterConfig } from './NewTableFilterConfig';
import { getAvailableTerms } from '../../store/availableTerms/availableTermsSelector';
import {
  getWorkflowQueryParamsFilters,
  getWorkflowQueryParamsQueryString,
  getWorklistRecordCount,
  getWorklistTotalItems
} from '../../store/worklist/worklistSelector';
import { mapAvailableTermsV2toV1 } from '../../utils/mappers';
import { getWorklistTasksRequest } from '../../store/worklist/worklistActions';
import { GetWorklistTasksRequestV2FiltersPayload } from '../../store/worklist/worklistActionTypes';
import createLoadingSelector from '../../store/loading/loadingSelector';
import { setFilterWorklist } from '../../store/queryParams/queryParamsActions';
import {
  getUserPreferences,
  getUserPreferencesWorklist
} from '../../store/userPreferences/userPreferencesSelector';
import api from '../../api/api';
import { useActions } from './useActions';
import ImportExportModal from '../importExportModal/ImportExportModal';
import { ModalRoot } from '@providers/modal-provider';

const loadingSelector = createLoadingSelector([
  'GET_USER_PREFERENCE',
  'GET_AVAILABLE_TERMS',
  'GET_WORKLIST'
]);

const NewTableFilter = () => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const currentUserPreferences = useSelector(getUserPreferences);
  const worklistUserPreferences = useSelector(getUserPreferencesWorklist);
  const displayedCounts = useSelector(getWorklistRecordCount);
  const totalCounts = useSelector(getWorklistTotalItems);
  const availableTerms = useSelector(getAvailableTerms);
  const query = useSelector(getWorkflowQueryParamsQueryString);
  const filters = useSelector(getWorkflowQueryParamsFilters);
  const isLoadingFilters = useSelector(loadingSelector);
  const [activeFilters, setActiveFilters] = useState([]);
  const actionsConfig = useActions({ activeFilters });

  const remappedAvailableTerms = useMemo(() => {
    if (!isEmpty(availableTerms)) {
      return mapAvailableTermsV2toV1(availableTerms);
    }
  }, [availableTerms]);

  const handleOnApplyButton = (query: any, raw: any) => {
    dispatch(setFilterWorklist(raw, query.q));
    dispatch(
      getWorklistTasksRequest({
        query: query.q,
        filters: raw
      } as GetWorklistTasksRequestV2FiltersPayload)
    );
    dispatch(
      UserPreferencesRedux.userPreferencesAction.putUserPreferenceRequest(
        'workflow',
        'worklist',
        { filtersV2: raw },
        currentUserPreferences
      )
    );
  };

  const handleOnSavePreset = (filterPresets: PresetType[], presetId?: string | null) => {
    let preferences =
      presetId !== undefined
        ? { list: filterPresets, lastUsedPresetId: presetId }
        : { list: filterPresets };
    dispatch(
      UserPreferencesRedux.userPreferencesAction.putUserPreferenceRequest(
        'workflow',
        'filterPresets',
        preferences,
        currentUserPreferences
      )
    );
  };

  const handleOnLastUsedPreset = (presetId: string | null) => {
    dispatch(
      UserPreferencesRedux.userPreferencesAction.putUserPreferenceRequest(
        'workflow',
        'filterPresets',
        { lastUsedPresetId: presetId },
        currentUserPreferences
      )
    );
  };

  const handleOnRefreshButton = () => {
    dispatch(
      getWorklistTasksRequest({
        query,
        filters
      } as GetWorklistTasksRequestV2FiltersPayload)
    );
  };

  const handleOnFilterChange = (activeFilterRaw: any) => {
    setActiveFilters(activeFilterRaw);
  };

  const { filtersV2 } = worklistUserPreferences;
  const { filterPresets } = currentUserPreferences;
  const existingRawFilter = hash
    ? JSON.parse(decodeURIComponent(hash.replace('#', '')))
    : filtersV2;

  return (
    <>
      <ModalRoot />
      <ImportExportModal />
      <TableFilters
        componentConfig={{
          hasShareFilterButton: true,
          hasRefreshButton: true,
          hasActionsButton: true,
          hasMoreAction: false,
          hasCreateCopyButton: false,
          hasLogicalOperator: true,
          hasSaveButton: true,
          title: '',
          displayedCounts,
          totalCounts
        }}
        existingRawFilter={existingRawFilter}
        presets={filterPresets.list}
        lastUsedPresetId={filterPresets.lastUsedPresetId}
        filterConfig={NewTableFilterConfig}
        actionsConfig={actionsConfig}
        additionalFilterOptions={remappedAvailableTerms}
        handleApplyButton={handleOnApplyButton}
        handleRefreshButton={handleOnRefreshButton}
        handleOnSavePreset={handleOnSavePreset}
        handleOnLastUsedPreset={handleOnLastUsedPreset}
        handleOnFilterChange={handleOnFilterChange}
        availableTermsSearchAPI={api.worklist.filterSearchQueryParamsUrl}
        isLoadingFilters={isLoadingFilters}
      />
    </>
  );
};

export default NewTableFilter;
