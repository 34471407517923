import React, { FC, useCallback } from 'react';
import { MenuList } from '@tidbits/react-tidbits';
import { 
  useDispatch, 
  // useSelector 
} from 'react-redux';

import { userLogoutRequest } from '../../../../store/user/userActions';
import LogoutButton from '../../buttons/LogoutButton';
// import { getUserPermissions } from '../../../../store/user/userSelector';
import { Pages } from '../../../../common/types/UserPreferences';
// import { PERMISSIONS } from '../../../../common';

interface Props {
  openUserPreferencesModal: (openTab: string) => void;
  setHidden: (isHidden: boolean) => void;
}


export const UserMenu: FC<Props> = ({ openUserPreferencesModal, setHidden }) => {
    const dispatch = useDispatch();
    // const permissions = useSelector(getUserPermissions);
    // const canViewBetaFeatures = permissions.includes(PERMISSIONS.canViewWorkflowBetaFeatures);

    const logout = useCallback(() => {
      sessionStorage.clear();
      dispatch(userLogoutRequest());
    }, [dispatch]);

    return (
      <MenuList>
        <MenuList.Item id="logoutButton" className="mpe-user-dropdown-menu__align_right">
        <a href={'https://copy.apple.com'}>
        <div className="mpe-user-dropdown-menu__menu_item">
          Copy at Apple
        </div>
        </a>
        </MenuList.Item>
        <MenuList.Item
          id="settingsButton"
          className="mpe-user-dropdown-menu__align_right"
          onClick={() => {
            openUserPreferencesModal(Pages.SETTINGS);
            setHidden(true);
          }}
        >
          <div className="mpe-user-dropdown-menu__menu_item">
            Settings
          </div>
        </MenuList.Item>
        {/* { canViewBetaFeatures && <MenuList.Item
          id="settingsButton"
          className="mpe-user-dropdown-menu__align_right"
          onClick={() => {
            openUserPreferencesModal(Pages.BETA_FEATURES);
            setHidden(true);
          }}
        >
          <div className="mpe-user-dropdown-menu__menu_item">
            Beta Features
          </div>
        </MenuList.Item> } */}
        <MenuList.Item
          id="settingsButton"
          className="mpe-user-dropdown-menu__align_right"
          onClick={() => {
            openUserPreferencesModal(Pages.HOT_KEYS);
            setHidden(true);
          }}
        >
          <div className="mpe-user-dropdown-menu__menu_item">
            Help
          </div>
        </MenuList.Item>
        <LogoutButton
          logoutText={'Logout'}
          logout={logout}
        />
    </MenuList>
    )
}