const TRANSLATE_KEYS = {
  welcome_to_react: 'welcome_to_react',
  loader: 'loader',
  you: 'you',
  unexpected_error: 'unexpected_error',
  of: 'of',
  yes: 'yes',
  no: 'no',
  agree: 'agree',
  disagree: 'disagree',
  reject: 'reject',
  approve: 'approve',
  welcome_page: {
    title: 'welcome_page.title',
    button_text: 'welcome_page.button_text',
    ok: 'welcome_page.ok',
    something_went_wrong: 'welcome_page.something_went_wrong',
    try_logging_in_again: 'welcome_page.try_logging_in_again'
  },
  login_page: {
    forgot_id: 'login_page.forgot_id',
    dont_have_account: 'login_page.dont_have_account',
    create_account: 'login_page.create_account',
    email_login_placeholder: 'login_page.email_login_placeholder',
    password_login_placeholder: 'login_page.password_login_placeholder',
    invalid_mail: 'login_page.invalid_mail',
    invalid_password: 'login_page.invalid_password'
  },
  confirm_user_page: {
    first_name_placeholder: 'confirm_user_page.first_name_placeholder',
    last_name_placeholder: 'confirm_user_page.last_name_placeholder',
    password_placeholder: 'confirm_user_page.password_placeholder',
    confirm_password_placeholder: 'confirm_user_page.confirm_password_placeholder'
  },
  reassign_modal: {
    reassign_modal_unassign_task_title: 'reassign_modal.reassign_modal_unassign_task_title',
    reassign_modal_unassign_task_text: 'reassign_modal.reassign_modal_unassign_task_text'
  },
  navigation_bar: {
    search_input_placeholder: 'navigation_bar.search_input_placeholder',
    logout: 'navigation_bar.logout',
    user_available: 'navigation_bar.user_available',
    user_unavailable: 'navigation_bar.user_unavailable',
    task_list: 'navigation_bar.task_list',
    user_list: 'navigation_bar.user_list',
    vendor_list: 'navigation_bar.vendor_list',
    analytics: 'navigation_bar.analytics'
  },
  notifications_panel: {
    notifications: 'notifications_panel.notifications',
    mark_all_as_read: 'notifications_panel.mark_all_as_read',
    unread: 'notifications_panel.unread',
    read: 'notifications_panel.read'
  },
  filters: {
    filtered_by: 'filters.filtered_by',
    assignee: 'filters.assignee',
    locale: 'filters.locale',
    dueDate: 'filters.dueDate',
    genres: 'filters.genres',
    statusChangeDate: 'filters.statusChangeDate',
    taskStatus: 'filters.taskStatus',
    workflowType: 'filters.workflowType',
    clear_filter: 'filters.clear_filter',
    select_date: 'filters.select_date',
    no_filter_selected: 'filters.no_filter_selected',
    add_filter: 'filters.add_filter',
    add_filters: 'filters.add_filters',
    available: 'filters.available',
    me: 'filters.me',
    no_results: 'filters.no_results'
  },
  submit: 'submit',
  cancel_dialog: 'cancel_dialog',
  /* ---> TASKS <--- */
  task_page: {
    task_list: 'task_page.task_list',
    empty_task_list: 'task_page.empty_task_list',
    empty_task_list_author: 'task_page.empty_task_list_author',
    empty_task_list_additional_info: 'task_page.empty_task_list_additional_info',
    table_headers: {
      name: 'task_page.table_headers.name',
      assignee: 'task_page.table_headers.assignee',
      locale: 'task_page.table_headers.locale',
      genres: 'task_page.table_headers.genres',
      adamId: 'task_page.table_headers.adamId',
      dueDate: 'task_page.table_headers.dueDate',
      statusChangeDate: 'task_page.table_headers.statusChangeDate',
      expirationDate: 'task_page.table_headers.expirationDate',
      workflowType: 'task_page.table_headers.workflowType',
      status: 'task_page.table_headers.status',
      lastComment: 'task_page.table_headers.lastComment',
      priority: 'task_page.table_headers.priority',
      contentType: 'task_page.table_headers.contentType',
      rating: 'task_page.table_headers.rating'
    }
  },
  task_details_no_page: {
    task_details_no_page_headline: 'task_details_no_page.task_details_no_page_headline',
    task_details_no_page_button: 'task_details_no_page.task_details_no_page_button'
  },
  task_details_page: {
    task_list_label: 'task_details_page.task_list_label',
    rejection: 'task_details_page.rejection',
    decline: 'task_details_page.decline',
    submit: 'task_details_page.submit',
    priority: 'task_details_page.priority',
    preview: 'task_details_page.preview',
    show_all: 'task_details_page.show_all',
    update_spoken_name: 'task_details_page.update_spoken_name',
    enter_spoken_name_placeholder: 'task_details_page.enter_spoken_name_placeholder',
    aliases_card_title: 'task_details_page.aliases_card_title',
    spoken_name_title: 'task_details_page.spoken_name_title',
    pronunciation_title: 'task_details_page.pronunciation_title',
    equivalencies_card_title: 'task_details_page.equivalencies_card_title',
    localized_word_for_rejected: 'task_details_page.localized_word_for_rejected',
    alias_type_title: 'task_details_page.alias_type_title',
    create_new_alias: 'task_details_page.create_new_alias',
    delete_alias_title: 'task_details_page.delete_alias_title',
    delete_alias_button_label: 'task_details_page.delete_alias_button_label',
    delete_alias_modal_text: 'task_details_page.delete_alias_modal_text',
    information: {
      information_assignee: 'task_details_page.information.information_assignee',
      reassign_task: 'task_details_page.information.reassign_task',
      information_due_date: 'task_details_page.information.information_due_date',
      no_date: 'task_details_page.information.no_date',
      edit_date: 'task_details_page.information.edit_date',
      information_day_left: 'task_details_page.information.information_day_left',
      information_days_left: 'task_details_page.information.information_days_left',
      information_priority: 'task_details_page.information.information_priority',
      information_high: 'task_details_page.information.information_high',
      information_normal: 'task_details_page.information.information_normal',
      information_prioritize: 'task_details_page.information.information_prioritize',
      information_prioritized: 'task_details_page.information.information_prioritized',
      information_copy_direction: 'task_details_page.information.information_copy_direction',
      information_locale: 'task_details_page.information.information_locale',
      information_adam_id: 'task_details_page.information.information_adam_id',
      information_task_type: 'task_details_page.information.information_task_type',
      information_status_change_date:
        'task_details_page.information.information_status_change_date',
      information_artist_name: 'task_details_page.information.information_artist_name',
      information_genre: 'task_details_page.information.information_genre',
      information_modal_prioritize_title:
        'task_details_page.information.information_modal_prioritize_title',
      information_modal_prioritize_content:
        'task_details_page.information.information_modal_prioritize_content'
    },
    copy: {
      copy_segments: 'task_details_page.copy.copy_segments',
      name: 'task_details_page.copy.name',
      short_description: 'task_details_page.copy.short_description',
      long_description: 'task_details_page.copy.long_description',
      song: 'task_details_page.copy.song',
      album: 'task_details_page.copy.album',
      artist: 'task_details_page.copy.artist'
    },
    comments: {
      send: 'task_details_page.comments.send',
      edit: 'task_details_page.comments.edit_comment',
      reply: 'task_details_page.comments.reply_comment',
      create_action_item: 'task_details_page.comments.create_action_item',
      comments_title: 'task_details_page.comments.comments_title',
      comment: 'task_details_page.comments.comment',
      comment_field_empty: 'task_details_page.comments.comment_field_empty',
      comment_edited: 'task_details_page.comments.comment_edited',
      comment_resolved: 'task_details_page.comments.comment_resolved',
      comment_resolved_by: 'task_details_page.comments.comment_resolved_by',
      comment_resolve: 'task_details_page.comments.comment_resolve',
      create_action_item_from_comment: 'task_details_page.comments.create_action_item_from_comment',
      create_action_item_from_comment_short:
        'task_details_page.comments.create_action_item_from_comment_short'
    },
    tabs: {
      task: 'task_details_page.tabs.task',
      inspect: 'task_details_page.tabs.inspect',
      empty_inspect_tab_description: 'task_details_page.tabs.empty_inspect_tab_description',
      unavailable_inspect_tab_description:
        'task_details_page.tabs.unavailable_inspect_tab_description',
      playlist_accordion_title: 'task_details_page.tabs.playlist_accordion_title',
      task_info_accordion_title: 'task_details_page.tabs.task_info_accordion_title',
      history_accordion_title: 'task_details_page.tabs.history_accordion_title',
      confidence_score_accordion_title: 'task_details_page.tabs.confidence_score_accordion_title',
      shelf_info_accordion_title: 'task_details_page.tabs.shelf_info_accordion_title',
      shelf_info_song_title: 'task_details_page.tabs.shelf_info_song_title',
      shelf_info_album_title: 'task_details_page.tabs.shelf_info_album_title',
      shelf_info_artist_title: 'task_details_page.tabs.shelf_info_artist_title',
      spoken_names_accordion_title: 'task_details_page.tabs.spoken_names_accordion_title',
      aliases_accordion_title: 'task_details_page.tabs.aliases_accordion_title',
      pronunciation_accordion_title: 'task_details_page.tabs.pronunciation_accordion_title',
      comments_accordion_title: 'task_details_page.tabs.comments_accordion_title'
    },
    name_shelf: {
      name_shelf_label_edited: 'task_details_page.name_shelf.name_shelf_label_edited',
      name_shelf_label_auto_generated:
        'task_details_page.name_shelf.name_shelf_label_auto_generated'
    },
    task_history: {
      task_history_show_more_details:
        'task_details_page.task_history.task_history_show_more_details',
      task_history_show_less_details:
        'task_details_page.task_history.task_history_show_less_details',
      task_history_item_changed: 'task_details_page.task_history.task_history_item_changed',
      task_history_item_changed_from:
        'task_details_page.task_history.task_history_item_changed_from',
      task_history_item_changed_to: 'task_details_page.task_history.task_history_item_changed_to',
      task_history_item_changed_by: 'task_details_page.task_history.task_history_item_changed_by',
      task_history_item_priority_normal:
        'task_details_page.task_history.task_history_item_priority_normal',
      task_history_item_priority_prioritized:
        'task_details_page.task_history.task_history_item_priority_prioritized',
      task_history_item_rejected: 'task_details_page.task_history.task_history_item_rejected',
      task_history_item_assigned: 'task_details_page.task_history.task_history_item_assigned',
      task_history_item_assignee_changed:
        'task_details_page.task_history.task_history_item_assignee_changed',
      task_history_item_status_changed:
        'task_details_page.task_history.task_history_item_status_changed',
      task_history_item_due_date_changed:
        'task_details_page.task_history.task_history_item_due_date_changed',
      task_history_item_status_priority_changed:
        'task_details_page.task_history.task_history_item_status_priority_changed'
    },
    confidence_score: {
      calculate_confidence_score: 'task_details_page.confidence_score.calculate_confidence_score',
      calculating_confidence_score:
        'task_details_page.confidence_score.calculating_confidence_score',
      confidence_score: 'task_details_page.confidence_score.confidence_score',
      confidence_score_error: 'task_details_page.confidence_score.confidence_score_error',
      apply_issue: 'task_details_page.confidence_score.apply_issue',
      suggested_change: 'task_details_page.confidence_score.suggested_change'
    },
    shelf_information: {
      artist_label: 'task_details_page.shelf_information.artist_label',
      about_label: 'task_details_page.shelf_information.about_label',
      born_label: 'task_details_page.shelf_information.born_label',
      hometown_label: 'task_details_page.shelf_information.hometown_label',
      genre_label: 'task_details_page.shelf_information.genre_label',
      adam_id_label: 'task_details_page.shelf_information.adam_id_label',
      song_label: 'task_details_page.shelf_information.song_label',
      album_label: 'task_details_page.shelf_information.album_label',
      year_label: 'task_details_page.shelf_information.year_label'
    },
    rich_text: {
      leave_comment: 'task_details_page.rich_text.leave_comment'
    },
    all_cards: {
      all_cards_table_title_locale: 'all_cards.all_cards_table_title_locale',
      all_cards_table_title_spoken_names: 'all_cards.all_cards_table_title_spoken_names',
      all_cards_table_title_alias: 'all_cards.all_cards_table_title_alias',
      all_cards_table_title_pronunciation: 'all_cards.all_cards_table_title_pronunciation',
      all_cards_history_new: 'all_cards.all_cards_history_new',
      all_cards_history_previous: 'all_cards.all_cards_history_previous',
      all_cards_history_few_seconds_ago: 'all_cards.all_cards_history_few_seconds_ago'
    },
    equivalencies: {
      tracks_label: 'task_details_page.equivalencies.tracks_label',
      reject_label: 'task_details_page.equivalencies.reject_label',
      genre_number_label: 'task_details_page.equivalencies.genre_number_label'
    }
  },
  task_create_modal: {
    title: 'task_create_modal.title',
    create_button: 'task_create_modal.create_button',
    song_adam_id: 'task_create_modal.song_adam_id',
    song_adam_id_placeholder: 'task_create_modal.song_adam_id_placeholder',
    invalid_song_adam_id: 'task_create_modal.invalid_song_adam_id',
    set_due_date: 'task_create_modal.set_due_date',
    locale_label: 'task_create_modal.locale_label',
    bypass_availability_check: 'task_create_modal.bypass_availability_check',
    bypass_availability_notification: 'task_create_modal.bypass_availability_notification',
    scheduled_task_creation_title: 'task_create_modal.scheduled_task_creation_title',
    scheduled_task_creation_text: 'task_create_modal.scheduled_task_creation_text',
    scheduled_task_creation_button: 'task_create_modal.scheduled_task_creation_button',
    calendar_placeholder_label: 'task_create_modal.calendar_placeholder_label'
  },
  music_player: {
    music_player_log_in: 'music_player.music_player_log_in',
    music_player_log_out: 'music_player.music_player_log_out',
    music_player_unavailable: 'music_player.music_player_unavailable'
  },
  rating_stars: {
    rating_stars_insufficient: 'rating_stars.rating_stars_insufficient',
    rating_stars_sufficient: 'rating_stars.rating_stars_sufficient',
    rating_stars_good: 'rating_stars.rating_stars_good',
    rating_stars_great: 'rating_stars.rating_stars_great',
    rating_stars_excellent: 'rating_stars.rating_stars_excellent'
  },
  advance_task: {
    modal_text_author: 'advance_task.modal_text_author',
    modal_text_editor_shakespeare: 'advance_task.modal_text_editor_shakespeare',
    modal_text_reviewer: 'advance_task.modal_text_reviewer'
  },
  reject_page: {
    reject_task_textarea_placeholder_author: 'reject_page.reject_task_textarea_placeholder_author',
    reject_task_textarea_placeholder_editor: 'reject_page.reject_task_textarea_placeholder_editor',
    reject_task_textarea_placeholder_reviewer:
      'reject_page.reject_task_textarea_placeholder_reviewer',
    reject_task_modal_headline: 'reject_page.reject_task_modal_headline',
    reject_task_modal_right_button_label: 'reject_page.reject_task_modal_right_button_label',
    comment_error: 'reject_page.comment_error',
    reject_task_modal_text: 'reject_page.reject_task_modal_text',
    rejection_reason: 'reject_page.rejection_reason',
    rejection_reason_headline: 'reject_page.rejection_reason_headline',
    rejection_reason_add: 'reject_page.rejection_reason_add',
    rejection_reason_placeholder: 'reject_page.rejection_reason_placeholder'
  },
  resolve_page: {
    resolve_task_textarea_placeholder_author:
      'resolve_page.resolve_task_textarea_placeholder_author',
    resolve_task_textarea_placeholder_editor:
      'resolve_page.resolve_task_textarea_placeholder_editor',
    resolve_task_textarea_placeholder_reviewer:
      'resolve_page.resolve_task_textarea_placeholder_reviewer',
    resolve_task_modal_headline_author: 'resolve_page.resolve_task_modal_headline_author',
    resolve_task_modal_headline_editor_shakespeare:
      'resolve_page.resolve_task_modal_headline_editor_shakespeare',
    resolve_task_modal_headline_reviewer: 'resolve_page.resolve_task_modal_headline_reviewer',
    resolve_task_modal_right_button_label: 'resolve_page.resolve_task_modal_right_button_label',
    resolve_task_modal_right_button_label_rate_shakespeare:
      'resolve_page.resolve_task_modal_right_button_label_rate_shakespeare',
    comment_error: 'resolve_page.comment_error'
  },
  confidence_score: {
    confidence_score_modal_info_content: 'confidence_score.confidence_score_modal_info_content',
    confidence_score_modal_title: 'confidence_score.confidence_score_modal_title',
    confidence_score_modal_right_button_label:
      'confidence_score.confidence_score_modal_right_button_label',
    confidence_score_modal_left_button_label:
      'confidence_score.confidence_score_modal_left_button_label',
    confidence_comment_required_text: 'confidence_score.confidence_comment_required_text',
    calculate_confidence_score_modal_title:
      'confidence_score.calculate_confidence_score_modal_title',
    calculate_confidence_score_modal_content:
      'confidence_score.calculate_confidence_score_modal_content',
    calculate_confidence_score_modal_button:
      'confidence_score.calculate_confidence_score_modal_button'
  },
  export: {
    export_success_button_label: 'export.export_success_button_label'
  },
  export_valid: {
    export_all_tasks: 'export_valid.export_all_tasks',
    export_valid_one_task_headline: 'export_valid.export_valid_one_task_headline',
    export_valid_headline: 'export_valid.export_valid_headline',
    export_valid_one_task_sub_headline: 'export_valid.export_valid_one_task_sub_headline',
    export_valid_sub_headline: 'export_valid.export_valid_sub_headline',
    export_and_update_valid_sub_headline: 'export_valid.export_and_update_valid_sub_headline'
  },
  reassign_user: {
    reassign_user_title: 'reassign_user.reassign_user_title',
    reassign_user_title_multiple: 'reassign_user.reassign_user_title_multiple',
    reassign_user_button: 'reassign_user.reassign_user_button'
  },
  /* ---> USERS <--- */
  user_details_page: {
    empty_user_list: 'user_details_page.empty_user_list',
    user_add_user: 'user_details_page.user_add_user',
    table_headers: {
      display_name: 'user_details_page.table_headers.display_name',
      email: 'user_details_page.table_headers.email',
      jobTypes: 'user_details_page.table_headers.jobTypes',
      vendorName: 'user_details_page.table_headers.vendorName',
      locales: 'user_details_page.table_headers.locales',
      available: 'user_details_page.table_headers.available',
      favoriteGenre: 'user_details_page.table_headers.favoriteGenre',
      favoriteContentType: 'user_details_page.table_headers.favoriteContentType',
      createdAt: 'user_details_page.table_headers.createdAt',
      publishedTasks: 'user_details_page.table_headers.publishedTasks',
      assignedTasks: 'user_details_page.table_headers.assignedTasks',
      rejectedTasks: 'user_details_page.table_headers.rejectedTasks',
      averageStarRating: 'user_details_page.table_headers.averageStarRating'
    },
    invalid_mail: 'user_details_page.invalid_mail',
    invalid_first_name: 'user_details_page.invalid_first_name',
    invalid_last_name: 'user_details_page.invalid_last_name',
    something_went_wrong: 'user_details_page.something_went_wrong',
    email: 'user_details_page.email',
    email_placeholder: 'user_details_page.email_placeholder',
    first_name: 'user_details_page.first_name',
    first_name_placeholder: 'user_details_page.first_name_placeholder',
    last_name: 'user_details_page.last_name',
    last_name_placeholder: 'user_details_page.last_name_placeholder',
    invalid_job_type: 'user_details_page.invalid_job_type',
    delete_confirm_message_header: 'user_details_page.delete_confirm_message_header',
    delete_confirm_message_content: 'user_details_page.delete_confirm_message_content',
    edit_confirm_message: 'user_details_page.edit_confirm_message',
    add_user_headline: 'user_details_page.add_user_headline',
    add_user_button: 'user_details_page.add_user_button',
    assign_job_type_header: 'user_details_page.assign_job_type_header',
    assign_job_type_header_placeholder: 'user_details_page.assign_job_type_header_placeholder',
    select_languages: 'user_details_page.select_languages',
    select_languages_placeholder: 'user_details_page.select_languages_placeholder',
    invalid_languages: 'user_details_page.invalid_languages',
    select_content_type: 'user_details_page.select_content_type',
    select_content_type_placeholder: 'user_details_page.select_content_type_placeholder',
    invalid_content_type: 'user_details_page.invalid_content_type',
    select_media: 'user_details_page.select_media',
    select_media_placeholder: 'user_details_page.select_media_placeholder',
    invalid_media: 'user_details_page.invalid_media',
    select_genre: 'user_details_page.select_genre',
    select_genre_placeholder: 'user_details_page.select_genre_placeholder',
    invalid_genre: 'user_details_page.invalid_genre',
    availability_header: 'user_details_page.availability_header',
    invalid_availability: 'user_details_page.invalid_availability',
    select_vendor_organization: 'user_details_page.select_vendor_organization',
    select_vendor_organization_placeholder:
      'user_details_page.select_vendor_organization_placeholder',
    invalid_vendor_organization: 'user_details_page.invalid_vendor_organization',
    personal_info_header: 'user_details_page.personal_info_header',
    permissions_header: 'user_details_page.permissions_header'
  },
  user_availability: {
    user_availability_message_header: 'user_availability.user_availability_message_header',
    user_availability_message_content: 'user_availability.user_availability_message_content',
    user_availability_button: 'user_availability.user_availability_button',
    user_available: 'user_availability.user_available'
  },
  edit_user: {
    edit_user_headline: 'edit_user.edit_user_headline',
    edit_user_button: 'edit_user.edit_user_button'
  },
  delete_not_possible: {
    delete_not_possible_headline: 'delete_not_possible.delete_not_possible_headline',
    delete_not_possible_sub_headline: 'delete_not_possible.delete_not_possible_sub_headline'
  },
  /* ---> VENDORS <--- */
  vendor_details_page: {
    empty_vendor_list: 'vendor_details_page.empty_vendor_list',
    add_vendor_sub_headline: 'vendor_details_page.add_vendor_sub_headline',
    table_headers: {
      name: 'vendor_details_page.table_headers.name',
      numberOfAuthors: 'vendor_details_page.table_headers.numberOfAuthors',
      numberOfEditors: 'vendor_details_page.table_headers.numberOfEditors'
    },
    invalid_name: 'vendor_details_page.invalid_name',
    something_went_wrong: 'vendor_details_page.something_went_wrong',
    name: 'vendor_details_page.name',
    assign_languages: 'vendor_details_page.assign_languages',
    select_languages: 'vendor_details_page.select_languages',
    invalid_languages: 'vendor_details_page.invalid_languages',
    add_vendor_headline: 'vendor_details_page.add_vendor_headline',
    edit_vendor_headline: 'vendor_details_page.edit_vendor_headline',
    add_vendor_button: 'vendor_details_page.add_vendor_button'
  },
  date_picker: {
    confirm_button_label: 'date_picker.confirm_button_label'
  },
  expiration_page: {
    expiration_message: 'Your confirmation link has expired. Please contact your admin.'
  },
  /* ---> TOAST MESSAGES <--- */
  toast_messages: {
    successful: {
      successful_advanced_task: 'toast_messages.successful.successful_advanced_task',
      successful_created_user: 'toast_messages.successful.successful_created_user',
      successful_edited_user: 'toast_messages.successful.successful_edited_user',
      successful_created_vendor: 'toast_messages.successful.successful_created_vendor',
      successful_edited_vendor: 'toast_messages.successful.successful_edited_vendor',
      successful_deleted_user: 'toast_messages.successful.successful_deleted_user',
      successful_reassign_tasks: 'toast_messages.successful.successful_reassign_tasks',
      successful_reassign_task: 'toast_messages.successful.successful_reassign_task'
    },
    warning: {
      forbidden: 'toast_messages.warning.forbidden'
    },
    error: {
      error_on_advance_task: 'toast_messages.error.error_on_advance_task',
      error_on_reassign_task: 'toast_messages.error.error_on_reassign_task',
      error_something_went_wrong: 'toast_messages.error.error_something_went_wrong',
      error_on_confidence_score: 'toast_messages.error.error_on_confidence_scor',
      error_on_device: 'toast_messages.error.error_on_device',
      too_many_permissions: 'toast_messages.error.too_many_permissions'
    }
  },
  /* ---> AUDIO RECORDING <--- */
  audio_recording: {
    record: 'audio_recording.record',
    recording: 'audio_recording.recording',
    processing: 'audio_recording.processing'
  }
};
export default TRANSLATE_KEYS;
