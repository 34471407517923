import { createSelector } from 'reselect';
import { ReduxState, SimpleTask } from '../../common/types';
import { mapTaskStateToJobType } from '../../utils/generalUtils';
import taskListUtils, { taskCanBeReassigned } from '../../components/tasks/TaskListUtils';

export const getInsertTask = (state: ReduxState) => state.insertTask;
export const insertTaskResults = createSelector(getInsertTask, (insertTask) => insertTask.results);
export const getSelectedTasksIds = createSelector(
  getInsertTask,
  (insertTask) => insertTask.selectedIndexes
);
export const createTaskErrorSelector = createSelector(
  getInsertTask,
  (insertTask) => insertTask.createTaskError
);
export const getInsertTaskTotalItems = createSelector(
  getInsertTask,
  (insertTask) => insertTask.totalItems
);

export const getTasksRecordCount = createSelector(
  getInsertTask,
  (insertTask) => insertTask.totalRecordItems
);

export const areTasksLoaded = createSelector(insertTaskResults, (tasks) => !!tasks.length);

export const getTaskNumber = createSelector(insertTaskResults, (tasks) => tasks.length);

export const getSelectedTasks = createSelector(
  getSelectedTasksIds,
  insertTaskResults,
  (selectedIds, allTasks) => {
    const selectedTasks = [];
    for (const taskId of selectedIds) {
      const task = allTasks.find((item: SimpleTask) => item.id === taskId);
      if (task) selectedTasks.push(task);
    }
    return selectedTasks;
  }
);

export const selectedTasksNotAssignable = createSelector(
  getSelectedTasks,
  (selectedTasks) =>
    !selectedTasks.length ||
    selectedTasks.some((selectedTask) => !taskCanBeReassigned(selectedTask))
);

const getSelectedLocales = createSelector(getSelectedTasks, (selectedTasks) => [
  ...new Set(selectedTasks.map((selectedTask) => selectedTask.locale.key))
]);

const getSelectedJobTypes = createSelector(getSelectedTasks, (selectedTasks) => {
  const jobTypes = selectedTasks
    .map((selectedTask) => mapTaskStateToJobType(selectedTask.taskState))
    .filter((jobType) => !!jobType);
  return [...new Set(jobTypes)];
});

export const selectedFiltersForReassign = createSelector(
  getSelectedLocales,
  getSelectedJobTypes,
  taskListUtils.filtersForTaskReassign
);
