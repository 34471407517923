import React, { FC } from 'react';

import NotificationsDefaultMessage from '../NotificationsDefaultMessage/NotificationsDefaultMessage';
import { Notification } from '../../../../common/types';

interface Props {
  notification: Notification;
  onNotificationClick(): void;
  onUnreadClick(): void;
}

const NotificationsMessageFactory: FC<Props> = ({
  notification,
  onNotificationClick,
  onUnreadClick
}) => {
  switch (notification.eventType.key) {
    case 'TASK_STATUS_CHANGE':
    case 'MENTION':
      return (
        <NotificationsDefaultMessage
          notification={notification}
          onNotificationClick={onNotificationClick}
          onUnreadClick={onUnreadClick}
        />
      );
    default:
      return <></>;
  }
};

export default NotificationsMessageFactory;
