import React, { FC, useState } from 'react';
import { withModal } from '@providers/modal-provider';
import InfoModal from '@ui-components/info-modal';
import { useSelector } from 'react-redux';
import { getUserPreferencesFilterPresets } from '../../../store/userPreferences/userPreferencesSelector';
import { FilterConfigType } from '@providers/types-provider';
import ImportPresetAccordion from '../../ImportPresetAccordion/ImportPresetAccordion';

interface Props {
  filterConfig: FilterConfigType[];
  onClose: () => void;
}

const ShareFiltersModal: FC<Props> = ({ filterConfig, onClose }) => {
  const filterPresets = useSelector(getUserPreferencesFilterPresets);
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>([]);

  const handleOnSuccessButton = () => {
    const filename = 'shareFilters.json';
    const data = filterPresets.list.filter((filter: any) => selectedFilterIds.includes(filter.id));
    downloadFile(JSON.stringify(data, null, 2), filename);
  };

  const downloadFile = (data: any, filename: any) => {
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <InfoModal
      isFullHeight
      title={'Share Filter Presets'}
      closeModal={onClose}
      closeLabel="Cancel"
      onSuccess={handleOnSuccessButton}
      successLabel="Export"
    >
      <ImportPresetAccordion
        filterConfig={filterConfig}
        presetList={filterPresets.list}
        selectedFilterIds={selectedFilterIds}
        setSelectedFilterIds={setSelectedFilterIds}
      />
    </InfoModal>
  );
};

export default withModal(ShareFiltersModal);
