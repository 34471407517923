import React, { FC } from 'react';
import { Icons } from '@tidbits/react-tidbits';
import styled from 'styled-components';

const StyledIconWrapper = styled.div`
  margin-left: auto;
  opacity: 0.5;
  transition: 0.3s all;
  &:hover {
    opacity: 1 !important;
    cursor: pointer;
  }
`;

interface Props {
  handleOnClick: (e: any) => void;
}

const DeleteIcon: FC<Props> = ({ handleOnClick }) => {
  return (
    <StyledIconWrapper>
      <Icons.DeleteIcon
        name={'delete'}
        width={18}
        height={18}
        color={'appleMusicRed'}
        onClick={handleOnClick}
      />
    </StyledIconWrapper>
  );
};

export default DeleteIcon;
