import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import './i18n';

import store from './store/index';
import history from './utils/history';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './common/components/ErrorBoundary/ErrorBoundary';

ReactDOM.render(
  <ErrorBoundary>
    <Router history={history}>
      <Provider store={store}>
        <Route component={App} />
      </Provider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
