import React, { FC, ReactNode, ComponentProps } from 'react';
import { connect } from 'react-redux';

import { ReduxState } from '../../common/types';
import { makeFeatureFlagSelector } from '../../store/featureFlags/featureFlagsSelector';

interface StateProps {
  isEnabled: boolean;
}

interface EnabledFeatureProps {
  featureName: string;
  children: ReactNode;
}

type Props = StateProps & EnabledFeatureProps & ComponentProps<FC>;

const EnabledFeature: FC<Props> = ({ isEnabled, children }: Props) => {
  if (isEnabled) {
    return <>{children}</>;
  }

  return null;
};

const mapStateToProps = (state: ReduxState, ownProps: EnabledFeatureProps): StateProps => {
  const featureFlagSelector = makeFeatureFlagSelector(ownProps.featureName);
  return {
    isEnabled: featureFlagSelector(state)
  };
};

export default connect(mapStateToProps)(EnabledFeature);
