import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import {
  fetchUsersSuccess,
  fetchUsersFailure,
  createUserSuccess,
  createUserFailure,
  fetchUsersForReassignFailure,
  fetchUsersForReassignSuccess
} from './usersActions';

import api from '../../api/api';
import { hideModal } from '../modal/modalActions';
import {
  generateToastMessage,
  getDefaultQueryParams,
  mapTaskStateToJobType,
  getUniqueValueArrayByKey
} from '../../utils/generalUtils';
import { TOAST_TYPES } from '../../common';
import TRANSLATE_KEYS from '../../public/locales/translateKeys';
import { getQueryParamsUsers } from '../queryParams/queryParamsSelector';
import {
  GET_USERS_REQUEST,
  CREATE_USER_REQUEST,
  GET_USERS_FOR_REASSIGN_REQUEST
} from './usersActionTypes';
import { adoptSimpleUsers } from '../../utils/AntiCorruption/UserMappingLayer';
import { getSelectedTasks } from '../insertTask/insertTaskSelector';
import { taskSelector } from '../task/taskSelector';

// TODO: Remove this if it is not used
export function* fetchUsers(action: any) {
  try {
    const storeQueryParams = yield select(getQueryParamsUsers);
    const allQueryParams = { ...storeQueryParams };
    const queryParams = action.queryParams || allQueryParams;
    const users = yield call(api.user.fetchUsers, {
      ...queryParams,
      nextCursor: null
    });
    yield put(fetchUsersSuccess(adoptSimpleUsers(users)));
  } catch (err) {
    yield put(fetchUsersFailure(err));
  }
}

export function* fetchUsersForReassignRequest(action: any) {
  try {
    const storeQueryParams = yield select(getQueryParamsUsers);
    const selectedTasks = yield select(getSelectedTasks);
    const task = yield select(taskSelector);
    const tasksArrayForReassign = selectedTasks.length > 0 ? selectedTasks : [task];
    const uniqueLocalesArray = getUniqueValueArrayByKey(tasksArrayForReassign, 'locale');
    const { sort, pageSize, cursor } = getDefaultQueryParams(storeQueryParams);
    const payload = {
      jobType: mapTaskStateToJobType(tasksArrayForReassign[0].taskState),
      workflowType: tasksArrayForReassign[0].workflowType,
      locales: uniqueLocalesArray,
      status: 'ACTIVE',
      ...action.queryParams,
      sort,
      pageSize,
      cursor
    };
    const users = yield call(api.user.fetchUsersForReassign, payload);
    yield put(fetchUsersForReassignSuccess(adoptSimpleUsers(users)));
  } catch (err) {
    yield put(fetchUsersForReassignFailure(err));
  }
}

export function* createUser(action: any) {
  try {
    const user = yield call(api.user.createUser, action.user);
    yield put(createUserSuccess(user));
    yield put(
      generateToastMessage(
        TOAST_TYPES.info,
        TRANSLATE_KEYS.toast_messages.successful.successful_created_user,
        { userMail: user.email }
      )
    );
    yield put(hideModal());
  } catch (err) {
    yield put(createUserFailure('Creating a user was not successful'));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_USERS_REQUEST, fetchUsers),
    takeLatest(GET_USERS_FOR_REASSIGN_REQUEST, fetchUsersForReassignRequest),
    takeLatest(CREATE_USER_REQUEST, createUser)
  ]);
}
