import { UpdateArtistNameAction } from './artistNameAction';
import { ArtistNameActionTypes } from './artistNameActionTypes';
import { ArtistName } from '../../common/types';

const initialState: ArtistName = {
  artist: ''
};

export type ArtistNameAction = UpdateArtistNameAction;

const artistNameReducer = (state: ArtistName = initialState, action: ArtistNameAction) =>
  action.type === ArtistNameActionTypes.UPDATE_ARTIST ? { artist: action.artist } : state;

export default artistNameReducer;
