import {
  UPDATE_ALIAS_CARD,
  UPDATE_ALIAS_CARD_REQUEST,
  UPDATE_ALIAS_CARD_SUCCESS,
  DELETE_ALIAS_CARD,
  DELETE_ALIAS_CARD_REQUEST,
  DELETE_ALIAS_CARD_SUCCESS,
  CREATE_ALIAS_CARD,
  CREATE_ALIAS_CARD_REQUEST,
  CREATE_ALIAS_CARD_SUCCESS,
  UPDATE_SPOKEN_NAME_CARD,
  UPDATE_SPOKEN_NAME_CARD_REQUEST,
  UPDATE_SPOKEN_NAME_CARD_SUCCESS,
  UPDATE_PRONUNCIATION_CARD_REQUEST,
  UPDATE_PRONUNCIATION_CARD_SUCCESS,
  UPDATE_PRONUNCIATION_CARD_FAILURE,
  UPDATE_PRONUNCIATION_CARD
} from '../task/taskActionTypes';

export default function loadingNestedReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_ALIAS_CARD_REQUEST:
      return {
        ...state,
        CREATE_ALIAS_CARD: {
          ...state[CREATE_ALIAS_CARD],
          [action.shelfId]: true
        }
      };
    case CREATE_ALIAS_CARD_SUCCESS:
      return {
        ...state,
        CREATE_ALIAS_CARD: {
          ...state[CREATE_ALIAS_CARD],
          [action.shelfId]: false
        }
      };
    case UPDATE_ALIAS_CARD_REQUEST:
      return {
        ...state,
        UPDATE_ALIAS_CARD: {
          ...state[UPDATE_ALIAS_CARD],
          [action.cardId]: true
        }
      };
    case UPDATE_ALIAS_CARD_SUCCESS:
      return {
        ...state,
        UPDATE_ALIAS_CARD: {
          ...state[UPDATE_ALIAS_CARD],
          [action.card.id]: false
        }
      };
    case DELETE_ALIAS_CARD_REQUEST:
      return {
        ...state,
        DELETE_ALIAS_CARD: {
          ...state[DELETE_ALIAS_CARD],
          [action.cardId]: true
        }
      };

    case DELETE_ALIAS_CARD_SUCCESS:
      return {
        ...state,
        DELETE_ALIAS_CARD: {
          ...state[DELETE_ALIAS_CARD],
          [action.cardId]: false
        }
      };

    case UPDATE_SPOKEN_NAME_CARD_REQUEST:
      return {
        ...state,
        UPDATE_SPOKEN_NAME_CARD: {
          ...state[UPDATE_SPOKEN_NAME_CARD],
          [action.cardId]: true
        }
      };

    case UPDATE_SPOKEN_NAME_CARD_SUCCESS:
      return {
        ...state,
        UPDATE_SPOKEN_NAME_CARD: {
          ...state[UPDATE_SPOKEN_NAME_CARD],
          [action.card.id]: false
        }
      };

    case UPDATE_PRONUNCIATION_CARD_REQUEST:
      return {
        ...state,
        UPDATE_PRONUNCIATION_CARD: {
          ...state[UPDATE_PRONUNCIATION_CARD],
          [action.cardId]: true
        }
      };
    case UPDATE_PRONUNCIATION_CARD_SUCCESS:
      return {
        ...state,
        UPDATE_PRONUNCIATION_CARD: {
          ...state[UPDATE_PRONUNCIATION_CARD],
          [action.card.id]: false
        }
      };
    case UPDATE_PRONUNCIATION_CARD_FAILURE:
      return {
        ...state,
        UPDATE_PRONUNCIATION_CARD: {
          ...state[UPDATE_PRONUNCIATION_CARD],
          [action.cardId]: false
        }
      };

    default:
      return state;
  }
}
