export enum GetAlertsActionType {
  GET_ALERTS = 'GET_ALERTS',
  GET_ALERTS_REQUEST = `GET_ALERTS_REQUEST`,
  GET_ALERTS_SUCCESS = `GET_ALERTS_SUCCESS`,
  GET_ALERTS_FAILURE = `GET_ALERTS_FAILURE`
}

export enum GetAlertActionType {
  GET_ALERT = 'GET_ALERT',
  GET_ALERT_REQUEST = `GET_ALERT_REQUEST`,
  GET_ALERT_SUCCESS = `GET_ALERT_SUCCESS`,
  GET_ALERT_FAILURE = `GET_ALERT_FAILURE`
}

export enum CreateAlertActionTypes {
  CREATE_ALERT = 'CREATE_ALERT',
  CREATE_ALERT_REQUEST = `CREATE_ALERT_REQUEST`,
  CREATE_ALERT_SUCCESS = `CREATE_ALERT_SUCCESS`,
  CREATE_ALERT_FAILURE = `CREATE_ALERT_FAILURE`
}

export enum UpdateAlertActionTypes {
  UPDATE_ALERT = 'UPDATE_ALERT',
  UPDATE_ALERT_REQUEST = `UPDATE_ALERT_REQUEST`,
  UPDATE_ALERT_SUCCESS = `UPDATE_ALERT_SUCCESS`,
  UPDATE_ALERT_FAILURE = `UPDATE_ALERT_FAILURE`
}

export enum DeleteAlertActionTypes {
  DELETE_ALERT = 'DELETE_ALERT',
  DELETE_ALERT_REQUEST = `DELETE_ALERT_REQUEST`,
  DELETE_ALERT_SUCCESS = `DELETE_ALERT_SUCCESS`,
  DELETE_ALERT_FAILURE = `DELETE_ALERT_FAILURE`
}

export interface CreateAlertRequestAction {
  type: CreateAlertActionTypes.CREATE_ALERT_REQUEST;
  payload: CreateAlertRequestPayload;
}

export interface CreateAlertRequestFailure {
  type: CreateAlertActionTypes.CREATE_ALERT_FAILURE;
  payload: Error;
}

export type CreateAlertRequestPayload = {
  type: string;
  title: string;
  description: string;
};

export interface UpdateAlertRequestAction {
  type: UpdateAlertActionTypes.UPDATE_ALERT_REQUEST;
  payload: UpdateAlertRequestPayload;
}

export interface UpdateAlertRequestFailure {
  type: UpdateAlertActionTypes.UPDATE_ALERT_FAILURE;
  payload: Error;
}

export interface UpdateAlertRequestPayload {
  uuid: string;
  title: string;
  description: string;
}

export interface DeleteAlertRequestAction {
  type: DeleteAlertActionTypes.DELETE_ALERT_REQUEST;
  payload: DeleteAlertRequestPayload;
}

export interface DeleteAlertRequestFailure {
  type: DeleteAlertActionTypes.DELETE_ALERT_FAILURE;
  payload: Error;
}

export interface DeleteAlertRequestPayload {
  uuid: string;
}
