import React, { FC, useState, useEffect } from 'react';
import {
  Form,
  Input,
  Heading,
  Banner,
  Button,
  Modal,
  Spinner,
  Text,
  InfoTooltip
} from '@tidbits/react-tidbits';
import ImportTaskValidItem from './ImportTaskValidItem';
import ImportTaskSelectedTask from './ImportTaskSelectedTask';
import AccordionList from '@ui-components/accordion-list';
import { getImportExportTasks, getImportComplete } from '../../store/worklist/worklistSelector';
import { useSelector, useDispatch } from 'react-redux';
import { importWorklistRequest } from '../../store/worklist/worklistActions';
import { ImportExportItem } from '../../common/types/ImportExportItem';
import {
  Artifact,
  TitleAndTaglineArtifactType,
  QuoteArtifactType,
  TextArtifactType,
  TextListArtifactType,
  LinkArtifactType,
  HeaderArtifactType,
  ImageArtifactType,
  TipBlockArtifactType,
  VideoArtifactType
} from '@ui-components/artifacts';
import { Artifacts } from '../../common/types/ImportExportItem';
import { formatUnderscoreToTitleCase } from '../../utils/generalUtils';

interface Props {
  hide: any;
  file: any;
  workflowType: string;
  label: string;
}

export const ImportTaskListPage: FC<Props> = ({ hide, file, workflowType, label }) => {
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState('imports');
  const [selectedTask, setSelectedTask] = useState('');
  const [selectedImportIds, setSelectedImportIds] = useState(new Set());
  const [imported, setImported] = useState(false);
  const importExportTasks = useSelector(getImportExportTasks);
  const importComplete = useSelector(getImportComplete);

  useEffect(() => {
    if (showSpinner && importComplete) {
      onSuccessfulImport();
    }
  }, [importComplete, showSpinner]);

  const handleImport = (advanceIds: boolean) => {
    setShowSpinner(true);

    dispatch(
      importWorklistRequest({
        type: workflowType,
        file: file[0],
        advance: advanceIds,
        ids: Array.from(selectedImportIds)
      })
    );
  };

  const handleSelectAll = (e: any) => {
    const checkboxes = document.getElementsByClassName('import--list-item-checkbox');

    for (let i = 0; i < checkboxes.length; i++) {
      // @ts-ignore
      checkboxes[i].checked = e.target.checked;
    }

    if (e.target.checked) {
      const taskIds = getValidTaskIds();
      const newSet = new Set();
      taskIds.forEach((id) => newSet.add(id));
      setSelectedImportIds(newSet);
    } else {
      setSelectedImportIds(new Set());
    }
  };

  const handleCheckboxClick = (e: any, id: string) => {
    if (e.target.checked) {
      setSelectedImportIds(new Set(selectedImportIds).add(id));
    } else {
      selectedImportIds.delete(id);
      setSelectedImportIds(new Set(selectedImportIds));
    }
  };

  const handleAccordionClick = (clickedAccordionName: string) => {
    setActiveAccordion(activeAccordion === clickedAccordionName ? '' : clickedAccordionName);
  };

  const onSuccessfulImport = () => {
    setShowSpinner(false);
    setImported(true);
  };

  const getValidTaskIds = () => {
    return getValidTasks().map((task) => task.task.id);
  };

  const getValidTasks = () => {
    return importExportTasks.filter((task) => task.taskValidation.isValid);
  };

  const getInvalidTasks = () => {
    return importExportTasks.filter((task) => !task.taskValidation.isValid);
  };

  const getSelectedTask = () => {
    return importExportTasks.find((task) => task.task.id === selectedTask);
  };

  const getImportItemEls = () => {
    const validItems = getValidTasks();

    let items = validItems.map((item) => {
      const task = item.task;
      const className =
        task.id === selectedTask ? 'import--list-item--selected' : 'import--list-item';
      return (
        <ImportTaskValidItem
          task={task}
          className={className}
          setSelectedTask={setSelectedTask}
          handleCheckboxClick={handleCheckboxClick}
        />
      );
    });
    return items.length ? (
      items
    ) : (
      <div className="import--list-item">
        <div>{'No issues to list here.'}</div>
      </div>
    );
  };

  const getInvalidItemEls = () => {
    const invalidItems = getInvalidTasks();

    let items = invalidItems.map((item) => {
      const task = item.task;
      const className =
        task.id === selectedTask ? 'import--list-item--selected' : 'import--list-item';
      return (
        <div
          id={task.id}
          className={className}
          onClick={() => setSelectedTask(task.id)}
          key={task.id}
        >
          {task.name || 'Undefined'}
        </div>
      );
    });
    return items.length ? (
      items
    ) : (
      <div className="import--list-item">
        <div>{'No issues to list here.'}</div>
      </div>
    );
  };

  const selectedTaskComponent = () => {
    const selectedTask = getSelectedTask();
    const taskValidation = selectedTask?.taskValidation;

    if (taskValidation && !taskValidation.isValid) {
      return selectedTaskWithError(taskValidation);
    }
    const name = findByField(selectedTask, 'NAME') as Artifacts;
    const shortNote = findByField(selectedTask, 'SHORT_NOTE') as Artifacts;
    const longNote = findByField(selectedTask, 'LONG_NOTE') as Artifacts;
    const tagLine = findByField(selectedTask, 'TAGLINE') as Artifacts;
    const caption = findByField(selectedTask, 'CAPTION') as Artifacts;
    const titleAndTagline = findByField(
      selectedTask,
      'TITLE_AND_TAGLINE'
    ) as TitleAndTaglineArtifactType;
    const textlist = findByField(selectedTask, 'TEXT_LIST') as TextListArtifactType;
    const text = findByField(selectedTask, 'TEXT') as TextArtifactType;
    const quote = findByField(selectedTask, 'QUOTE') as QuoteArtifactType;
    const link = findByField(selectedTask, 'LINK') as LinkArtifactType;
    const header = findByField(selectedTask, 'HEADER') as HeaderArtifactType;
    const image = findByField(selectedTask, 'IMAGE') as ImageArtifactType;
    const tipblock = findByField(selectedTask, 'TIP_BLOCK') as TipBlockArtifactType;
    const video = findByField(selectedTask, 'VIDEO') as VideoArtifactType;

    const fields = [
      {
        displayName: name?.displayName,
        value: name?.value,
        workflowType: ['VIDEO', 'MUSIC']
      },
      {
        displayName: shortNote?.displayName,
        value: shortNote?.value,
        workflowType: ['VIDEO', 'MUSIC']
      },
      {
        displayName: longNote?.displayName,
        value: longNote?.value,
        workflowType: ['VIDEO', 'MUSIC']
      },
      {
        displayName: tagLine?.displayName,
        value: tagLine?.value,
        workflowType: ['VIDEO', 'MUSIC']
      },
      {
        displayName: caption?.displayName,
        value: caption?.value,
        workflowType: ['VIDEO']
      },
      {
        displayName: 'Title',
        value: titleAndTagline?.title,
        workflowType: ['APP']
      },
      {
        displayName: 'Card Tagline',
        value: titleAndTagline?.cardTagline,
        workflowType: ['APP']
      },
      {
        displayName: 'Hero Tagline',
        value: titleAndTagline?.heroTagline,
        workflowType: ['APP']
      },
      {
        displayName: 'Header',
        value: header?.header,
        workflowType: ['APP']
      },
      {
        displayName: 'Shelf Text',
        value: text?.text,
        workflowType: ['APP']
      },
      {
        displayName: 'Image Text',
        value: image?.imageBodyText,
        workflowType: ['APP']
      },
      {
        displayName: 'Tip Block Text',
        value: tipblock?.text,
        workflowType: ['APP']
      },
      {
        displayName: 'Video Text',
        value: video?.videoBodyText,
        workflowType: ['APP']
      },
      {
        displayName: 'Text List',
        value: textlist?.textItems,
        workflowType: ['APP']
      },
      {
        displayName: 'Quote Attribution',
        value: quote?.text,
        workflowType: ['APP']
      },
      {
        displayName: 'Link Url Text',
        value: link?.cardTagline,
        workflowType: ['APP']
      }
    ];

    if (selectedTask?.task && 'shelves' in selectedTask?.task) {
      //if COPY WORKFLOW, I have used this method to check due to type guards
      return selectedTask.task.shelves.map(({ noteType, text }) => (
        <ImportTaskSelectedTask
          field={{ displayName: formatUnderscoreToTitleCase(noteType), value: text }}
        />
      ));
    } else {
      // Handle other verticals
      return fields.map((field) => {
        if (field.workflowType) {
          if (field.workflowType.includes(workflowType) && field.displayName) {
            return <ImportTaskSelectedTask field={field} />;
          } else {
            return null;
          }
        } else {
          return <ImportTaskSelectedTask field={field} />;
        }
      });
    }
  };

  const findByField = (selectedTask: ImportExportItem | undefined, field: string) => {
    if (!selectedTask?.task) return null;

    const getShelves = (task: ImportExportItem['task'] | undefined) => {
      if (!task) return [];

      if ('artifacts' in task) {
        return task.artifacts;
      }
      if ('shelves' in task) {
        return task.shelves;
      }

      return [];
    };

    if (workflowType === 'APP') {
      const selectedTaskArtifacts = getShelves(selectedTask.task) as Artifact[];
      return selectedTaskArtifacts.find((artifact: Artifact) => artifact.key === field);
    } else {
      const selectedTaskArtifacts = getShelves(selectedTask.task) as Artifacts[];
      return selectedTaskArtifacts.find((artifact: Artifacts) => artifact.type === field);
    }
  };

  const selectedTaskWithError = (taskValidation: any) => {
    return (
      <Banner.Inline
        className="import-export--error-banner"
        variant="error"
        spaceBelow="disassociate"
      >
        <Text>{taskValidation?.errorMessage}</Text>
      </Banner.Inline>
    );
  };

  return (
    <div>
      <Modal.Content>
        <Modal.Header>{label}</Modal.Header>
        <Modal.Body className="import--task-list">
          <Text>
            {`${imported ? 'Importing successfully completed for ' : 'Importing'} ${
              selectedImportIds.size
            } of ${getValidTasks().length} Tasks from ${file[0].name}…`}
          </Text>
          {showSpinner ? (
            <div className="import--text--in-progress">
              <Spinner opacity={1} size={25} visible={showSpinner} />
              <div className="import--text--in-progress">
                <Text>{`Importing ${file[0].name}...`}</Text>
              </div>
            </div>
          ) : (
            <div className="import--task-list-container">
              <div className="import--task-list--left">
                <AccordionList
                  activeAccordionName={activeAccordion}
                  handleAccordionClick={handleAccordionClick}
                >
                  <Form.Label>
                    <Input.Checkbox
                      className="import--select-all"
                      onChange={(e: any) => handleSelectAll(e)}
                    />
                  </Form.Label>
                  <AccordionList.Accordion
                    title={`Imports (${getValidTasks().length})`}
                    collapsedTitle={`Imports (${getValidTasks().length})`}
                    name="imports"
                    isImportTaskList={true}
                  >
                    {getImportItemEls()}
                  </AccordionList.Accordion>

                  <AccordionList.Accordion
                    title={`Issues (${getInvalidTasks().length})`}
                    collapsedTitle={`Issues (${getInvalidTasks().length})`}
                    name="issues"
                    isImportTaskList={true}
                  >
                    {getInvalidItemEls()}
                  </AccordionList.Accordion>
                </AccordionList>
              </div>

              <div className="import--task-list--right">
                {selectedTask.length ? (
                  selectedTaskComponent()
                ) : (
                  <div className="import--task-no-selected-task">
                    <Heading.H5 className="import--task-no-selected-task-header">
                      No Task Selected
                    </Heading.H5>
                    <Text>Select a Task</Text>
                  </div>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal.Content>
      <Modal.Footer display="flex" flexDirection="row" justifyContent="flex-end">
        {imported ? (
          <Button large standard type="button" disabled={false} onClick={hide} mr={10}>
            Close
          </Button>
        ) : (
          <>
            <Button large standard type="button" onClick={hide} mr={10}>
              {imported ? 'Close' : 'Cancel'}
            </Button>
            <Button
              large
              primary
              type="button"
              disabled={!selectedImportIds.size || showSpinner}
              onClick={() => {
                handleImport(false);
              }}
              mr={10}
            >
              Import
            </Button>
            <Button
              large
              primary
              type="button"
              disabled={!selectedImportIds.size || showSpinner}
              onClick={() => {
                handleImport(true);
              }}
            >
              Import & Advance
            </Button>
            <div className="import--info-tooltip">
              <InfoTooltip>
                <InfoTooltip.Content>
                  <Text>
                    By clicking "Import and Advance", the task will be submitted and advanced to the
                    next stage of the workflow.
                  </Text>
                </InfoTooltip.Content>
              </InfoTooltip>
            </div>
          </>
        )}
      </Modal.Footer>
    </div>
  );
};

export default ImportTaskListPage;
