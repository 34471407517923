import React, { useState, FC, ReactElement, RefObject, ReactNode } from 'react';
import { TopHeader, Popover, Box } from '@tidbits/react-tidbits';

import './navigationBarMenuRight.scss';

import { UserMenu } from '../UserMenu/UserMenu';
import NavigationBarProfile from '../NavigationBarProfile/NavigationBarProfile';
import { useDispatch } from 'react-redux';
import {
  showUserPreferences,
  hideUserPreferences
} from '../../../../store/userPreferences/userPreferencesActions';
import { UserPreferencesHelpModal } from '../../../modals/UserPreferencesModal/modals/UserPreferencesHelpModal';
import { Pages } from '../../../../common/types/UserPreferences';
import { UserPreferencesSettingsModal } from '../../../modals/UserPreferencesModal/modals/UserPreferencesSettingsModal';

interface Props {
  userDisplayName: string;
  userJobTypes: string;
  isSearchVisible?: boolean;
  notificationNode: ReactNode;
}

interface PopoverDropdownProps {
  isHidden: boolean;
  setHidden: (isHidden: boolean) => void;
  containerRef: RefObject<ReactElement>;
}

const NavigationBarMenuRight: FC<Props> = ({ userDisplayName, userJobTypes, notificationNode }) => {
  const dispatch = useDispatch();
  const [isUserPrefSettingsModalOpen, setIsUserPrefSettingsModalOpen] = useState({
    openTab: '',
    open: false
  });
  const [isUserPrefHelpOpen, setIsUserPrefHelpOpen] = useState({
    openTab: '',
    open: false
  });

  const openUserPrefModal = (openTab: string) => {
    dispatch(showUserPreferences());
    if (openTab === Pages.SETTINGS || openTab === Pages.BETA_FEATURES) {
      if (isUserPrefHelpOpen.open) {
        setIsUserPrefHelpOpen({
          openTab: '',
          open: false
        });
      }
      setIsUserPrefSettingsModalOpen({
        openTab,
        open: true
      });
    } else if (openTab === Pages.HOT_KEYS || openTab === Pages.QUERIES_AND_ESCALATIONS) {
      if (isUserPrefSettingsModalOpen.open) {
        setIsUserPrefSettingsModalOpen({
          openTab: '',
          open: false
        });
      }
      setIsUserPrefHelpOpen({
        openTab,
        open: true
      });
    }
  };

  const closeUserPrefModal = () => {
    dispatch(hideUserPreferences());
    if (isUserPrefHelpOpen.open) {
      setIsUserPrefHelpOpen({
        openTab: '',
        open: false
      });
    } else if (isUserPrefSettingsModalOpen.open) {
      setIsUserPrefSettingsModalOpen({
        openTab: '',
        open: false
      });
    }
  };

  return (
    <TopHeader.User className="mpe-navigation__right">
      {!isUserPrefHelpOpen.open && (
        <UserPreferencesSettingsModal
          openModalStatus={isUserPrefSettingsModalOpen}
          hide={closeUserPrefModal}
        />
      )}
      {!isUserPrefSettingsModalOpen.open && (
        <UserPreferencesHelpModal openModalStatus={isUserPrefHelpOpen} hide={closeUserPrefModal} />
      )}
      {notificationNode}
      <Popover.ClickAwayOpenState>
        {({ isHidden, setHidden, containerRef }: PopoverDropdownProps) => (
          <Box>
            <Popover.BaseDropdown
              ref={containerRef}
              hidden={isHidden}
              zIndex={20}
              minWidth={170} // has to be here because it relates to wrapper of "mpe-navigation__user" which comes from Tidbits
              className="mpe-navigation__user"
              target={({ ref }: { ref: RefObject<ReactElement> }) => (
                <Box
                  ref={ref}
                  className="mpe-navigation__user-inner"
                  onClick={() => {
                    setHidden(!isHidden);
                  }}
                >
                  <NavigationBarProfile
                    userDisplayName={userDisplayName}
                    userJobTypes={userJobTypes}
                  />
                </Box>
              )}
            >
              <UserMenu openUserPreferencesModal={openUserPrefModal} setHidden={setHidden} />
            </Popover.BaseDropdown>
          </Box>
        )}
      </Popover.ClickAwayOpenState>
    </TopHeader.User>
  );
};

export default NavigationBarMenuRight;
