import {
  SET_ACTIVE_ISSUE,
  ACCEPT_ISSUE_SUCCESS,
  REJECT_ISSUE_SUCCESS,
  INVALIDATE_CONFIDENCE_SCORE,
  REMOVE_ACTIVE_ISSUE
} from './taskActionTypes';

export default function scoresAndIssuesReducer(state = null, action = {}) {
  switch (action.type) {
    case SET_ACTIVE_ISSUE: {
      return {
        ...state,
        activeIssue: action.activeIssue
      };
    }
    case REMOVE_ACTIVE_ISSUE: {
      if (state) return { ...state, activeIssue: undefined };
      return state;
    }
    case INVALIDATE_CONFIDENCE_SCORE: {
      return undefined;
    }
    case ACCEPT_ISSUE_SUCCESS: {
      if (action.data.status === 'SUCCESS') {
        return {
          ...state,
          score: action.data.score,
          issues: action.data.issues,
          activeIssue: { id: '', startIndex: -1 }
        };
      }
      return state;
    }
    case REJECT_ISSUE_SUCCESS: {
      if (action.data.status === 'SUCCESS') {
        return {
          ...state,
          score: action.data.score,
          issues: action.data.issues,
          activeIssue: { id: '', startIndex: -2 }
        };
      }
      return state;
    }
    default:
      return state;
  }
}
