import React, { FC } from 'react';
import { CheckboxProps, Checkbox, SemanticShorthandItem, HtmlLabelProps } from 'semantic-ui-react';
import classNames from 'classnames';

import './customCheckbox.scss';

interface Props {
  checked?: boolean;
  indeterminate?: boolean;
  className?: string;
  id?: number | string;
  label?: SemanticShorthandItem<HtmlLabelProps>;
  name?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  value?: number | string;
}

const CustomCheckbox: FC<Props> = ({
  checked,
  indeterminate,
  className = '',
  id,
  label,
  name,
  onChange,
  value
}) => {
  const classes = classNames(className, 'mpe-custom-checkbox_checkbox');
  return (
    <Checkbox
      checked={checked}
      indeterminate={indeterminate}
      className={classes}
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      label={label}
    />
  );
};

export default CustomCheckbox;
