import React, { ReactNode, FC, useCallback } from 'react';

import NavigationBar from '../navigation/NavigationBar';
import ToastMessageContainer from './../../containers/ToastMessage/ToastMessageContainer';
import ModalContainer from '../../common/components/ModalContainer';
import TableDashboardLayout from '../TableDashboardLayout';

import { GlobalAlerts } from '@ui-components/alert-banner';
import NewTableFilter from '../NewTableFilter';
import { ModalProvider } from '@providers/modal-provider';

interface Props {
  isAuthenticated: boolean;
  locationPath: string;
  children?: ReactNode;
  alerts: [GlobalAlerts] | [];
}

const Layout: FC<Props> = ({ isAuthenticated, locationPath, children = null, alerts }) => {
  const isFilterVisible = useCallback((): boolean => {
    if (
      locationPath === '/tasks' ||
      locationPath === '/tasks/' ||
      locationPath === '/worklist' ||
      locationPath === '/worklist/'
      // TODO Improve in the future by handling slashes at the end of an endpoint in RouteCreator.tsx e.g. <Redirect strict from={url + '/'} to={url} />
    ) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return true;
    }
    return false;
  }, [locationPath]);

  const filterRouteForLocation = (): ReactNode => {
    if (
      locationPath === '/tasks' ||
      locationPath === '/tasks/' ||
      locationPath === '/worklist' ||
      locationPath === '/worklist/'
    ) {
      return (
        <ModalProvider>
          <NewTableFilter />
        </ModalProvider>
      );
    }
    return null;
  };

  const renderBanner = (): [GlobalAlerts] | [] => {
    return alerts;
  };

  return (
    <>
      <TableDashboardLayout
        filter={filterRouteForLocation()}
        isFilterVisible={isFilterVisible()}
        isFilterBodyVisible={true}
        header={isAuthenticated && <NavigationBar />}
        alerts={renderBanner()}
        dashboard={<div className="mpe-container-full-height">{children}</div>}
      >
        <ToastMessageContainer />
        <ModalContainer />
      </TableDashboardLayout>
    </>
  );
};

export default Layout;
