import { put, takeLatest, all } from 'redux-saga/effects';

// import api from "../../api/api";
import {
  // getNotificationsSuccess,
  getNotificationsFailure
} from './notificationsAction';
import { GET_NOTIFICATIONS_REQUEST } from './notificationsActionTypes';

export function* getNotificationsRequest(action: any): any {
  try {
    // THIS IS COMMENTED OUT SINCE WE ARE STILL NOT USING NOTIFICATIONS AT ALL AND IS GIVING US 404
    // const notificationsData = yield call(api.notifications.getNotifications);
    // yield put(getNotificationsSuccess(notificationsData));
  } catch (error) {
    yield put(getNotificationsFailure(error.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(GET_NOTIFICATIONS_REQUEST, getNotificationsRequest)]);
}
