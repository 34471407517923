import {
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAILURE,
  GET_TASKS_INFINITE_REQUEST,
  GET_TASKS_INFINITE_SUCCESS,
  GET_TASKS_INFINITE_FAILURE,
  GET_TASKS_RECORD_SUCCESS,
  GET_TASKS_RECORD_REQUEST,
  GET_TASKS_RECORD_FAILURE,
  REASSIGN_TASK_REQUEST,
  REASSIGN_TASK_SUCCESS,
  REASSIGN_TASK_FAILURE,
  EXPORT_TASKS_REQUEST,
  EXPORT_TASKS_SUCCESS,
  EXPORT_TASKS_FAILURE,
  CREATE_NEW_TASKS_REQUEST,
  CREATE_NEW_TASKS_SUCCESS,
  CREATE_NEW_TASKS_FAILURE,
  RESET_CREATE_TASK_ERROR,
  SET_SELECTED_TASKS,
  SET_FILTER_INDEX
} from './insertTaskActionTypes';

export const setSelectedTasks = (selectedIndexes) => ({
  type: SET_SELECTED_TASKS,
  selectedIndexes
});

export const getTasksRequest = () => ({
  type: GET_TASKS_REQUEST
});

export const getTasksSuccess = (data) => ({
  type: GET_TASKS_SUCCESS,
  data
});

export const getTasksFailure = (error) => ({
  type: GET_TASKS_FAILURE,
  error
});

export const getTasksInfiniteRequest = () => ({
  type: GET_TASKS_INFINITE_REQUEST
});

export const getTasksInfiniteSuccess = (data) => ({
  type: GET_TASKS_INFINITE_SUCCESS,
  data
});

export const getTasksInfiniteFailure = (error) => ({
  type: GET_TASKS_INFINITE_FAILURE,
  error
});

export const getTasksRecordCountRequest = (data) => ({
  type: GET_TASKS_RECORD_REQUEST
});

export const getTasksRecordCountSuccess = (data) => ({
  type: GET_TASKS_RECORD_SUCCESS,
  data
});

export const getTasksRecordCountFailure = (error) => ({
  type: GET_TASKS_RECORD_FAILURE,
  error
});

export const reassignTaskRequest = (tasks, userId) => ({
  type: REASSIGN_TASK_REQUEST,
  tasks,
  userId
});

export const reassignTaskSuccess = (data) => ({
  type: REASSIGN_TASK_SUCCESS,
  data
});

export const reassignTaskFailure = (error) => ({
  type: REASSIGN_TASK_FAILURE,
  error
});

export const exportTasksRequest = (data) => ({
  type: EXPORT_TASKS_REQUEST,
  data
});

export const exportTasksSuccess = () => ({
  type: EXPORT_TASKS_SUCCESS
});

export const exportTasksFailure = (error) => ({
  type: EXPORT_TASKS_FAILURE,
  error
});

export const createNewTasksRequest = (data) => ({
  type: CREATE_NEW_TASKS_REQUEST,
  data
});

export const createNewTasksSuccess = () => ({
  type: CREATE_NEW_TASKS_SUCCESS
});

export const createNewTasksFailure = (error) => ({
  type: CREATE_NEW_TASKS_FAILURE,
  error
});

export const resetCreateTaskError = () => ({
  type: RESET_CREATE_TASK_ERROR
});

// Not sure do we still use this
export const setFilterIndex = (index) => ({
  type: SET_FILTER_INDEX,
  index
});
