import { GET_COLLABORATION_SUCCESS, ADD_TASK_COMMENT_SUCCESS } from './taskActionTypes';

export default function collaborationSummaryReducer(state = null, action = {}) {
  switch (action.type) {
    case GET_COLLABORATION_SUCCESS: {
      return { ...state, unreadCount: 0 };
    }
    case ADD_TASK_COMMENT_SUCCESS: {
      const { totalCount } = state;
      if (totalCount) return { ...state, totalCount: state.totalCount + 1 };
      return { ...state, totalCount: 1 };
    }
    default:
      return state;
  }
}
