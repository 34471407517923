import { AvailableTermsV2 } from "../common/types";

const mapResultsArrToObj = (results:string[]) => {
  // There is no count returned from v2 api so I have hardcoded 0 as a value. 
  // Luckily I believe that at the moment we are not showing count in the UI
  let data: {[key: string]: number} = {}
  results.forEach(res => data[res] = 0);
  return data
}

export const mapV2AvailableTermsToV1 = (availableTermsV2: AvailableTermsV2) => {
  return availableTermsV2.map(({field, results}) => {
    return {
      field,
      results: mapResultsArrToObj(results)
    }
  })
}
