import React, { FC } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import IconTemplate, { IconType } from '@ui-components/icon-template';

import './mediaTypeIcon.scss';

import { KeyDisplayName } from '../../../../common/types';

interface Props {
  workflowType?: string | KeyDisplayName;
}

interface MediaIcons {
  [name: string]: IconType;
}

// TODO rename it to be ContentTypeIcon
const MediaTypeIcon: FC<Props> = ({ workflowType }) => {
  // because of issues with catching the video and album path after new implementations, this is the best possible way found
  const history = useHistory();
  const match = matchPath<{ type: string }>(history.location.pathname, {
    path: ['/:type/tasks/:id', '/:type/workflows/:id/latestTask'],
    exact: true,
    strict: false
  });

  const type = match?.params.type;

  const icons: MediaIcons = {
    shakespeare: 'shakespeareLogo'
  };

  const taskWorkflowType =
    typeof workflowType === 'string' || workflowType instanceof String ? workflowType : type;

  if (!taskWorkflowType && !type) {
    return <></>;
  }

  const getIconByWorkflowType = () => {
    return icons.shakespeare;
  };

  return <IconTemplate className="mpe-nav-bar-logo" name={getIconByWorkflowType()} />;
};

export default MediaTypeIcon;
