import { AvailableTermsActionTypes } from './availableTermsActionTypes';
import { ActionCreator } from 'redux';
import { AvailableTerms } from '../../common/types';

interface AvailableTermsRequestAction {
  type: AvailableTermsActionTypes.GET_AVAILABLE_TERMS_REQUEST;
}

export const availableTermsRequest: ActionCreator<AvailableTermsRequestAction> = () => ({
  type: AvailableTermsActionTypes.GET_AVAILABLE_TERMS_REQUEST
});

interface AvailableTermsSuccessAction {
  type: AvailableTermsActionTypes.GET_AVAILABLE_TERMS_SUCCESS;
  data: AvailableTerms;
}

export const availableTermsSuccess: ActionCreator<AvailableTermsSuccessAction> = (data) => ({
  type: AvailableTermsActionTypes.GET_AVAILABLE_TERMS_SUCCESS,
  data
});

interface AvailableTermsFaillureAction {
  type: AvailableTermsActionTypes.GET_AVAILABLE_TERMS_FAILURE;
}

export const availableTermsFailure: ActionCreator<AvailableTermsFaillureAction> = (data) => ({
  type: AvailableTermsActionTypes.GET_AVAILABLE_TERMS_FAILURE
});

export type AvailableTermsActions =
  | AvailableTermsRequestAction
  | AvailableTermsSuccessAction
  | AvailableTermsFaillureAction;
