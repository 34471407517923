import { GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAILURE } from './notificationsActionTypes';

export default function notifications(state = {}, action = { type: '', data: {} }) {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return { ...state, ...action.data };
    case GET_NOTIFICATIONS_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
