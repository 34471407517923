import React, { FC } from 'react';
import classNames from 'classnames';

import './infoModal.scss';

import InfoModalContent, { InfoModalContentProps } from '../InfoModalContent/InfoModalContent';
import InfoModalIcon, { InfoModalIconProps } from '../InfoModalIcon/InfoModalIcon';
import ModalButtonsBottom, {
  ModalButtonsBottomProps
} from '../ModalButtonsBottom/ModalButtonsBottom';

type Props = InfoModalIconProps & ModalButtonsBottomProps & InfoModalContentProps;

const InfoModal: FC<Props> = ({
  iconName,
  title,
  closeModal,
  onSuccess,
  successLabel,
  closeLabel,
  successColor,
  children,
  iconColor,
  isModalWithTable,
  successButtonDisabled
}) => {
  const infoModalWithTable = classNames('mpe-info-modal', {
    'mpe-info-modal-modal-with-table': isModalWithTable
  });
  return (
    <div className={infoModalWithTable} id="InfoModal">
      <div className="mpe-info-modal_body" id="InfoModalBody">
        {iconName && <InfoModalIcon iconName={iconName} iconColor={iconColor} />}
        <InfoModalContent title={title} isModalWithTable={isModalWithTable}>
          {children}
        </InfoModalContent>
      </div>
      <ModalButtonsBottom
        onSuccess={onSuccess}
        closeModal={closeModal}
        successLabel={successLabel}
        closeLabel={closeLabel}
        successColor={successColor}
        successButtonDisabled={successButtonDisabled}
      />
    </div>
  );
};

export default InfoModal;
