import { SHOW_TOAST, HIDE_TOAST } from './toastActionTypes';

export const showToast = (open, toastProps) => ({
  type: SHOW_TOAST,
  open,
  toastProps
});

export const hideToast = (toast) => ({
  type: HIDE_TOAST,
  toast
});
