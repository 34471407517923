import { FC } from 'react';
import { WorkflowType } from '@helper-hooks/user-preferences-redux-action';

export enum FormType {
  RADIO = 'radio',
  TOGGLE = 'toggle',
  DROPDOWN_SELECTION = 'dropdown_selection'
}

export enum Pages {
  BETA_FEATURES = 'betaFeatures',
  HOT_KEYS = 'hotKeys',
  QUERIES_AND_ESCALATIONS = 'queriesAndEscalations',
  SETTINGS = 'settings',
  GLOBAL_ALERTS = 'globalAlerts'
}

export interface WorkflowSettings {
  workflowType: WorkflowType;
  namespacePermissions: string[];
  features: FeatureSetting[];
}

export interface FeatureSetting {
  type: FormType;
  permissions: string[];
  propertyKey: string;
  label: string;
  prodEnabled: boolean;
  envEnabled: string[];
  defaultValue: string | boolean | number;
  options?: SettingOptions[];
  localChange: boolean;
  localChangeString: string;
}

export interface SettingOptions {
  value: string | number;
  label: string;
}

export interface NavMenuOptions {
  title: string;
  pages: NavMenuPage;
}

export interface NavMenuPage {
  [key: string]: NavMenuPageDetails;
}

export interface NavMenuPageDetails {
  title: string;
  Component: FC;
  permissions?: string | string[];
}
