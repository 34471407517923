import axios from 'axios';

import { setError } from '@helper-hooks/error-pages-state-redux-action';
import { globalAxiosInstanceErrorInterceptor } from '@helper-hooks/general-utils';

import { TOAST_TYPES } from '../common';
import store from '../store';
import { showToast } from '../store/toast/toastActions';
import TRANSLATE_KEYS from '../public/locales/translateKeys';
import { clearUserData } from '../store/user/userActions';
import { reportError } from './errorReportUtil';
import { envBaseUrl } from './envResolver';
import { generateCorrelationId } from './correlationId';

const baseUrl = envBaseUrl();

const axiosInstance = axios.create({
  baseURL: baseUrl
});

localStorage.setItem('baseUrl', baseUrl);

axiosInstance.interceptors.request.use((config) => {
  const correlationId = generateCorrelationId(26);
  config.headers['x-apple-jingle-correlation-key'] = correlationId;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { message } = error;
    const { status, data } = error.response;
    const correlationKey = error.response.config.headers['x-apple-jingle-correlation-key'];

    globalAxiosInstanceErrorInterceptor(
      status,
      () => store.dispatch(setError({
        errorCode: status,
        correlationKey,
        errorMessage: message,
        componentStack: data
      })),
      () => store.dispatch(clearUserData()),
      () =>
        store.dispatch(
          showToast(true, {
            toastType: TOAST_TYPES.warning,
            toastMessage: {
              translateKey: TRANSLATE_KEYS.toast_messages.error.error_something_went_wrong
            }
          })
        ),
      () =>
        reportError(error, {
          endpoint: error.response.config.url,
          method: error.response.config.method,
          requestPayload: error.response.config.data,
          responsePayload: error.response.data,
          tags: { source: 'api', errorInfo: error.response.data }
        })
    );

    return Promise.reject(error);
  }
);

export default axiosInstance;
