import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
history.listen((location, action) => {
  if (process.env.NODE_ENV === 'development') {
    /*eslint-disable */
    console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
    console.log(`The last navigation action was ${action}`);
    /* eslint-enable */
  }
});

export default history;
