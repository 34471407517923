import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const ServerErrorMessage = ({ header, message }) => (
  <Message negative>
    <Message.Header>{header}</Message.Header>
    <p>{message}</p>
  </Message>
);

ServerErrorMessage.defaultProps = {
  header: ''
};

ServerErrorMessage.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default ServerErrorMessage;
