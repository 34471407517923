import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_REQUEST
} from './notificationsActionTypes';

export const getNotificationsRequest = () => ({
  type: GET_NOTIFICATIONS_REQUEST
});

export const getNotificationsSuccess = (data: any) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  data
});

export const getNotificationsFailure = (error: Error) => ({
  type: GET_NOTIFICATIONS_FAILURE,
  error
});
