import React, { FC, useCallback, useState } from 'react';
import { Box, Text, Spinner } from '@tidbits/react-tidbits';
import { useDropzone } from 'react-dropzone';
import { PresetType } from '@ui-components/table-filters';
import styled from 'styled-components';
import { FilterConfigType } from '@providers/types-provider';
import ImportPresetAccordion from '../../ImportPresetAccordion/ImportPresetAccordion';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

interface Props {
  selectedFilterIds: string[];
  setSelectedFilterIds: (selectedFiltersIds: string[]) => void;
  importedPresets: PresetType[];
  setImportedPresets: (importedPresets: PresetType[]) => void;
  filterConfig: FilterConfigType[];
}

const ImportFilters: FC<Props> = ({
  selectedFilterIds,
  setSelectedFilterIds,
  importedPresets,
  setImportedPresets,
  filterConfig
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onDrop = useCallback(
    async ([acceptedFiles]) => {
      var reader = new FileReader();
      reader.onloadstart = () => {
        setLoading(true);
      };
      reader.onloadend = () => {
        setLoading(false);
      };
      reader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          if (e?.target?.result) {
            const json = JSON.parse(e.target.result.toString());
            setImportedPresets(json);
          }
        } catch (error) {
          console.log(error);
        }
      };
      reader.readAsText(acceptedFiles);
    },
    [setImportedPresets]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'application/json': ['.json']
    },
    onDrop
  });

  const renderPresetList = () => {
    return (
      <ImportPresetAccordion
        modalType={'import'}
        filterConfig={filterConfig}
        presetList={importedPresets}
        selectedFilterIds={selectedFilterIds}
        setSelectedFilterIds={setSelectedFilterIds}
      />
    );
  };

  return (
    <Box>
      {importedPresets.length ? (
        renderPresetList()
      ) : (
        <DropzoneContainer {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {loading && <Spinner visible />}
          <Text>Drag and drop json file, or click to select the file</Text>
        </DropzoneContainer>
      )}
    </Box>
  );
};

export default ImportFilters;
