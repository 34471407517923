import React, { FC } from 'react';
import classNames from 'classnames';
import Moment from 'react-moment';
import { Icon } from 'semantic-ui-react';
import IconTemplate from '@ui-components/icon-template';

import './notificationsDefaultMessage.scss';

import { Notification } from '../../../../common/types';
import i18n from '../../../../i18n';
import TRANSLATE_KEYS from '../../../../public/locales/translateKeys';

interface Props {
  notification: Notification;
  onNotificationClick(): void;
  onUnreadClick(): void;
}

const NotificationsDefaultMessage: FC<Props> = ({
  notification,
  onNotificationClick,
  onUnreadClick
}) => {
  const isRead = () => notification.eventStatus.key === 'READ';

  const messageStatus = classNames({
    'mpe-navigation_notifications-default-message_status': !isRead(),
    'mpe-navigation_notifications-default-message_status_read': isRead()
  });

  const messageContentStatus = classNames({
    'mpe-navigation_notifications-default-message_content': !isRead(),
    'mpe-navigation_notifications-default-message_content_read': isRead()
  });

  return (
    <div className="mpe-navigation_notifications-default-message" onClick={onNotificationClick}>
      <div className="mpe-navigation_notifications-default-message_info">
        <Moment
          format="D MMM YYYY"
          date={notification.timeStamp}
          className="mpe-navigation_notifications-default-message_date"
        />
        {isRead() ? (
          <span className={messageStatus}>
            <IconTemplate
              width={13}
              height={13}
              name="done"
              color="black"
              className="mpe-navigation_notifications-default-message_status-done"
            />
            {i18n.t(TRANSLATE_KEYS.notifications_panel.read)}
          </span>
        ) : (
          <span className={messageStatus} onClick={onUnreadClick}>
            <Icon
              name="circle"
              size={'tiny'}
              className="mpe-navigation_notifications-default-message_status-dot"
            />
            {i18n.t(TRANSLATE_KEYS.notifications_panel.unread)}
          </span>
        )}
      </div>
      <div className={messageContentStatus}>{notification.message}</div>
    </div>
  );
};

export default NotificationsDefaultMessage;
