import {
  UPDATE_SHELF_TEXT_TO_UPDATE_SUCCESS,
  SET_NEW_HIGHLIGHT,
  REMOVE_HIGHLIGHT,
  UPDATE_PLAIN_TEXT_LENGTH
} from './taskActionTypes';

export default function localizationReducer(state = null, action = {}) {
  switch (action.type) {
    case UPDATE_SHELF_TEXT_TO_UPDATE_SUCCESS: {
      return {
        ...state,
        shelfText: action.shelfLocalization.shelfTextToUpdate,
        shelfPlainTextLength: action.shelfLocalization.shelfPlainTextLength
      };
    }
    case SET_NEW_HIGHLIGHT: {
      const newHighlights = [...state.shelfTextHighlights];
      newHighlights.push(action.highlight);
      return {
        ...state,
        shelfTextHighlights: newHighlights,
        snapshotHighlightId: action.highlight.id
      };
    }
    case REMOVE_HIGHLIGHT: {
      const newHighlights = [...state.shelfTextHighlights];
      newHighlights.pop(action.highlight);
      return { ...state, shelfTextHighlights: newHighlights };
    }
    case UPDATE_PLAIN_TEXT_LENGTH: {
      return { ...state, shelfPlainTextLength: action.length };
    }
    default:
      return state;
  }
}
