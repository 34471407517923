import React, { FC, ReactNode } from 'react';
import { MenuList } from '@tidbits/react-tidbits';

import '../../../style/logoutButton.scss';

interface Props {
  logoutText: string;
  logout(): void;
  betaFeatures?: ReactNode;
}

const LogoutButton: FC<Props> = ({ logoutText, logout, betaFeatures }) => (
  <>
    <MenuList.Item id="logoutButton" className="mpe-user-dropdown-menu__align_right">
      <div className="mpe-user-dropdown-menu__menu_item" onClick={logout}>
        {logoutText}
      </div>
    </MenuList.Item>
    {betaFeatures}
  </>
);

export default LogoutButton;
