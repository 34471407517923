import { MatchActionTypes } from './matchActionTypes';
import { Action, ActionCreator } from 'redux';
import { MatchObj } from './matchReducer';

export interface UpdateMatchAction extends Action {
  type: MatchActionTypes.UPDATE_MATCH;
  matchObj: MatchObj;
}

export const updateMatch: ActionCreator<UpdateMatchAction> = (matchObj: MatchObj) => ({
  type: MatchActionTypes.UPDATE_MATCH,
  matchObj
});
