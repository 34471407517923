import React from 'react';
import styled from 'styled-components';
import { Panel, Text, Box, Table } from '@tidbits/react-tidbits';

const hotkeys: HotKeyDescription[] = [
  {
    page: 'Worklist',
    hotkey: ['alt/option', 'w'],
    description: 'Navigate to any task(s) by enter the workflow ID.'
  },
  {
    page: 'Worklist',
    hotkey: ['alt/option', 'u'],
    description: 'Mark task(s) as unread.'
  },
  {
    page: 'Worklist',
    hotkey: ['alt/option', 'r'],
    description: 'Mark task(s) as unread.'
  },
  {
    page: 'Task Details',
    hotkey: '*',
    description: 'To activate the unicode palette in text editors.'
  }
];

const TitleContainer = styled(Box)`
  padding-bottom: 15px;
`;

const ContentContainer = styled(Box)`
  padding-top: 10px;
`;

const KeyboardKey = styled.kbd`
  background-color: rgb(250, 250, 250);
  color: rgb(51, 51, 51);
  line-height: 1.3;
  font-weight: 400;
  letter-spacing: -0.027em;
  font-family: 'SF Mono', Menlo, monospace, 'SF Pro Icons';
  overflow: auto;
  speak: literal-punctuation;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 12px;
`;

interface HotKeyDescription {
  page: string;
  hotkey: string | string[];
  description: string;
}

const MultipleKeys = ({ keyboardKeys }: { keyboardKeys: string[] }) => {
  return (
    <div>
      {keyboardKeys
        .map<React.ReactNode>((key: string, index: number) => {
          return <KeyboardKey key={index}>{key}</KeyboardKey>;
        })
        .reduce((prev, curr) => [prev, '+ ', curr])}
    </div>
  );
};

export const HotKeys = () => {
  return (
    <Panel height={'100%'}>
      <TitleContainer>
        <Text sb="spacer20" textStyle="h3Emph">
          Hot Keys Guide
        </Text>
      </TitleContainer>
      <ContentContainer>
        <Table>
          <Table.THead>
            <Table.TH>Page</Table.TH>
            <Table.TH>Action</Table.TH>
            <Table.TH>Hot Key</Table.TH>
          </Table.THead>
          <Table.TBody>
            {hotkeys.map(({ page, hotkey, description }, index) => {
              return (
                <Table.TR key={index}>
                  <Table.TD>{page}</Table.TD>
                  <Table.TD>{description}</Table.TD>
                  <Table.TD>
                    {Array.isArray(hotkey) ? (
                      <MultipleKeys keyboardKeys={hotkey} />
                    ) : (
                      <KeyboardKey>{hotkey}</KeyboardKey>
                    )}
                  </Table.TD>
                </Table.TR>
              );
            })}
          </Table.TBody>
        </Table>
      </ContentContainer>
    </Panel>
  );
};
