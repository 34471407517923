import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const TableActionHeaders = ({ actionColumnsLength }) =>
  !!actionColumnsLength && (
    <Table.HeaderCell className="no-arrows" collapsing colSpan={actionColumnsLength} />
  );

TableActionHeaders.propTypes = {
  actionColumnsLength: PropTypes.number.isRequired
};

export default TableActionHeaders;
