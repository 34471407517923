import { createSelector } from 'reselect';
import { ReduxState } from '../../common/types';

// ------------------> TASKS <--------------------
export const getQueryParamsTasks = (state: ReduxState) => state.queryParams.tasks;

export const getNextCursorTasks = createSelector(
  getQueryParamsTasks,
  (queryParams) => queryParams.nextCursor
);

export const getFilterTasks = createSelector(
  getQueryParamsTasks,
  (queryParams) => queryParams.filter
);

export const getSelectedFilters = createSelector(
  getQueryParamsTasks,
  (queryParams) => queryParams.filter
);

export const getSortTasks = createSelector(getQueryParamsTasks, (queryParams) => queryParams.sort);

export const getLimitTasks = createSelector(
  getQueryParamsTasks,
  (queryParams) => queryParams.limit
);

// ------------------> USERS <--------------------
export const getQueryParamsUsers = (state: ReduxState) => state.queryParams.users;

export const getNextCursorUsers = createSelector(
  getQueryParamsUsers,
  (queryParams) => queryParams.nextCursor
);

export const getFilterUsers = createSelector(
  getQueryParamsUsers,
  (queryParams) => queryParams.filter
);

export const getSortUsers = createSelector(getQueryParamsUsers, (queryParams) => queryParams.sort);

export const getLimitUsers = createSelector(
  getQueryParamsUsers,
  (queryParams) => queryParams.limit
);

// ------------------> VENDORS <--------------------
export const getQueryParamsVendors = (state: ReduxState) => state.queryParams.vendors;

export const getNextCursorVendors = createSelector(
  getQueryParamsVendors,
  (queryParams) => queryParams.nextCursor
);

export const getFilterVendors = createSelector(
  getQueryParamsVendors,
  (queryParams) => queryParams.filter
);

export const getSortVendors = createSelector(
  getQueryParamsVendors,
  (queryParams) => queryParams.sort
);

export const getLimitVendors = createSelector(
  getQueryParamsVendors,
  (queryParams) => queryParams.limit
);

// ------------------> WORKLIST <--------------------
export const getQueryParamsWorklist = (state: ReduxState) => {
  return state.queryParams.worklist;
};

export const getNextCursorWorklist = createSelector(
  getQueryParamsWorklist,
  (queryParams) => queryParams.nextCursor
);

export const getFilterWorklist = createSelector(
  getQueryParamsWorklist,
  (queryParams) => queryParams.filter
);

export const getSortWorklist = createSelector(
  getQueryParamsWorklist,
  (queryParams) => queryParams.sort
);

export const getLimitWorklist = createSelector(
  getQueryParamsWorklist,
  (queryParams) => queryParams.limit
);

export const getFilterQueryStringWorklist = createSelector(
  getQueryParamsWorklist,
  (queryParams) => queryParams.filterQueryString
)


