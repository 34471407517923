import React, { FC } from 'react';
import { Form, Input, Text } from '@tidbits/react-tidbits';

interface Props {
  task: any;
  className: string;
  setSelectedTask: any;
  handleCheckboxClick: any;
}

export const ImporetTaskValidItem: FC<Props> = ({
  task,
  className,
  setSelectedTask,
  handleCheckboxClick
}) => (
  <div id={task.id} className={className} onClick={() => setSelectedTask(task.id)}>
    <Form.Label>
      <Input.Checkbox
        className="import--list-item-checkbox"
        onChange={(e: any) => handleCheckboxClick(e, task.id)}
      />
    </Form.Label>
    <div className="import--list--thumbnail"></div>
    <div>
      <Text className="import--list--item-contentType">{task.contentType}</Text>
      <Text className="import--list--item-name">{task.name}</Text>
      <Text>{task.locale?.key}</Text>
      <Text>{task.storefronts?.value.length ? task.storefronts.value.join(', ') : 'World'}</Text>
      <Text>{task.dueDate?.value || 'No due date'}</Text>
    </div>
  </div>
);

export default ImporetTaskValidItem;
