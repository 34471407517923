import { Comment } from './Comment';
import { mockLocalization } from './Localization';
import { ScoresAndIssues } from './ScoresAndIssues';
import { mockCollaborationSummary } from './CollaborationSummary';
import { DESCRIPTION_STRINGS } from '..';

export interface BaseShelf {
  id: string;
  shelfName: string;
  shelfType: string;
  conversation?: Comment[];
  scoresAndIssues?: ScoresAndIssues;
  isLoading?: boolean;
  isError?: boolean;
  [key: string]: any; // used to add index signature in typescript
}

// Shelf will include all shelf types, this way we will be able to cover all types that will often be required
// plus it will not break old code that is using old Shelf
export type Shelf = BaseShelf;

export const mockNameShelf = {
  id: '-121',
  shelfName: 'Name',
  shelfType: DESCRIPTION_STRINGS.name,
  collaborationSummary: mockCollaborationSummary,
  localization: mockLocalization,
  isConfidenceScoreSupported: false,
  conversation: []
} as Shelf;

export const mockBaseShelf: BaseShelf = {
  id: '-121',
  shelfName: '',
  shelfType: DESCRIPTION_STRINGS.name,
  collaborationSummary: mockCollaborationSummary
};

export const mockShortShelf = {
  id: '-122',
  shelfName: 'Short',
  shelfType: DESCRIPTION_STRINGS.short,
  collaborationSummary: mockCollaborationSummary,
  localization: mockLocalization,
  isConfidenceScoreSupported: false,
  conversation: []
} as Shelf;

export const mockLongShelf = {
  id: '-123',
  shelfName: 'Long',
  shelfType: DESCRIPTION_STRINGS.long,
  collaborationSummary: mockCollaborationSummary,
  localization: mockLocalization,
  isConfidenceScoreSupported: false,
  conversation: []
} as Shelf;
