export const TASKS = 'TASKS';

export const GET_TASKS = `GET_${TASKS}`;
export const GET_TASKS_REQUEST = `${GET_TASKS}_REQUEST`;
export const GET_TASKS_SUCCESS = `${GET_TASKS}_SUCCESS`;
export const GET_TASKS_FAILURE = `${GET_TASKS}_FAILURE`;

export const GET_TASKS_INFINITE = `${GET_TASKS}_INFINITE`;
export const GET_TASKS_INFINITE_REQUEST = `${GET_TASKS_INFINITE}_REQUEST`;
export const GET_TASKS_INFINITE_SUCCESS = `${GET_TASKS_INFINITE}_SUCCESS`;
export const GET_TASKS_INFINITE_FAILURE = `${GET_TASKS_INFINITE}_FAILURE`;

export const REASSIGN_TASK = 'REASSIGN_TASK';
export const REASSIGN_TASK_REQUEST = `${REASSIGN_TASK}_REQUEST`;
export const REASSIGN_TASK_SUCCESS = `${REASSIGN_TASK}_SUCCESS`;
export const REASSIGN_TASK_FAILURE = `${REASSIGN_TASK}_FAILURE`;

export const EXPORT_TASKS = 'EXPORT_TASKS';
export const EXPORT_TASKS_REQUEST = `${EXPORT_TASKS}_REQUEST`;
export const EXPORT_TASKS_SUCCESS = `${EXPORT_TASKS}_SUCCESS`;
export const EXPORT_TASKS_FAILURE = `${EXPORT_TASKS}_FAILURE`;

export const CREATE_NEW_TASKS = 'CREATE_NEW_TASKS';
export const CREATE_NEW_TASKS_REQUEST = `${CREATE_NEW_TASKS}_REQUEST`;
export const CREATE_NEW_TASKS_SUCCESS = `${CREATE_NEW_TASKS}_SUCCESS`;
export const CREATE_NEW_TASKS_FAILURE = `${CREATE_NEW_TASKS}_FAILURE`;
export const RESET_CREATE_TASK_ERROR = 'RESET_CREATE_TASK_ERROR';

export const SET_FILTER_INDEX = 'SET_FILTER_INDEX';

export const SET_SELECTED_TASKS = 'SET_SELECTED_TASKS';

export const TASKS_RECORD = 'TASKS_RECORD';
export const GET_TASKS_RECORD = `GET_${TASKS_RECORD}`;
export const GET_TASKS_RECORD_REQUEST = `${GET_TASKS_RECORD}_REQUEST`;
export const GET_TASKS_RECORD_SUCCESS = `${GET_TASKS_RECORD}_SUCCESS`;
export const GET_TASKS_RECORD_FAILURE = `${GET_TASKS_RECORD}_FAILURE`;
