export enum FilterRequestOperation {
  BETWEEN = 'BETWEEN',
  CONTAINS_ALL = 'CONTAINS_ALL',
  EQUALS = 'EQUALS'
}

export interface BetweenFilterRequest {
  operation: FilterRequestOperation.BETWEEN;
  field: string;
  value: string[][];
}

export interface ContainsAllFilterRequest {
  operation: FilterRequestOperation.CONTAINS_ALL;
  field: string;
  value: string[];
}

export interface BasicFilterRequest {
  operation: FilterRequestOperation.EQUALS;
  field: string;
  value: string[];
}

export type FilterRequest = BasicFilterRequest | ContainsAllFilterRequest | BetweenFilterRequest;

export const createFilterRequest = (
  field: string,
  operation: FilterRequestOperation,
  value: any
): FilterRequest => {
  return {
    field,
    operation,
    value
  } as FilterRequest;
};
