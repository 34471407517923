import { KeyDisplayName } from './KeyDisplayName';
import { KEY_DISPLAY_NAME } from '../testMocks';
import { BasicUser, mockBasicUser } from './BasicUser';
import { Highlight } from './Highlight';

export interface Comment {
  id: string;
  createdAt: string;
  lastModifiedAt: string;
  createdBy: BasicUser;
  collaborationType: KeyDisplayName;
  collaborationStatus: KeyDisplayName | null;
  resolvedBy: { displayName: string; timestamp: string };
  text: string;
  contentSnapshot: string;
  highlight: Highlight | null;
  replies: Comment[];
  parentId: string | '';
  readStatus: KeyDisplayName;
  editable: boolean;
}

export const mockComment = {
  id: 'UNKNOWN_COMMENT',
  createdAt: '2019-02-26T10:58:28.000Z',
  lastModifiedAt: '2019-02-26T10:58:28.000Z',
  createdBy: mockBasicUser,
  collaborationType: KEY_DISPLAY_NAME,
  collaborationStatus: null,
  resolvedBy: {
    displayName: 'UNKNOWN_USER',
    timestamp: '2019-02-26T10:58:28.000Z'
  },
  text: 'UNKNOWN_TEXT',
  contentSnapshot: '',
  highlight: null,
  parentId: '',
  replies: [],
  readStatus: KEY_DISPLAY_NAME,
  editable: false
} as Comment;
