import {
  SET_SORT_WORKLIST,
  SET_FILTER_WORKLIST,
  CLEAR_ALL_FILTERS_WORKLIST,
  INIT_QUERY_PARAMS,
  SET_SELECTED_FILTERS_WORKLIST,
  DELETE_FILTER_WORKLIST
} from './queryParamsActionTypes';

// ----------------------> SORT <-----------------------------------

export const setSortWorklist = (sort) => ({ type: SET_SORT_WORKLIST, sort });

// ---------------------> FILTER <----------------------------------

export const deleteFilterWorklist = (filter) => ({
  type: DELETE_FILTER_WORKLIST,
  filter
});

export const setSelectedWorklistFilters = (filters, query) => ({
  type: SET_SELECTED_FILTERS_WORKLIST,
  filters,
});

export const setFilterWorklist = (filter, query='') => ({
  type: SET_FILTER_WORKLIST,
  filter,
  query
});

// --------------------> CLEAR FILTERS <-----------------------------

export const clearAllFiltersWorklist = () => ({
  type: CLEAR_ALL_FILTERS_WORKLIST
});

// Initialization on start
export const initQueryParams = (queryParams) => ({
  type: INIT_QUERY_PARAMS,
  queryParams
});
