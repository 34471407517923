import {
  GetAlertsActionType,
  CreateAlertActionTypes,
  CreateAlertRequestAction,
  CreateAlertRequestFailure,
  UpdateAlertRequestAction,
  UpdateAlertActionTypes,
  UpdateAlertRequestPayload,
  CreateAlertRequestPayload,
  UpdateAlertRequestFailure,
  DeleteAlertRequestPayload,
  DeleteAlertActionTypes,
  DeleteAlertRequestAction
} from './alertsActionTypes';

export const getAlertsRequest = () => ({
  type: GetAlertsActionType.GET_ALERTS_REQUEST
});

export const getAlertsSuccess = (payload: any) => ({
  type: GetAlertsActionType.GET_ALERTS_SUCCESS,
  payload: payload
});

export const getAlertsFailure = (error: Error) => ({
  type: GetAlertsActionType.GET_ALERTS_FAILURE,
  payload: error
});

export const createAlertRequest = (
  payload: CreateAlertRequestPayload
): CreateAlertRequestAction => ({
  type: CreateAlertActionTypes.CREATE_ALERT_REQUEST,
  payload
});

export const createAlertSuccess = () => ({
  type: CreateAlertActionTypes.CREATE_ALERT_SUCCESS
});

export const createAlertFailure = (error: Error): CreateAlertRequestFailure => ({
  type: CreateAlertActionTypes.CREATE_ALERT_FAILURE,
  payload: error
});

export const updateAlertRequest = (
  payload: UpdateAlertRequestPayload
): UpdateAlertRequestAction => ({
  type: UpdateAlertActionTypes.UPDATE_ALERT_REQUEST,
  payload
});

export const updateAlertSuccess = (payload: any) => ({
  type: UpdateAlertActionTypes.UPDATE_ALERT_SUCCESS,
  payload
});

export const updateAlertFaillure = (error: Error): UpdateAlertRequestFailure => ({
  type: UpdateAlertActionTypes.UPDATE_ALERT_FAILURE,
  payload: error
});

export const deleteAlertRequest = (
  payload: DeleteAlertRequestPayload
): DeleteAlertRequestAction => ({
  type: DeleteAlertActionTypes.DELETE_ALERT_REQUEST,
  payload
});

export const deleteAlertSuccess = () => ({
  type: DeleteAlertActionTypes.DELETE_ALERT_SUCCESS
});

export const deleteAlertFailure = (error: Error) => ({
  type: DeleteAlertActionTypes.DELETE_ALERT_FAILURE,
  payload: error
});
