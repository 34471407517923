import React, { useState } from 'react';
import { Modal, Ref } from '@tidbits/react-tidbits';
import ImportValidationPage from './ImportValidationPage';
import ImportTaskListPage from './ImportTaskListPage';

export const ImportExportModal = () => {
  const [page, setPage] = useState('validation');
  const [file, setFile] = useState<any[]>([]);
  const [workflowType, setWorkflowType] = useState('');

  const getPageContent = (hide: any, setRef: any) => {
    switch (page) {
      case 'validation':
        return (
          <ImportValidationPage
            label={getLabel()}
            setRef={setRef}
            file={file}
            setFile={setFile}
            hide={hide}
            setPage={setPage}
            workflowType={workflowType}
          />
        );
      case 'importTaskList':
        return (
          <ImportTaskListPage
            label={getLabel()}
            file={file}
            hide={hide}
            workflowType={workflowType}
          />
        );
    }
  };

  const showClicked = (show: () => void) => {
    const modalButton = document.getElementById('hiddenModalButton');
    setWorkflowType(modalButton?.dataset?.workflowtype || '');
    setFile([]);
    setPage('validation');
    show();
  };

  const getLabel = () => {
    if (workflowType === 'MUSIC') {
      return 'Import Music Workflow';
    } else if (workflowType === 'VIDEO') {
      return 'Import Video Workflow';
    } else if (workflowType === 'COPY_WORKFLOW') {
      return 'Import Copy Workflow';
    } else if (workflowType === 'APP') {
      return 'Import Apps Workflow';
    } else {
      return 'None';
    }
  };

  return (
    <Modal.State>
      {({ show, hide, isHidden }: { show: any; hide: any; isHidden: any }) => (
        <Ref>
          {({ ref, setRef }: { ref: any; setRef: any }) => (
            <>
              <Modal.Window open={!isHidden} closeModal={hide} focusableRef={ref}>
                {getPageContent(hide, setRef)}
              </Modal.Window>
              <div
                id="hiddenModalButton"
                onClick={() => showClicked(show)}
                data-workflowType={workflowType}
              />
            </>
          )}
        </Ref>
      )}
    </Modal.State>
  );
};

export default ImportExportModal;
