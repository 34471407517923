import { all, takeLatest, put, call } from 'redux-saga/effects';
import { getReassignListSuccess } from './reassignActions';
import { api } from '@helper-hooks/api';
import { GetReassignListRequestAction, TaskReassignActionTypes } from './reassignTypes';

export function* getReassignListRequest(action: GetReassignListRequestAction) {
  try {
    let reassignResponse;
    reassignResponse = yield call(api.qualifiedUsers, action.payload);
    yield put(getReassignListSuccess(reassignResponse));
  } catch (err) {

  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(TaskReassignActionTypes.GET_REASSIGN_LIST_REQUEST, getReassignListRequest)
  ]);
}
