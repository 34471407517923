import { StaticDataActionTypes } from './staticDataActionTypes';
import { ActionCreator } from 'redux';
import { StaticData } from '../../common/types';

interface StaticDataRequestAction {
  type: StaticDataActionTypes.GET_STATIC_DATA_REQUEST;
}

export const staticDataRequest: ActionCreator<StaticDataRequestAction> = () => ({
  type: StaticDataActionTypes.GET_STATIC_DATA_REQUEST
});

interface StaticDataSuccessAction {
  type: StaticDataActionTypes.GET_STATIC_DATA_SUCCESS;
  data: StaticData;
}

export const staticDataSuccess: ActionCreator<StaticDataSuccessAction> = (data) => ({
  type: StaticDataActionTypes.GET_STATIC_DATA_SUCCESS,
  data
});

interface StaticDataFailureAction {
  type: StaticDataActionTypes.GET_STATIC_DATA_FAILURE;
}

export const staticDataFailure: ActionCreator<StaticDataFailureAction> = () => ({
  type: StaticDataActionTypes.GET_STATIC_DATA_FAILURE
});

export type StaticDataActions =
  | StaticDataRequestAction
  | StaticDataSuccessAction
  | StaticDataFailureAction;
