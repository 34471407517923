import { KeyDisplayName } from './KeyDisplayName';

export interface ShelfScoreStatus {
  shelfId: string;
  status: KeyDisplayName;
}

export const mockShelfScoreStatus = {
  shelfId: '-123',
  status: { key: 'UNKNOWN_STATUS', displayName: 'Unknown status' }
} as ShelfScoreStatus;
