import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Heading, Text } from '@tidbits/react-tidbits';
import { selectGlobalAlerts } from '../../../../../store/alerts/alertsSelector';
import DeleteIcon from './DeleteIcon';
import AlertBanner, { GlobalAlerts } from '@ui-components/alert-banner';
import styled from 'styled-components';
import { deleteAlertRequest } from '../../../../../store/alerts/alertsAction';

const AlertListItem = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const AlertListContainer = styled(Box)`
  padding-top: 5px;
  padding-right: 5px;
  height: 50%;
  overflow-y: auto;
`;

const DeleteIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

const GlobalAlertsList = () => {
  const dispatch = useDispatch();
  const alerts = useSelector(selectGlobalAlerts);

  const handleOnDelete = useCallback(
    (id: string) => {
      dispatch(deleteAlertRequest({ uuid: id }));
    },
    [dispatch]
  );

  return (
    <AlertListContainer>
      <Heading.H3 sb="standard">Active Alerts</Heading.H3>
      {!alerts.length && (
        <Text as="span" textStyle="h5Regular">
          No Active Alerts
        </Text>
      )}
      {alerts.map((alert: GlobalAlerts) => {
        const { id } = alert;
        return (
          <AlertListItem>
            <AlertBanner alert={alert} />
            <DeleteIconContainer>
              <DeleteIcon handleOnClick={(e: any) => handleOnDelete(id)} />
            </DeleteIconContainer>
          </AlertListItem>
        );
      })}
    </AlertListContainer>
  );
};

export default GlobalAlertsList;
