import React, { FC } from 'react';
import { Text } from '@tidbits/react-tidbits';
import styled from 'styled-components';
import { Colors } from '@ui-components/style-constants';

interface Props {
  field: {
    displayName: string | undefined;
    value: string | string[] | undefined;
  };
}

const ImportTaskListShelf = styled.div`
  border: 1px solid #${Colors.gray};
  border-radius: 4px;
  width: 100%;
  margin: 35px 0px;
  padding: 10px;
`;

const ShelfHeader = styled.div`
  flex-direction: row;
`;

const ShelfTitle = styled(Text)<{ hasText: boolean }>`
  font-weight: 500 !important;
  padding: ${props => props.hasText ? '10px' : '20px'} 0px 15px 0px;
`;

const ShelfCharacterCount = styled(Text)`
  text-align: right;
  margin-top: -30px !important;
`;

const ShelfNotes = styled(Text)`
  margin-top: 15px !important;
`;

const ShelfListItem = styled.div`
  padding-bottom: 4px;
`;

export const ImportTaskSelectedTask: FC<Props> = ({ field }) => {

  const renderCharacterCount = () => {
    let text;
    if(Array.isArray(field.value)) {
      text = field.value.join(' ');
    } else {
      text = String(field.value);
    }
    
    return `${text?.length || 0} characters (${
      text?.split(' ').length || '0'
    } words)`;
  }

  const renderShelf = () => {
    if(Array.isArray(field.value)) {
      return (
        <>
          {
            field.value.map((item, index) => {
              return (
                <ShelfListItem >
                  { `${index+1}. ${item}` } 
                </ShelfListItem>
              )
            })
          }
        </>
      )
    } else {
      return field.value ? field.value : 'Empty'
    }
  }

  return (
    <ImportTaskListShelf>
      <ShelfHeader>
        <ShelfTitle hasText={field.displayName}>
          {field.displayName}
        </ShelfTitle>
        <ShelfCharacterCount>
          { renderCharacterCount() }
        </ShelfCharacterCount>
      </ShelfHeader>
      <ShelfNotes>
          { renderShelf() }
      </ShelfNotes>
    </ImportTaskListShelf>
  )
};

export default ImportTaskSelectedTask;
