"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedQueryKeys = void 0;
var SharedQueryKeys;
(function (SharedQueryKeys) {
    SharedQueryKeys["TASK_HISTORY"] = "taskHistory";
    SharedQueryKeys["VENDOR"] = "vendor";
    SharedQueryKeys["QUALIFIED_USERS"] = "qualifiedUsers";
    SharedQueryKeys["COLLABORATION_BATCH_SUMMARY"] = "collaborationBatchSummary";
    SharedQueryKeys["COLLABORATION_SUMMARY"] = "collaborationSummary";
    SharedQueryKeys["COMMENTS"] = "comments";
    SharedQueryKeys["USER"] = "user";
    SharedQueryKeys["CONFIDENCE_SCORE"] = "confidenceScore";
    SharedQueryKeys["COPY_CHECK"] = "copyCheck";
    SharedQueryKeys["CHARACTER_COUNTS"] = "characterCounts";
})(SharedQueryKeys = exports.SharedQueryKeys || (exports.SharedQueryKeys = {}));
