import { UserToReassignV2 } from '@providers/types-provider';
import { ReassignTaskActionTypes, TaskReassignActionTypes } from './reassignTypes';

const initialState = [] as UserToReassignV2[];

export default function reassignReducer(state = initialState, action: ReassignTaskActionTypes) {
  switch (action.type) {
    case TaskReassignActionTypes.GET_REASSIGN_LIST_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}
