import { AxiosPromise } from 'axios';
import axiosInstance from '../utils/axiosInstance';

const USER_PREFERENCES_PATH = 'api/userpreferences';

const getKeyString = (keys: string[]) => {
  let keyString = '';
  keys.forEach((key, index) => {
    keyString += `key=${key}`;
    if (index !== keys.length - 1) {
      keyString += '&';
    }
  });
  return keyString;
};

export const getPreferences = (contextId: string, keys: string[]): AxiosPromise<any> => {
  const keyString = getKeyString(keys);
  const url = `/${USER_PREFERENCES_PATH}/${contextId}?${keyString}`;

  return axiosInstance.get(url).then((res) => res);
};

export const putPreferences = (
  contextId: string,
  key: string,
  payload: string
): AxiosPromise<any> => {
  const url = `/${USER_PREFERENCES_PATH}/${contextId}`;
  const putPayload = [
    {
      prefKey: key,
      payload
    }
  ];
  return axiosInstance.put(url, putPayload).then((res) => res);
};
