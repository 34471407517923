import { KeyDisplayName } from './KeyDisplayName';

export interface Localization {
  id: string;
  lastModified: string;
  referenceLocale: KeyDisplayName;
  referenceText: string;
  shelfDisplayTitle: string;
  shelfLocalizationHistory: any[]; // TODO: create type once we know structure
  shelfText: string;
  shelfAutogeneratedText?: string;
  shelfTextHighlights: any; // TODO: create type once we know structure
  shelfTextToUpdate: string;
  shelfPlainTextLength?: number;
}

const date = new Date('2020-01-01').toISOString();
export const mockLocalization = {
  id: '-123',
  lastModified: date,
  referenceLocale: {
    key: 'UNKNOWN_REFERENCE_LOCALE',
    displayName: 'Unknown reference locale'
  },
  referenceText: 'UNKNOWN_REFERENCE_TEXT',
  shelfDisplayTitle: 'UNKNOWN_SHELF_DISPLAY_TITLE',
  shelfLocalizationHistory: [],
  shelfText: '',
  shelfAutogeneratedText: '',
  shelfTextHighlights: [],
  shelfTextToUpdate: ''
} as Localization;
