"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateKeys = void 0;
var DateKeys;
(function (DateKeys) {
    DateKeys["dueDate"] = "dueDate";
    DateKeys["writerDueDate"] = "writerDueDate";
    DateKeys["editorDueDate"] = "editorDueDate";
    DateKeys["reviewerDueDate"] = "reviewerDueDate";
    DateKeys["geoLeadDueDate"] = "geoLeadDueDate";
    DateKeys["copyEndDate"] = "copyEndDate";
    DateKeys["copyStartDate"] = "copyStartDate";
})(DateKeys = exports.DateKeys || (exports.DateKeys = {}));
