import React, { FC } from 'react';
import { Dropdown } from 'semantic-ui-react';
import i18n from '../../../../i18n';

import './notificationsHeader.scss';
import TRANSLATE_KEYS from '../../../../public/locales/translateKeys';

const NotificationsHeader: FC = () => (
  <Dropdown.Header className="mpe-navigation_notifications-header">
    <span>{i18n.t(TRANSLATE_KEYS.notifications_panel.notifications)}</span>
    <span>{i18n.t(TRANSLATE_KEYS.notifications_panel.mark_all_as_read)}</span>
  </Dropdown.Header>
);

export default NotificationsHeader;
