import axiosInstance from '../utils/axiosInstance';
import {
  CreateAlertRequestPayload,
  DeleteAlertRequestPayload,
  UpdateAlertRequestPayload
} from '../store/alerts/alertsActionTypes';

// /api/api2/v1/alerts?status=ACTIVE
const GlobalAlerts = {
  createAlert: (payload: CreateAlertRequestPayload) => {
    return axiosInstance.post('/api/api2/v1/alerts', payload).then((res) => res.data);
  },
  getAlerts: (status?: string) => {
    const queryParams = status ? `status=${status}` : '';
    return axiosInstance.get(`/api/api2/v1/alerts?${queryParams}`).then((res) => res.data);
  },
  getAlertById: (id: string) => {
    return axiosInstance.post(`/api/api2/v1/alerts/${id}`).then((res) => res.data);
  },
  updateAlert: (payload: UpdateAlertRequestPayload) => {
    const { uuid, ...rest } = payload;
    return axiosInstance
      .post(`/api/api2/v1/alerts/${uuid}`, {
        ...rest
      })
      .then((res) => res.data);
  },
  deleteAlert: (payload: DeleteAlertRequestPayload) => {
    const { uuid } = payload;
    return axiosInstance.delete(`/api/api2/v1/alerts/${uuid}`).then((res) => res.data);
  }
};

export default GlobalAlerts;
