"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isConfidenceScoreSupportedByLocale = void 0;
const supportedLocales = [
    'en',
    'en-US',
    'en-GB',
    'en-ROW',
    'de',
    'de-DE',
    'es',
    'es-ES',
    'fr',
    'fr-FR',
    'nl',
    'nl-NL',
    'pt',
    'pt-PT',
    'ja',
    'ja-JP',
    'es-MX',
    'pt-BR',
    'fr-CA',
    'da-DK',
    'sv-SE',
    'zh-Hans-CN',
    'ar-SA',
    'el-GR'
];
exports.isConfidenceScoreSupportedByLocale = (locale) => {
    const hasSupportedLocale = supportedLocales.find((supportedLocale) => supportedLocale === locale);
    return hasSupportedLocale ? true : false;
};
