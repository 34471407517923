import React, { FC } from 'react'
import Toggle from '@ui-components/toggle';

interface Props {
  defaultValue: boolean;
  handleOnChange: (value: boolean) => void;
}

export const ToggleSwitch: FC<Props> = ({
  defaultValue,
  handleOnChange
}) => {
  return (
    <Toggle
      value={defaultValue}
      onClickHandler={handleOnChange}
    />
  )
}





