import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_INFINITE_REQUEST,
  GET_USERS_INFINITE_SUCCESS,
  GET_USERS_INFINITE_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  CREATE_USER_CLEAR,
  FETCH_USERS_MENTIONING_REQUEST,
  FETCH_USERS_MENTIONING_SUCCESS,
  FETCH_USERS_MENTIONING_FAILURE,
  GET_USERS_FOR_REASSIGN_REQUEST,
  GET_USERS_FOR_REASSIGN_SUCCESS,
  GET_USERS_FOR_REASSIGN_FAILURE
} from './usersActionTypes';

export const fetchUsersForReassignRequest = (queryParams) => ({
  type: GET_USERS_FOR_REASSIGN_REQUEST,
  queryParams
});

export const fetchUsersForReassignSuccess = (data) => ({
  type: GET_USERS_FOR_REASSIGN_SUCCESS,
  data
});

export const fetchUsersForReassignFailure = (error) => ({
  type: GET_USERS_FOR_REASSIGN_FAILURE,
  error
});

export const fetchUsersRequest = (queryParams) => ({
  type: GET_USERS_REQUEST,
  queryParams
});

export const fetchUsersSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  data
});

export const fetchUsersFailure = (error) => ({
  type: GET_USERS_FAILURE,
  error
});

export const fetchUsersInfiniteRequest = (queryParams) => ({
  type: GET_USERS_INFINITE_REQUEST,
  queryParams
});

export const fetchUsersInfiniteSuccess = (data) => ({
  type: GET_USERS_INFINITE_SUCCESS,
  data
});

export const fetchUsersInfiniteFailure = (error) => ({
  type: GET_USERS_INFINITE_FAILURE,
  error
});

export const createUserRequest = (user) => ({
  type: CREATE_USER_REQUEST,
  user
});

export const createUserSuccess = (user) => ({
  type: CREATE_USER_SUCCESS,
  user
});

export const createUserFailure = (error) => ({
  type: CREATE_USER_FAILURE,
  error
});

export const createUserClear = () => ({
  type: CREATE_USER_CLEAR
});

export const deleteUserRequest = (id) => ({
  type: DELETE_USER_REQUEST,
  id
});

export const deleteUserSuccess = (id) => ({
  type: DELETE_USER_SUCCESS,
  id
});

export const deleteUserFailure = (error) => ({
  type: DELETE_USER_FAILURE,
  error
});

export const editUserRequest = (data) => ({
  type: EDIT_USER_REQUEST,
  data
});

export const editUserSuccess = (user) => ({
  type: EDIT_USER_SUCCESS,
  user
});

export const editUserFailure = (error) => ({
  type: EDIT_USER_FAILURE,
  error
});

export const fetchUsersMentionRequest = (searchTerm, callback) => ({
  type: FETCH_USERS_MENTIONING_REQUEST,
  searchTerm,
  callback
});

export const fetchUsersMentionSuccess = (users) => ({
  type: FETCH_USERS_MENTIONING_SUCCESS,
  users
});

export const fetchUsersMentionFailure = (error) => ({
  type: FETCH_USERS_MENTIONING_FAILURE,
  error
});
