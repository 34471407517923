import { call, put, takeLatest, all } from 'redux-saga/effects';

import { staticDataSuccess, staticDataFailure } from './staticDataActions';
import api from '../../api/api';
import { setLocalStorageData } from '../../utils/localStorageData';
import { adoptPromiseAllData } from '../../utils/AntiCorruption/AntiCorruptionLayer';
import { StaticDataActionTypes } from './staticDataActionTypes';

export function* staticDataRequest() {
  try {
    const staticData = yield call(api.staticData.getStaticData);

    const mappedStaticData = setLocalStorageData(adoptPromiseAllData(staticData));
    yield put(staticDataSuccess(mappedStaticData));
  } catch (err) {
    // TODO: danijela - find better way to fix this
    // @ts-ignore
    yield put(staticDataFailure(err.response.data.message));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(StaticDataActionTypes.GET_STATIC_DATA_REQUEST, staticDataRequest)]);
}
