import { KeyDisplayName } from './KeyDisplayName';

// TODO: Remove this if it is not used
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  role: string;
  jobTypes: string[];
  vendorName: string;
  locales: KeyDisplayName[];
  localeCodes?: string[];
  userPreferences?: any; // TODO: change type once we know the structure
  permissions: string[];
  userColumns: {
    tasks: KeyDisplayName[];
    users: KeyDisplayName[];
    vendors: KeyDisplayName[];
    reassign: KeyDisplayName[];
  };
  favoriteGenre: KeyDisplayName[];
  favoriteContentType: KeyDisplayName[];
  favoriteMedia: KeyDisplayName[];
  available: boolean;
  status: KeyDisplayName | null;
  averageStarRating: number;
  enabled: boolean;
  accessToken: string;
  publishedTasks: number;
  assignedTasks: number;
  rejectedTasks: number;
}

export const mockUser = {
  id: '001',
  firstName: 'UNKNOWN_NAME',
  lastName: 'UNKNOWN_LAST_NAME',
  displayName: 'UNKNOWN_DISPLAY_NAME',
  email: 'UNKNOWN_EMAIL',
  role: 'UNKNOWN_ROLE',
  jobTypes: ['SUPER_ADMIN'],
  vendorName: 'UNKNOWN_VENDOR',
  locales: [{ key: '', displayName: 'Unknown locales' }],
  localeCodes: [''],
  permissions: ['UNKNOWN_PERMISSION'],
  favoriteGenre: [{ key: 'UNKNOWN_FAVORITE_GENRE', displayName: '' }],
  favoriteContentType: [
    {
      key: 'UNKNOWN_FAVORITE_CONTENT_TYPE',
      displayName: ''
    }
  ],
  favoriteMedia: [{ key: 'UNKNOWN_FAVORITE_MEDIA', displayName: 'Unknown favorite media' }],
  available: false,
  status: null,
  averageStarRating: 0,
  enabled: false,
  accessToken: 'UNKNOWN_ACCESS_TOKEN',
  publishedTasks: 0,
  assignedTasks: 0,
  rejectedTasks: 0
} as User;
