export const USERS = 'USERS';

export const GET_USERS = `GET_${USERS}`;
export const GET_USERS_REQUEST = `${GET_USERS}_REQUEST`;
export const GET_USERS_SUCCESS = `${GET_USERS}_SUCCESS`;
export const GET_USERS_FAILURE = `${GET_USERS}_FAILURE`;

export const GET_USERS_FOR_REASSIGN = `GET_${USERS}_FOR_REASSIGN`;
export const GET_USERS_FOR_REASSIGN_REQUEST = `${GET_USERS_FOR_REASSIGN}_REQUEST`;
export const GET_USERS_FOR_REASSIGN_SUCCESS = `${GET_USERS_FOR_REASSIGN}_SUCCESS`;
export const GET_USERS_FOR_REASSIGN_FAILURE = `${GET_USERS_FOR_REASSIGN}_FAILURE`;

export const GET_USERS_INFINITE = `${GET_USERS}_INFINITE`;
export const GET_USERS_INFINITE_REQUEST = `${GET_USERS_INFINITE}_REQUEST`;
export const GET_USERS_INFINITE_SUCCESS = `${GET_USERS_INFINITE}_SUCCESS`;
export const GET_USERS_INFINITE_FAILURE = `${GET_USERS_INFINITE}_FAILURE`;

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_REQUEST = `${CREATE_USER}_REQUEST`;
export const CREATE_USER_SUCCESS = `${CREATE_USER}_SUCCESS`;
export const CREATE_USER_FAILURE = `${CREATE_USER}_FAILURE`;
export const CREATE_USER_CLEAR = `${CREATE_USER}_CLEAR`;

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_REQUEST = `${DELETE_USER}_REQUEST`;
export const DELETE_USER_SUCCESS = `${DELETE_USER}_SUCCESS`;
export const DELETE_USER_FAILURE = `${DELETE_USER}_FAILURE`;

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_REQUEST = `${EDIT_USER}_REQUEST`;
export const EDIT_USER_SUCCESS = `${EDIT_USER}_SUCCESS`;
export const EDIT_USER_FAILURE = `${EDIT_USER}_FAILURE`;

export const FETCH_USERS_MENTIONING = 'FETCH_USERS_MENTIONING';
export const FETCH_USERS_MENTIONING_REQUEST = `${FETCH_USERS_MENTIONING}_REQUEST`;
export const FETCH_USERS_MENTIONING_SUCCESS = `${FETCH_USERS_MENTIONING}_SUCCESS`;
export const FETCH_USERS_MENTIONING_FAILURE = `${FETCH_USERS_MENTIONING}_FAILURE`;
