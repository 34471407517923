import { AxiosPromise } from 'axios';
import axiosInstance from '../utils/axiosInstance';
import { FilterRequest } from '../utils/RSQL/FilterRequest';
import {
  getUrlQueryParams,
  getFilterQueryParams,
  getUrlQueryParamsFilterV2,
  getFilterSearchParams
} from '../utils/worklistParams';
import { generateFileFromExport } from '../utils/generalUtils';
import { mapV2WorklistResponseToV1 } from '../utils/mapV2TaskItemToV1TaskItem';
import { mapV2AvailableTermsToV1 } from '../utils/mapV2AvailableTermsToV1';

const WORKLIST_PATH = 'api/cap-mgt/worklist';
const VCW_PATH = 'api/videocontent';
const MCW_PATH = 'api/musiccontent';
const ACW_PATH = 'api/appscanvas';
const CW_PATH = 'api/copyworkflow';
const REPORT_PATH = 'workflows/report';
const EXPORT_PATH = 'workflows/export';
const IMPORT_PATH = 'workflows/import';
const VALIDATE_PATH = 'workflows/import/validate';
const REPORT_VCW_PATH = `${VCW_PATH}/${REPORT_PATH}`;
const REPORT_MCW_PATH = `${MCW_PATH}/${REPORT_PATH}`;
const REPORT_ACW_PATH = `${ACW_PATH}/importexport/report`;
const REPORT_CW_PATH = `${CW_PATH}/${REPORT_PATH}`;
const EXPORT_VCW_PATH = `${VCW_PATH}/${EXPORT_PATH}`;
const EXPORT_MCW_PATH = `${MCW_PATH}/${EXPORT_PATH}`;
const EXPORT_ACW_PATH = `${ACW_PATH}/importexport/export`;
const EXPORT_CW_PATH = `${CW_PATH}/${EXPORT_PATH}`;
const IMPORT_VCW_PATH = `${VCW_PATH}/${IMPORT_PATH}`;
const IMPORT_MCW_PATH = `${MCW_PATH}/${IMPORT_PATH}`;
const IMPORT_CW_PATH = `${CW_PATH}/${IMPORT_PATH}`;
const IMPORT_ACW_PATH = `${ACW_PATH}/importexport/import`;
const VALIDATE_VCW_PATH = `${VCW_PATH}/${VALIDATE_PATH}`;
const VALIDATE_MCW_PATH = `${MCW_PATH}/${VALIDATE_PATH}`;
const VALIDATE_CW_PATH = `${CW_PATH}/${VALIDATE_PATH}`;
const VALIDATE_ACW_PATH = `${ACW_PATH}/importexport/import/validate`;
const WORKFLOW_EXCEL_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

interface Params {
  sortField: string;
  sortDirection: string;
  cursor: string;
  filterParams: FilterRequest[];
  searchTerm: string;
  pageSize: number;
}

interface ParamsFilterV2 {
  sortField: string;
  sortDirection: string;
  cursor: string;
  pageSize: number;
  queryString: string;
}

interface ReportParams {
  selectedTasks: string[];
  type: string;
}

interface ImportParams {
  type: string;
  file: File;
  ids: string[];
  advance: boolean;
}

interface ExportParams {
  selectedTasks: string[];
  type: string;
}

interface ValidateParams {
  type: string;
  file: File;
}

type ReportTasksParams = ReportParams & Params;
type ExportTasksParams = ExportParams & Params;

interface FilterSearchQueryParams {
  searchTerm: string;
  nextCursor?: string;
  filterKey: string;
}

export const filterSearchQueryParamsUrl = (queryParams: FilterSearchQueryParams) => {
  let url = `/api/cap-mgt/worklist/availableTerms/${queryParams.filterKey}?${getFilterSearchParams(
    queryParams
  )}`;
  return axiosInstance.get(url).then((res) => res.data);
};

export const tasks = ({
  sortField,
  sortDirection,
  cursor,
  filterParams,
  searchTerm,
  pageSize
}: Params): AxiosPromise<any> => {
  const queryUrl = getUrlQueryParams(filterParams, searchTerm, sortField, sortDirection, cursor);

  let url = `/${WORKLIST_PATH}?pageSize=${pageSize}&${queryUrl}`;
  return axiosInstance.get(url).then((res) => {
    res.data = mapV2WorklistResponseToV1(res.data);
    return res;
  });
};

export const tasksV2Filters = ({
  sortField,
  sortDirection,
  cursor,
  pageSize,
  queryString
}: ParamsFilterV2) => {
  const fullQueryUrl = getUrlQueryParamsFilterV2(sortField, sortDirection, cursor, queryString);
  let url = `/${WORKLIST_PATH}?pageSize=${pageSize}&${fullQueryUrl}`;
  return axiosInstance.get(url).then((res) => {
    res.data = mapV2WorklistResponseToV1(res.data);
    return res;
  });
};

export const reportTasks = ({ type, selectedTasks, filterParams }: ReportTasksParams): any => {
  let url = '';
  let body = {};

  if (type === 'VIDEO') {
    url = REPORT_VCW_PATH;
  } else if (type === 'MUSIC') {
    url = REPORT_MCW_PATH;
  } else if (type === 'APP') {
    url = REPORT_ACW_PATH;
  } else if (type === 'COPY_WORKFLOW') {
    url = REPORT_CW_PATH;
  }

  if (selectedTasks.length === 0) {
    const filterQueryParams = getFilterQueryParams(filterParams);
    body = {
      q: filterQueryParams ? `(${filterQueryParams})` : ''
    };
  } else {
    body = {
      workflowIds: selectedTasks
    };
  }

  return axiosInstance
    .post(url, body, { responseType: 'arraybuffer' })
    .then((res) => generateFileFromExport(res, true));
};

export const exportTasks = ({ type, selectedTasks, filterParams }: ExportTasksParams) => {
  let url = '';
  let body = {};

  if (type === 'VIDEO_CONTENT') {
    url = EXPORT_VCW_PATH;
  } else if (type === 'MUSIC_CONTENT') {
    url = EXPORT_MCW_PATH;
  } else if (type === 'APPS_CANVAS') {
    url = EXPORT_ACW_PATH;
  } else if (type === 'COPY_WORKFLOW') {
    url = EXPORT_CW_PATH;
  }

  if (selectedTasks.length === 0) {
    const filterQueryParams = getFilterQueryParams(filterParams);
    body = {
      q: filterQueryParams ? `(${filterQueryParams})` : ''
    };
  } else {
    body = {
      workflowIds: selectedTasks
    };
  }

  return axiosInstance
    .post(url, body, { responseType: 'arraybuffer' })
    .then((res) => generateFileFromExport(res, true));
};

export const importTasks = ({ type, file, ids, advance }: ImportParams) => {
  let url = '';
  if (type === 'VIDEO') {
    url = IMPORT_VCW_PATH;
  } else if (type === 'MUSIC') {
    url = IMPORT_MCW_PATH;
  } else if (type === 'COPY_WORKFLOW') {
    url = IMPORT_CW_PATH;
  } else if (type === 'APP') {
    url = IMPORT_ACW_PATH;
  }
  const idsString = ids.join(',');

  const headers = {
    'Content-Type': file.type,
    'TaskImport-Selection': advance ? idsString : '',
    'TaskImportSkipAdvance-Selection': advance ? '' : idsString
  };

  return axiosInstance.post(url, file, { headers }).then((res) => res);
};

export const validateTasks = ({ type, file }: ValidateParams) => {
  let url = '';
  if (type === 'VIDEO') {
    url = VALIDATE_VCW_PATH;
  } else if (type === 'MUSIC') {
    url = VALIDATE_MCW_PATH;
  } else if (type === 'COPY_WORKFLOW') {
    url = VALIDATE_CW_PATH;
  } else if (type === 'APP') {
    url = VALIDATE_ACW_PATH;
  }

  const headers = {
    'Content-Type': WORKFLOW_EXCEL_MIME_TYPE
  };

  return axiosInstance.post(url, file, { headers }).then((res) => res);
};

export const availableTerms = (): AxiosPromise<any> => {
  return axiosInstance.get(`/${WORKLIST_PATH}/availableTerms?numOfTerms=300`).then((res) => {
    res.data = mapV2AvailableTermsToV1(res.data);
    return res.data;
  });
};

// rdar://84201697 ([PROD] [P1] Shakespeare - Showstopper: Vendor Info Visible to Other Vendors)
export const vendorProfile = (namespace: string): AxiosPromise<any> => {
  return axiosInstance.get(`/api/${namespace}/user/profile`).then((res) => res.data);
};
