"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.putUserPreferenceRequest = exports.getUserPreferencesRequest = void 0;
const effects_1 = require("redux-saga/effects");
const userPreferencesTypes_1 = require("./userPreferencesTypes");
const userPreferencesActions_1 = require("./userPreferencesActions");
const utils_1 = require("./utils");
function* getUserPreferencesRequest(api, action) {
    var _a;
    try {
        const { contextId, keys } = action.payload;
        const userPreferencesResponse = yield effects_1.call(api, contextId, keys);
        const remappedPreferencesData = utils_1.getUserPreferencesData(userPreferencesResponse);
        yield effects_1.put(userPreferencesActions_1.getUserPreferencesSuccess(remappedPreferencesData));
    }
    catch (err) {
        const error = err;
        yield effects_1.put(userPreferencesActions_1.getUserPreferencesFailure({
            error: (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data.message,
            errorMessage: 'User Preferences could not be fetched'
        }));
    }
}
exports.getUserPreferencesRequest = getUserPreferencesRequest;
function* putUserPreferenceRequest(api, action) {
    var _a;
    const { contextId, key, preferences, currentPreferences } = action.payload;
    const newPreferences = Object.assign({}, Object.assign({}, currentPreferences[key]), preferences);
    yield effects_1.put(userPreferencesActions_1.putUserPreferenceSuccess({ [key]: Object.assign({}, newPreferences) }));
    try {
        yield effects_1.call(api, contextId, key, JSON.stringify(newPreferences));
    }
    catch (err) {
        const error = err;
        yield effects_1.put(userPreferencesActions_1.putUserPreferenceSuccess(Object.assign({}, currentPreferences)));
        yield effects_1.put(userPreferencesActions_1.putUserPreferencesFailure({
            error: (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data.message,
            errorMessage: 'PutUserPreferences failed - rolling back to previous state value'
        }));
    }
}
exports.putUserPreferenceRequest = putUserPreferenceRequest;
function* rootSaga(getAPI, putAPI) {
    yield effects_1.all([
        effects_1.takeLatest(userPreferencesTypes_1.UserPreferenceAction.GET_USER_PREFERENCE_REQUEST, getUserPreferencesRequest, getAPI),
        effects_1.takeLatest(userPreferencesTypes_1.UserPreferenceAction.PUT_USER_PREFERENCES_REQUEST, putUserPreferenceRequest, putAPI),
    ]);
}
exports.default = rootSaga;
