import { SHOW_TOAST, HIDE_TOAST } from './toastActionTypes';

const initialState = {
  open: false,
  toastProps: {
    toastType: '',
    toastMessage: {
      translateKey: '',
      translateData: {}
    }
  }
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        open: true,
        toastProps: action.toastProps
      };
    case HIDE_TOAST:
      return initialState;
    default:
      return state;
  }
};

export default toastReducer;
