import React, { FC, SyntheticEvent, useState } from 'react';
import styled from 'styled-components';
import { Box, HR, Form, Input } from '@tidbits/react-tidbits';
import AccordionList from '@ui-components/accordion-list';
import { PresetType, hasDuplicatePreset } from '@ui-components/table-filters';
import { FilterConfigType } from '@providers/types-provider';
import PresetAccordionTitle from './PresetAccordionTitle';
import PresetAccordionBody from './PresetAccordionBody';
import { useSelector } from 'react-redux';
import { getUserPreferencesFilterPresets } from '../../store/userPreferences/userPreferencesSelector';

const SelectAllSpan = styled.span`
  padding-left: 5px;
  font-weight: 500;
`;

const AccordionContainer = styled(Box)`
  padding-top: 10px;
`;

const SelectAllContainer = styled(Box)`
  padding-bottom: 10px;
`;

interface Props {
  modalType?: string;
  filterConfig: FilterConfigType[];
  presetList: PresetType[];
  selectedFilterIds: string[];
  setSelectedFilterIds: (selectedFilters: string[]) => void;
}

const ImportPresetAccordion: FC<Props> = ({
  modalType = 'share-filters',
  filterConfig,
  presetList,
  selectedFilterIds,
  setSelectedFilterIds
}) => {
  const filterPresets = useSelector(getUserPreferencesFilterPresets);
  const [activeAccordionName, setActiveAccordionName] = useState('');

  const handleAccordionClick = (clickedAccordionName: string) => {
    const activeAccordion =
      activeAccordionName === clickedAccordionName ? '' : clickedAccordionName;
    setActiveAccordionName(activeAccordion);
  };

  const handleOnSelectAccordion = (selectedPresetId: string) => {
    if (selectedFilterIds.includes(selectedPresetId)) {
      const selectedPresetIdIndex = selectedFilterIds.findIndex(
        (filterId: string) => filterId === selectedPresetId
      );
      const updatedSelectedFilters = [...selectedFilterIds];
      updatedSelectedFilters.splice(selectedPresetIdIndex, 1);
      setSelectedFilterIds(updatedSelectedFilters);
    } else {
      const updatedSelectedFilters = [...selectedFilterIds];
      updatedSelectedFilters.push(selectedPresetId);
      setSelectedFilterIds(updatedSelectedFilters);
    }
  };

  const handleSelectAll = (e: SyntheticEvent) => {
    if (selectedFilterIds.length === presetList.length) {
      setSelectedFilterIds([]);
    } else {
      const allFilterIds = presetList.map((filter: any) => filter.id);
      setSelectedFilterIds(allFilterIds);
    }
  };

  return (
    <AccordionContainer>
      <AccordionList
        activeAccordionName={activeAccordionName}
        handleAccordionClick={handleAccordionClick}
      >
        <SelectAllContainer>
          <Form.Label>
            <Input.Checkbox
              checked={selectedFilterIds.length === presetList.length}
              onChange={(e: any) => handleSelectAll(e)}
            />
            <SelectAllSpan>Select All</SelectAllSpan>
          </Form.Label>
        </SelectAllContainer>
        <HR sb="spacer3" />
        {presetList.map((preset: PresetType) => {
          const { name, id, filter, updatedAt } = preset;
          const isChecked = selectedFilterIds.includes(id);
          const duplicatePresets =
            modalType === 'import'
              ? hasDuplicatePreset(filterPresets.list, JSON.parse(filter))
              : [];
          return (
            <AccordionList.Accordion
              title={
                <PresetAccordionTitle
                  id={id}
                  title={name}
                  updatedAt={updatedAt}
                  onChange={(e: any) => handleOnSelectAccordion(id)}
                  isChecked={isChecked}
                  duplicatePresets={duplicatePresets}
                />
              }
              collapsedTitle={
                <PresetAccordionTitle
                  id={id}
                  title={name}
                  updatedAt={updatedAt}
                  onChange={(e: any) => handleOnSelectAccordion(id)}
                  isChecked={isChecked}
                  duplicatePresets={duplicatePresets}
                />
              }
              name={id}
            >
              <PresetAccordionBody
                filter={filter}
                filterConfig={filterConfig}
                duplicatePresets={duplicatePresets}
              />
            </AccordionList.Accordion>
          );
        })}
      </AccordionList>
    </AccordionContainer>
  );
};

export default ImportPresetAccordion;
