import { KeyDisplayName } from './KeyDisplayName';
import { ShelfScoreStatus, mockShelfScoreStatus } from './ShelfScoreStatus';

export interface TaskConfidenceScore {
  taskId: string;
  workflowId: string;
  status: KeyDisplayName;
  shelfScoreStatus: ShelfScoreStatus[];
}

export const mockTaskConfidenceScore = {
  taskId: '-123',
  workflowId: '-123',
  status: { key: 'UNKNOWN_STATUS', displayName: 'Unknown status' },
  shelfScoreStatus: [mockShelfScoreStatus]
} as TaskConfidenceScore;
