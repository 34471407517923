export interface CollaborationSummary {
  totalCount: number;
  unreadCount: number;
  lastMessage: string;
  sessionId: string;
}

export const mockCollaborationSummary = {
  totalCount: 0,
  unreadCount: 0,
  lastMessage: 'UNKNOWN_MESSAGE',
  sessionId: 'UNKNOWN_SESSION_ID'
} as CollaborationSummary;
