export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_REQUEST = `${USER_LOGIN}_REQUEST`;
export const USER_LOGIN_SUCCESS = `${USER_LOGIN}_SUCCESS`;
export const USER_LOGIN_FAILURE = `${USER_LOGIN}_FAILURE`;

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_REQUEST = `${FETCH_USER}_REQUEST`;
export const FETCH_USER_SUCCESS = `${FETCH_USER}_SUCCESS`;
export const FETCH_USER_FAILURE = `${FETCH_USER}_FAILURE`;

export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_REQUEST = `${USER_LOGOUT}_REQUEST`;
export const USER_LOGOUT_SUCCESS = `${USER_LOGOUT}_SUCCESS`;

export const USER_CONFIRM = 'USER_CONFIRM';
export const USER_CONFIRM_REQUEST = `${USER_CONFIRM}_REQUEST`;
export const USER_CONFIRM_SUCCESS = `${USER_CONFIRM}_SUCCESS`;
export const USER_CONFIRM_FAILURE = `${USER_CONFIRM}_FAILURE`;

export const SET_TABLE_HEADERS = 'SET_TABLE_HEADERS';
export const SET_TABLE_HEADERS_REQUEST = `${SET_TABLE_HEADERS}_REQUEST`;
export const SET_TABLE_HEADERS_SUCCESS = `${SET_TABLE_HEADERS}_SUCCESS`;
export const SET_TABLE_HEADERS_FAILURE = `${SET_TABLE_HEADERS}_FAILURE`;

export const SET_USER_AVAILABILITY = 'SET_USER_AVAILABILITY';
export const SET_USER_AVAILABILITY_REQUEST = `${SET_USER_AVAILABILITY}_REQUEST`;
export const SET_USER_AVAILABILITY_SUCCESS = `${SET_USER_AVAILABILITY}_SUCCESS`;
export const SET_USER_AVAILABILITY_FAILURE = `${SET_USER_AVAILABILITY}_FAILURE`;

export const INIT_USER_PREFERENCES = 'INIT_USER_PREFERENCES';
