import TRANSLATE_KEYS from '../translateKeys';

const translation = {
  [TRANSLATE_KEYS.welcome_to_react]: 'FR Welcome to React and react-i18next',
  [TRANSLATE_KEYS.invalid_mail]: 'FR Invalid email',
  [TRANSLATE_KEYS.invalid_password]: "FR Password can't be empty",
  [TRANSLATE_KEYS.something_went_wrong]: 'FR Something went wrong',
  [TRANSLATE_KEYS.email]: 'FR Email',
  [TRANSLATE_KEYS.password]: 'FR Password',
  [TRANSLATE_KEYS.logout]: 'FR Logout',
  [TRANSLATE_KEYS.user_available]: 'FR Available',
  [TRANSLATE_KEYS.send]: 'FR Send'
};
export default translation;
