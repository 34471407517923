import { FormType } from '../../../../common/types/UserPreferences';

import { WorkflowType } from '@helper-hooks/user-preferences-redux-action';

export const SettingsConfig = [
  {
    workflowType: WorkflowType.WORKLIST,
    features: [
      {
        propertyKey: 'pageSize',
        label: 'Select the page size for the worklist query',
        defaultValue: 50,
        type: FormType.DROPDOWN_SELECTION,
        options: [
          {
            value: 25,
            label: '25'
          },
          {
            value: 50,
            label: '50'
          },
          {
            value: 75,
            label: '75'
          },
          {
            value: 100, 
            label: '100'
          }
        ]
      }
    ]
  } 
]