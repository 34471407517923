import { SHOW_USER_PREFERENCES_MODAL, HIDE_USER_PREFERENCES_MODAL } from './userPreferencesActionTypes';

const initialState = {
  open: false,
};

const userPreferencesModalReducer = (state = initialState, action={}) => {
  switch (action.type) {
    case SHOW_USER_PREFERENCES_MODAL:
      return {
        open: true
      };
    case HIDE_USER_PREFERENCES_MODAL:
      return {
        open: false
      };
    default:
      return state;
  }
};

export default userPreferencesModalReducer;
