import { Location } from 'history';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { COMPONENT_PAGES, PROTECTION, ROUTES, ROUTE_COMPONENTS } from '../common/index';
import {
  ExpirationPage,
  LoginPage,
  ModuleTaskDetailsPage,
  ReleaseDetailsPage,
  WelcomePage,
  WorklistPage,
  CopyManagementPage
} from '../components/pages/pages';
import GuestRoute from '../routes/GuestRoute';
import UserRoute from '../routes/UserRoute';
interface Props {
  isAuthenticated: boolean;
  location: Location<any>;
}

class RouteCreator extends React.Component<Props> {
  components = {
    blank: undefined,
    [COMPONENT_PAGES.login]: LoginPage,
    [COMPONENT_PAGES.expirationPage]: ExpirationPage,
    [COMPONENT_PAGES.moduleTaskDetails]: ModuleTaskDetailsPage,
    [COMPONENT_PAGES.welcomePage]: WelcomePage,
    [COMPONENT_PAGES.releaseDetailsPage]: ReleaseDetailsPage,
    [COMPONENT_PAGES.worklistPage]: WorklistPage,
    [COMPONENT_PAGES.copyManagementPage]: CopyManagementPage
  };

  render() {
    const { location, isAuthenticated } = this.props;

    return (
      <Switch>
        {ROUTE_COMPONENTS.map((route, routeKey) => {
          const componentToRoute = this.components[route.component || 'blank'];
          switch (route.protected) {
            case PROTECTION.authenticated:
              return (
                <UserRoute
                  key={`routesKey${routeKey.toString()}`}
                  location={location}
                  path={route.name}
                  exact
                  component={componentToRoute}
                  allowedPermissions={route.permissions}
                />
              );
            case PROTECTION.unauthenticated:
              return (
                <GuestRoute
                  key={`routesKey${routeKey.toString()}`}
                  location={location}
                  path={route.name}
                  exact
                  component={componentToRoute}
                  allowedPermissions={route.permissions}
                />
              );
            default:
              return (
                <Route
                  key={`routesKey${routeKey.toString()}`}
                  location={location}
                  path={route.name}
                  exact
                  component={componentToRoute}
                />
              );
          }
        })}
        <Route
          render={() =>
            !isAuthenticated ? (
              <Redirect to={ROUTES.welcomePage} />
            ) : (
              <Redirect to={ROUTES.worklist} />
            )
          }
        />
      </Switch>
    );
  }
}

export default RouteCreator;
