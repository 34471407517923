
import { WorkflowType } from '@helper-hooks/user-preferences-redux-action';

export const getWorkflowTypeUserPreferenceKey = (workflowType: string) => {
  switch(workflowType) {
    case WorkflowType.MUSIC: 
      return 'mcw';
    case WorkflowType.VIDEO:
      return 'vcw';
    case WorkflowType.WORKLIST:
      return 'worklist';
    default:
      return '';
  }
}

export const getTypeTitle = (workflowType: WorkflowType) => {
  switch(workflowType) {
    case WorkflowType.MUSIC: 
      return 'Music Workflow';
    case WorkflowType.VIDEO:
      return 'Video Workflow';
    case WorkflowType.WORKLIST: 
      return 'Worklist';
    default:
      return '';
  }
}
