import {
  GetWorklistActionTypes,
  GetInfiniteWorklistTypes,
  ValidateWorklistTypes,
  ImportWorklistTypes,
  GetSortWorklistActionTypes,
  SetWorklistSelectedTasksTypes,
  ExportWorklistTypes,
  ReportWorklistTypes,
  UpdateWorklistItemTypes
} from './worklistActionTypes';

const initialState = {
  cursor: '',
  items: [],
  totalItems: 0,
  selectedIds: [],
  importExportTasks: {},
  importComplete: false,
  validateComplete: false,
  isLoading: false
};

export default function worklistReducer(state = initialState, action: any) {
  switch (action.type) {
    case GetWorklistActionTypes.GET_WORKLIST_REQUEST: {
      return {
        ...state,
        ...action.payload,
        isLoading: true
      };
    }
    case GetSortWorklistActionTypes.GET_SORT_WORKLIST_SUCCESS:
    case GetWorklistActionTypes.GET_WORKLIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    }
    case GetWorklistActionTypes.GET_WORKLIST_FAILURE:
    case GetSortWorklistActionTypes.GET_SORT_WORKLIST_FAILURE:
    case GetInfiniteWorklistTypes.GET_INFINITE_WORKLIST_FAILURE: {
      return {
        ...initialState,
        isLoading: false
      };
    }

    case GetInfiniteWorklistTypes.GET_INFINITE_WORKLIST_SUCCESS: {
      return {
        ...state,
        items: [...state.items, ...action.payload.items]
      };
    }
    case SetWorklistSelectedTasksTypes.SET_WORKLISTED_SELECTED_TASKS: {
      return {
        ...state,
        selectedIds: action.payload.selectedIds
      };
    }
    case ValidateWorklistTypes.VALIDATE_WORKLIST_REQUEST: {
      return {
        ...state,
        importExportTasks: [],
        validateComplete: false
      };
    }
    case ValidateWorklistTypes.VALIDATE_WORKLIST_SUCCESS: {
      return {
        ...state,
        importExportTasks: action.payload.items,
        validateComplete: true
      };
    }
    case ImportWorklistTypes.IMPORT_WORKLIST_REQUEST: {
      return {
        ...state,
        importExportTasks: [],
        importComplete: false
      };
    }
    case ImportWorklistTypes.IMPORT_WORKLIST_SUCCESS: {
      return {
        ...state,
        importExportTasks: action.payload.items,
        importComplete: true
      };
    }
    case ReportWorklistTypes.REPORT_WORKLIST_REQUEST:
    case ExportWorklistTypes.EXPORT_WORKLIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ReportWorklistTypes.REPORT_WORKLIST_SUCCESS:
    case ExportWorklistTypes.EXPORT_WORKLIST_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ReportWorklistTypes.REPORT_WORKLIST_FAILURE:
    case ExportWorklistTypes.EXPORT_WORKLIST_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UpdateWorklistItemTypes.UPDATE_WORKLIST_ITEMS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
