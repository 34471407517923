"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REJECT_SUBTITLE = exports.SUCCESS_SUBTITLE = exports.REJECT_TITLE = exports.SUCCESS_TITLE = exports.REJECT_PLACEHOLDERS = exports.SUCCESS_PLACEHOLDERS = void 0;
var SUCCESS_PLACEHOLDERS;
(function (SUCCESS_PLACEHOLDERS) {
    SUCCESS_PLACEHOLDERS["FOR_WRITER"] = "Add a comment for the writer";
    SUCCESS_PLACEHOLDERS["FOR_EDITOR"] = "Add a comment for the editor";
    SUCCESS_PLACEHOLDERS["FOR_REVIEWER"] = "Add a comment for the Reviewer";
    SUCCESS_PLACEHOLDERS["FOR_PUBLISH"] = "Add a comment for publishing";
    SUCCESS_PLACEHOLDERS["DEFAULT"] = "Add a comment";
})(SUCCESS_PLACEHOLDERS = exports.SUCCESS_PLACEHOLDERS || (exports.SUCCESS_PLACEHOLDERS = {}));
var REJECT_PLACEHOLDERS;
(function (REJECT_PLACEHOLDERS) {
    REJECT_PLACEHOLDERS["FOR_WRITER"] = "Add a comment for the writer";
    REJECT_PLACEHOLDERS["FOR_EDITOR"] = "Add a comment for the editor";
    REJECT_PLACEHOLDERS["DEFAULT"] = "Add a comment";
})(REJECT_PLACEHOLDERS = exports.REJECT_PLACEHOLDERS || (exports.REJECT_PLACEHOLDERS = {}));
var SUCCESS_TITLE;
(function (SUCCESS_TITLE) {
    SUCCESS_TITLE["FOR_WRITER"] = "Send to Writer";
    SUCCESS_TITLE["FOR_EDITOR"] = "Provide Feedback";
    SUCCESS_TITLE["FOR_REVIEWER"] = "Provide Feedback";
    SUCCESS_TITLE["FOR_PUBLISH"] = "Send to publish";
    SUCCESS_TITLE["DEFAULT"] = "Provide Feedback";
})(SUCCESS_TITLE = exports.SUCCESS_TITLE || (exports.SUCCESS_TITLE = {}));
var REJECT_TITLE;
(function (REJECT_TITLE) {
    REJECT_TITLE["DEFAULT"] = "Provide Feedback";
})(REJECT_TITLE = exports.REJECT_TITLE || (exports.REJECT_TITLE = {}));
var SUCCESS_SUBTITLE;
(function (SUCCESS_SUBTITLE) {
    SUCCESS_SUBTITLE["FOR_EDITOR"] = "You are about to send this task to an editor for review. Write a short remark in the field below if needed.";
    SUCCESS_SUBTITLE["FOR_REVIEWER"] = "You are about to send this task to a reviewer for review. Write a short remark in the field below if needed.";
    SUCCESS_SUBTITLE["FOR_PUBLISH"] = "After you submit, it will be marked for publishing. You can export finished tasks from the task list screen. This action is final and irreversible.";
    SUCCESS_SUBTITLE["DEFAULT"] = "You are about to send this task for a review. Write the short remark in the field below.";
})(SUCCESS_SUBTITLE = exports.SUCCESS_SUBTITLE || (exports.SUCCESS_SUBTITLE = {}));
var REJECT_SUBTITLE;
(function (REJECT_SUBTITLE) {
    REJECT_SUBTITLE["FOR_WRITER"] = "Rate your experience with this Writer?";
    REJECT_SUBTITLE["FOR_EDITOR"] = "Rate your experience with this Editor?";
    REJECT_SUBTITLE["DEFAULT"] = "Why are you rejecting this task?";
})(REJECT_SUBTITLE = exports.REJECT_SUBTITLE || (exports.REJECT_SUBTITLE = {}));
