import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@tidbits/react-tidbits';

import './infiniteScrollWrapper.scss';

function withInfiniteScroll(WrappedComponent) {
  class Wrapper extends Component {
    onScroll = (e) => {
      const element = e.currentTarget;
      const bottomVisible = element
        ? element.scrollHeight - element.scrollTop === element.clientHeight
        : false;
      if (bottomVisible) {
        const { fetchInfinite, nextCursor, totalItems, results } = this.props;
        if (results.length !== totalItems) {
          fetchInfinite({ nextCursor });
        }
      }
    };

    render() {
      const { loading } = this.props;
      return (
        <>
          <WrappedComponent onScroll={this.onScroll} {...this.props} />
          <Spinner visible={loading} />
        </>
      );
    }
  }

  // NOTE: loadingInfinite and fetchInfinite must be passed from WrappedComponent because they are different for each table
  Wrapper.propTypes = {
    nextCursor: PropTypes.string,
    loading: PropTypes.bool,
    // loadingInfinite: PropTypes.bool,
    fetchInfinite: PropTypes.func.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape()),
    totalItems: PropTypes.number
  };
  Wrapper.defaultProps = {
    nextCursor: null,
    loading: false,
    totalItems: 0,
    results: []
  };
  return Wrapper;
}

export default withInfiniteScroll;
