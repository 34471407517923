import { PERMISSIONS, TASK_STATUSES } from '../../common';
import { userHasPermission, mapTaskStateToJobType } from '../../utils/generalUtils';
import { FilterRequestOperation } from '../../utils/RSQL/FilterRequest';

export const isTaskInPendingStatus = (taskStatusKey) =>
  taskStatusKey === ':userDisplayStatus/pending';

export const taskCanBeReassigned = (task) => {
  if (!task) return false;

  const taskStatus = task.taskStatus || task.statusKey;
  const taskState = task.state || task.taskState;
  return isTaskInPendingStatus(taskStatus) || (taskState && !!mapTaskStateToJobType(taskState));
};

const isUserAllowedToEdit = (task, userPermissions) =>
  userHasPermission(userPermissions, PERMISSIONS.canEditTaskMetadata);

const addCustomClassForData = (task, userPermissions) => {
  if (task.status === TASK_STATUSES.EXTERNAL_REJECTED) {
    return 'mpe-table__row-red';
  }
  if (!isUserAllowedToEdit(task, userPermissions)) {
    return 'mpe-table__row-green';
  }
  return '';
};

const isUserAllowedToImportTasks = () => false;

const isUserAllowedToSeeCheckboxVisible = (userPermissions) =>
  userHasPermission(userPermissions, PERMISSIONS.canEditTaskMetadata);

const isAssignButtonDisabled = (task, userPermissions) =>
  !isUserAllowedToEdit(task, userPermissions) || !task || !taskCanBeReassigned(task);

// Once this is ts file return type will be: FilterParams[]
const filtersForTaskReassign = (selectedLocales, selectedJobTypes) => {
  const localesFilterData = {
    field: 'locale',
    value: selectedLocales,
    operation: FilterRequestOperation.CONTAINS_ALL
  };
  const roleFilterData = {
    field: 'jobType',
    value: selectedJobTypes,
    operation: FilterRequestOperation.CONTAINS_ALL
  };
  return [localesFilterData, roleFilterData];
};

const taskListUtils = {
  isAssignButtonDisabled,
  isUserAllowedToImportTasks,
  isUserAllowedToEdit,
  isUserAllowedToSeeCheckboxVisible,
  addCustomClassForData,
  filtersForTaskReassign
};

export default taskListUtils;
