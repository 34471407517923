import { MatchActionTypes } from './matchActionTypes';
import { UpdateMatchAction } from './matchAction';

export interface MatchObj {
  path: string;
  url: string;
  isExact: boolean;
  params: any;
}

interface MatchState {
  matchObj: MatchObj;
}

const initialState: MatchState = {
  matchObj: null as unknown as MatchObj
};

export type MatchAction = UpdateMatchAction;

const matchReducer = (state: MatchState = initialState, action: MatchAction) =>
  action.type === MatchActionTypes.UPDATE_MATCH ? { matchObj: { ...action.matchObj } } : state;

export default matchReducer;
