import { Component, ReactNode } from 'react';
import { reportError } from '../../../utils/errorReportUtil';

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends Component<Props> {
  static getDerivedStateFromError() {
    // Update state so the next render shows the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    reportError(error, { ...info, tags: { source: 'errorBoundary' } });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
