import React, { FC, useMemo } from 'react';
import { Box, Text } from '@tidbits/react-tidbits';
import styled from 'styled-components';
import { RawActiveFilter, mapRawFilterToActiveFilter } from '@ui-components/table-filters';
import { FilterConfigType } from '@providers/types-provider';
import FilterEntry from './FilterEntry';
import { Colors } from '@ui-components/style-constants';

interface Props {
  filterConfig: FilterConfigType[];
  filter: string;
  duplicatePresets?: any[];
}

const PresetsTextContainer = styled(Box)`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
`;

const PresetAccordionBody: FC<Props> = ({ filter, filterConfig, duplicatePresets }) => {
  const filterActiveFilterFormat = useMemo(() => {
    const filterJSON = JSON.parse(filter) as RawActiveFilter[];
    const activeFilter = mapRawFilterToActiveFilter(filterJSON, filterConfig);
    return activeFilter;
  }, [filter, filterConfig]);

  const duplicatePresetString = duplicatePresets
    ?.map((presetInfo: any) => presetInfo.name)
    .join(', ');

  return (
    <PresetsTextContainer>
      {Object.entries(filterActiveFilterFormat).map(([filterKey, filterValue]) => {
        return (
          <FilterEntry
            filterConfig={filterConfig}
            filterKey={filterKey}
            filterValue={filterValue}
          />
        );
      })}
      {duplicatePresets && duplicatePresets.length > 0 && (
        <Box>
          <Text color={`#${Colors.info}`}>
            {`You have already have a similar preset with ${duplicatePresetString}.`}{' '}
          </Text>
        </Box>
      )}
    </PresetsTextContainer>
  );
};

export default PresetAccordionBody;
