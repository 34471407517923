import React, { FC } from 'react';
import { Box, Input } from '@tidbits/react-tidbits';
import styled from 'styled-components';
import { Colors } from '@ui-components/style-constants';

interface Props {
  id: string;
  title: string;
  updatedAt: Date;
  onChange: (e: any) => void;
  isChecked: boolean;
  duplicatePresets: any[];
}

const PresetTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const TitleContainer = styled(Box)<{ hasDuplicates: boolean }>`
  padding-left: 5px;
  margin-top: -2px;
  color: ${(props) => props.hasDuplicates && `#${Colors.error}`};
`;

const DateSpan = styled.span`
  color: ${Colors.labelPlaceholder};
`;

const PresetAccordionTitle: FC<Props> = ({
  id,
  title,
  updatedAt,
  onChange,
  isChecked,
  duplicatePresets
}) => {
  const handleOnChange = (e: any) => {
    onChange && onChange(id);
  };

  return (
    <PresetTitleContainer>
      <Input.Checkbox
        checked={isChecked}
        onClick={(e: any) => e.stopPropagation()}
        onChange={handleOnChange}
      />
      <TitleContainer hasDuplicates={duplicatePresets.length}>
        {title}&nbsp;
        <DateSpan>{`(${new Date(updatedAt).toDateString()})`}</DateSpan>
      </TitleContainer>
    </PresetTitleContainer>
  );
};

export default PresetAccordionTitle;
