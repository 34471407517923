import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal, NavMenu } from '@tidbits/react-tidbits';
import { Colors } from '@ui-components/style-constants';
import { getUserPermissions } from '../../../../../store/user/userSelector';
import { userHasPermission } from '@helper-hooks/general-utils';
import { NavMenuOptions, NavMenuPageDetails, Pages} from '../../../../../common/types/UserPreferences';
  
const ContentContainer = styled.div`
  width: 80%;
  padding-left: 10px;
`;

interface Props {
  navMenuOptions: NavMenuOptions;
  selectedKey: string;
  handleOnNavMenuClick: (key: string) => void;
}

export const UserPreferencesContentBody: FC<Props> = ({
    navMenuOptions,
    selectedKey,
    handleOnNavMenuClick
}) => {
  const userPermissions = useSelector(getUserPermissions);
  const { title, pages } = navMenuOptions;

  const renderSubpageLink = (key: Pages, { permissions, title }: NavMenuPageDetails) => {
    const hasPermissions = permissions && userHasPermission(userPermissions, permissions);
    if(!permissions || hasPermissions) {
      return (
        <NavMenu.Link
          active={selectedKey === key}
          key={key}
          onClick={(e: any) => handleOnNavMenuClick(key)}
          hoverBg={`#${Colors.keyline}`}
          hoverColor={`#${Colors.black_soft}`}
          cursor="pointer"
        >
          {title}
        </NavMenu.Link>
      )
    }
    return <></>
  }

  const renderPage = (selectedKey: any) => {
    if(pages.hasOwnProperty(selectedKey)) {
      const { Component } = pages[selectedKey];
      return <Component/>
    }
    return <></>;
  }

  return (
    <Modal.Body className="user-preferences-modal-body">
      <NavMenu variant="popover" width="200px" marginTop="-45px" marginRight="5px" marginLeft="-20px">
      <NavMenu.Title>{title}</NavMenu.Title>
      <NavMenu.Links>
      {
        Object.entries(pages).map(([key, details]) => {
          return (
            <>
              { renderSubpageLink(key as Pages, details) }
            </>
          )
        })
      }
      </NavMenu.Links>
      </NavMenu>
      <ContentContainer>
        { renderPage(selectedKey) }
      </ContentContainer>
    </Modal.Body>
  )
}