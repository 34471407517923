import {
  GET_COLLABORATION_SUCCESS,
  UPDATE_SHELF_TEXT_TO_UPDATE_SUCCESS,
  GET_CONFIDENCE_SCORE_REQUEST,
  GET_CONFIDENCE_SCORE_SUCCESS,
  GET_CONFIDENCE_SCORE_FAILURE,
  SET_NEW_HIGHLIGHT,
  REMOVE_HIGHLIGHT,
  GET_COLLABORATION_SUMMARY_SUCCESS,
  SET_ACTIVE_ISSUE,
  ACCEPT_ISSUE_SUCCESS,
  REJECT_ISSUE_SUCCESS,
  INVALIDATE_CONFIDENCE_SCORE,
  CANCEL_CONFIDENCE_SCORE_REQUEST,
  EDIT_TASK_COMMENT_SUCCESS,
  ADD_TASK_COMMENT_SUCCESS,
  REMOVE_ACTIVE_ISSUE,
  UPDATE_PLAIN_TEXT_LENGTH,
  UPDATE_SHELF_INFO,
  UPDATE_ALIAS_CARD_SUCCESS,
  UPDATE_SPOKEN_NAME_CARD_SUCCESS,
  UPDATE_PRONUNCIATION_CARD_SUCCESS,
  CREATE_ALIAS_CARD_SUCCESS,
  DELETE_ALIAS_CARD_SUCCESS,
  REJECT_EQUIVALENCIES_CARD_SUCCESS
} from './taskActionTypes';
import collaborationSummaryReducer from './collaborationSummaryReducer';
import localizationReducer from './localizationReducer';
import scoresAndIssuesReducer from './scoresAndIssuesReducer';
import conversationReducer from './conversationReducer';

export default function shelfReducer(state = null, action = {}) {
  switch (action.type) {
    case GET_COLLABORATION_SUCCESS: {
      const collaboration = action.data;
      if (collaboration) {
        const shelfWithConversation = {
          ...state,
          collaborationSummary: collaborationSummaryReducer(state.collaborationSummary, action)
        };
        shelfWithConversation.conversation = collaboration;
        return shelfWithConversation;
      }
      return state;
    }
    case GET_COLLABORATION_SUMMARY_SUCCESS: {
      const shelfWithCollaborationSummary = {
        ...state,
        collaborationSummary: action.collaborationSummary
      };
      return shelfWithCollaborationSummary;
    }
    case ADD_TASK_COMMENT_SUCCESS:
    case EDIT_TASK_COMMENT_SUCCESS:
      return {
        ...state,
        conversation: conversationReducer(state.conversation, action),
        collaborationSummary: collaborationSummaryReducer(state.collaborationSummary, action)
      };
    case SET_NEW_HIGHLIGHT:
    case REMOVE_HIGHLIGHT:
    case UPDATE_PLAIN_TEXT_LENGTH:
    case UPDATE_SHELF_TEXT_TO_UPDATE_SUCCESS: {
      return {
        ...state,
        localization: localizationReducer(state.localization, action)
      };
    }
    case GET_CONFIDENCE_SCORE_REQUEST: {
      const shelfToUpdate = { ...state };
      shelfToUpdate.isLoading = true;
      shelfToUpdate.isError = false;
      shelfToUpdate.scoresAndIssues = 0;
      return shelfToUpdate;
    }
    case GET_CONFIDENCE_SCORE_SUCCESS: {
      if (state.id === action.data.shelfId) {
        const scoresAndIssues = action.data.confidenceScore;
        const shelfToUpdate = { ...state };
        shelfToUpdate.isLoading = false;
        shelfToUpdate.isError = false;
        shelfToUpdate.scoresAndIssues = scoresAndIssues;
        return shelfToUpdate;
      }
      return state;
    }
    case GET_CONFIDENCE_SCORE_FAILURE: {
      const shelfToUpdate = { ...state };
      shelfToUpdate.isError = true;
      shelfToUpdate.isLoading = false;
      return shelfToUpdate;
    }
    case CANCEL_CONFIDENCE_SCORE_REQUEST: {
      if (state.isLoading) {
        return { ...state, isLoading: false };
      }
      return state;
    }
    case SET_ACTIVE_ISSUE:
    case REMOVE_ACTIVE_ISSUE:
    case INVALIDATE_CONFIDENCE_SCORE:
    case ACCEPT_ISSUE_SUCCESS:
    case REJECT_ISSUE_SUCCESS:
      return {
        ...state,
        scoresAndIssues: scoresAndIssuesReducer(state.scoresAndIssues, action)
      };
    case UPDATE_SHELF_INFO:
      return {
        ...state,
        shelfInfos: action.info
      };
    case UPDATE_ALIAS_CARD_SUCCESS: {
      const cards = state.aliases.data;
      const cardIndex = cards.findIndex((card) => card.id === action.card.id);
      return {
        ...state,
        aliases: {
          ...state.aliases,
          data: [...cards.slice(0, cardIndex), action.card, ...cards.slice(cardIndex + 1)]
        }
      };
    }
    case UPDATE_SPOKEN_NAME_CARD_SUCCESS: {
      const cards = state.spokenNames.data;
      const cardIndex = cards.findIndex((card) => card.id === action.card.id);
      return {
        ...state,
        spokenNames: {
          ...state.spokenNames,
          data: [...cards.slice(0, cardIndex), action.card, ...cards.slice(cardIndex + 1)]
        }
      };
    }
    case UPDATE_PRONUNCIATION_CARD_SUCCESS: {
      const cards = state.pronunciation.data;

      const cardIndex = cards.findIndex((card) => card.id === action.card.id);
      return {
        ...state,
        pronunciation: {
          ...state.pronunciation,
          data: [...cards.slice(0, cardIndex), action.card, ...cards.slice(cardIndex + 1)]
        }
      };
    }
    case CREATE_ALIAS_CARD_SUCCESS: {
      const cards = state.aliases.data;
      return {
        ...state,
        aliases: {
          ...state.aliases,
          data: [action.card, ...cards]
        }
      };
    }
    case DELETE_ALIAS_CARD_SUCCESS: {
      const cards = state.aliases.data;
      return {
        ...state,
        aliases: {
          ...state.aliases,
          data: [...cards.filter((card) => card.id !== action.cardId)]
        }
      };
    }
    case REJECT_EQUIVALENCIES_CARD_SUCCESS: {
      const cards = state.equivalencies.data;
      const cardIndex = cards.findIndex((card) => card.id === action.cardId);

      return {
        ...state,
        equivalencies: {
          ...state.equivalencies,
          data: [...cards.slice(0, cardIndex), action.card, ...cards.slice(cardIndex + 1)]
        }
      };
    }
    default:
      return state;
  }
}
