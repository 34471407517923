import {
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE,
  UPDATE_TASK_SUCCESS,
  ADVANCE_TASK_SUCCESS,
  GET_COLLABORATION_SUCCESS,
  GET_COLLABORATIONS_SUCCESS,
  UPDATE_SHELF_TEXT_TO_UPDATE_SUCCESS,
  GET_CONFIDENCE_SCORE_SUCCESS,
  GET_TASK_HISTORY_SUCCESS,
  GET_CONFIDENCE_SCORE_FAILURE,
  SET_NEW_HIGHLIGHT,
  REMOVE_HIGHLIGHT,
  GET_CONFIDENCE_SCORE_REQUEST,
  CLEAR_TASK,
  GET_TASK_CONFIDENCE_SCORE_SUCCESS,
  SET_ACTIVE_ISSUE,
  ACCEPT_ISSUE_SUCCESS,
  REJECT_ISSUE_SUCCESS,
  // INVALIDATE_CONFIDENCE_SCORE,
  // CANCEL_CONFIDENCE_SCORE_REQUEST,
  EDIT_TASK_COMMENT_SUCCESS,
  ADD_TASK_COMMENT_SUCCESS,
  REMOVE_ACTIVE_ISSUE,
  UPDATE_PLAIN_TEXT_LENGTH,
  REMOVE_TASK_CONFIDENCE_SCORE,
  UPDATE_SHELF_INFO,
  UPDATE_ALIAS_CARD_SUCCESS,
  UPDATE_SPOKEN_NAME_CARD_SUCCESS,
  UPDATE_PRONUNCIATION_CARD_SUCCESS,
  CREATE_ALIAS_CARD_SUCCESS,
  DELETE_ALIAS_CARD_SUCCESS,
  REJECT_EQUIVALENCIES_CARD_SUCCESS,
  UPDATE_TASK_REASSIGNMENT_SUCCESS
} from './taskActionTypes';
import remapShelves, { addCollaborationSummaryToShelves } from './taskReducerUtils';

export default function user(state = null, action = {}) {
  switch (action.type) {
    case GET_TASK_SUCCESS:
      return { ...state, ...action.data };
    case GET_TASK_FAILURE:
      return null;
    case ADVANCE_TASK_SUCCESS:
      return { ...state, ...action.data };
    case GET_TASK_CONFIDENCE_SCORE_SUCCESS:
      return { ...state, confidenceScore: action.data };
    case REMOVE_TASK_CONFIDENCE_SCORE:
      return { ...state, confidenceScore: undefined };
    case UPDATE_TASK_REASSIGNMENT_SUCCESS:
      return { ...state, assignee: action.assignee };
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        dueDate: action.task.dueDate,
        priority: action.task.priority
      };
    case GET_TASK_HISTORY_SUCCESS: {
      return { ...state, taskHistory: action.data };
    }
    case CLEAR_TASK: {
      return null;
    }
    // case INVALIDATE_CONFIDENCE_SCORE:
    case GET_COLLABORATION_SUCCESS:
    case UPDATE_SHELF_TEXT_TO_UPDATE_SUCCESS:
    case GET_CONFIDENCE_SCORE_REQUEST:
    case GET_CONFIDENCE_SCORE_SUCCESS:
    case GET_CONFIDENCE_SCORE_FAILURE:
    case SET_NEW_HIGHLIGHT:
    case REMOVE_HIGHLIGHT:
    case SET_ACTIVE_ISSUE:
    case REMOVE_ACTIVE_ISSUE:
    case ACCEPT_ISSUE_SUCCESS:
    case REJECT_ISSUE_SUCCESS:
    case ADD_TASK_COMMENT_SUCCESS:
    case EDIT_TASK_COMMENT_SUCCESS:
    case UPDATE_PLAIN_TEXT_LENGTH:
    case UPDATE_SHELF_INFO:
    case UPDATE_ALIAS_CARD_SUCCESS:
    case UPDATE_SPOKEN_NAME_CARD_SUCCESS:
    case UPDATE_PRONUNCIATION_CARD_SUCCESS:
    case DELETE_ALIAS_CARD_SUCCESS:
    case CREATE_ALIAS_CARD_SUCCESS:
    case REJECT_EQUIVALENCIES_CARD_SUCCESS:
      // Every action that is passed to remapShelves must contain shelfId, otherwise all shelves will be updated
      return { ...state, shelves: remapShelves(state.shelves, action) };
    // case CANCEL_CONFIDENCE_SCORE_REQUEST: {
    //   if (state)
    //     return { ...state, shelves: remapShelves(state.shelves, action) };
    //   return state;
    // }
    case GET_COLLABORATIONS_SUCCESS: {
      return {
        ...state,
        shelves: addCollaborationSummaryToShelves(state.shelves, action)
      };
    }
    default:
      return state;
  }
}
