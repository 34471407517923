/* eslint-disable react/prop-types */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setSortWorklist, setFilterWorklist } from '../../store/queryParams/queryParamsActions';

const QueryWrapper =
  (Component, name) =>
  ({
    storeWorklistSort,
    storeWorklistFilter,
    worklistNextCursor,

    ...props
  }) => {
    let applySort;
    let applyFilter;
    let nextCursor;

    applySort = storeWorklistSort;
    applyFilter = storeWorklistFilter;
    nextCursor = worklistNextCursor;

    return (
      <Component
        applySort={applySort}
        applyFilter={applyFilter}
        nextCursor={nextCursor}
        {...props}
      />
    );
  };

const mapStateToProps = (state) => ({
  worklistNextCursor: state.queryParams.worklist.nextCursor
});

const mapDispatchToProps = {
  storeWorklistSort: setSortWorklist,
  storeWorklistFilter: setFilterWorklist
};

const composedQueryWrapper = compose(connect(mapStateToProps, mapDispatchToProps), QueryWrapper);

export default composedQueryWrapper;
