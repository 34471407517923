export const hostResolver = () => {
  return window.location.hostname.split('.')[0];
};

export const envBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'https://wrkflow.app.local/';
  }

  const domainSet = new Set(['itms5', 'itms7', 'itms8', 'itms11']);

  const curDomain = hostResolver();
  return domainSet.has(curDomain)
    ? `https://${window.location.host}/`
    : process.env.REACT_APP_BASE_URL;
};

export const branchResolver = () => {
  const branch = process.env.REACT_APP_GIT_BRANCH;
  const splitBranch = branch.split('/');

  return splitBranch[1] ? splitBranch[1] : splitBranch[0];
};
