/* eslint-disable no-plusplus */
import { EDIT_TASK_COMMENT_SUCCESS, ADD_TASK_COMMENT_SUCCESS } from './taskActionTypes';

export default function conversationReducer(state = null, action = {}) {
  switch (action.type) {
    case ADD_TASK_COMMENT_SUCCESS: {
      const { data } = action;
      const conversation = [...state];
      if (data.parentId) {
        const parent = conversation.find((element) => element.id === data.parentId);
        if (parent && parent.replies) {
          parent.replies.unshift(data);
        }
      } else {
        conversation.unshift(data);
      }
      return conversation;
    }
    case EDIT_TASK_COMMENT_SUCCESS: {
      const { data } = action;
      const conversation = [...state];
      for (let i = 0; i < conversation.length; i++) {
        if (state[i].id === data.id) {
          conversation[i] = { ...data, replies: conversation[i].replies };
        } else if (conversation[i].replies) {
          for (let j = 0; j < conversation[i].replies.length; j++) {
            if (conversation[i].replies[j].id === data.id) {
              conversation[i].replies[j] = { ...data };
            }
          }
        }
      }
      return conversation;
    }
    default:
      return state;
  }
}
