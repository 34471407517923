import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';

import '../../style/unassignTask.scss';

import i18n from '../../i18n';
import TRANSLATE_KEYS from '../../public/locales/translateKeys';
import RadioButton from '@ui-components/radio-button';

interface Props {
  onUnassignTaskCheckboxChange(): void;
  unassignTask: boolean;
}

const UnassignTask: FC<Props> = ({ onUnassignTaskCheckboxChange, unassignTask }) => {
  return (
    <Grid className="mpe-unassign-task-content">
      <Grid.Row>
        <Grid.Column className="mpe-unassign-task-content-checkbox-column">
          <RadioButton onChange={onUnassignTaskCheckboxChange} checked={unassignTask} />
        </Grid.Column>
        <Grid.Column className="mpe-unassign-task-content-text-column">
          {i18n.t(TRANSLATE_KEYS.reassign_modal.reassign_modal_unassign_task_title)}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="mpe-unassign-task-content-text-column-description">
          {i18n.t(TRANSLATE_KEYS.reassign_modal.reassign_modal_unassign_task_text)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default UnassignTask;
