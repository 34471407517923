"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.correlationKeySelector = exports.componentStackSelector = exports.errorMessageSelector = void 0;
const reselect_1 = require("reselect");
const errorSelector = (state) => {
    return state.error;
};
const errorCodeSelector = reselect_1.createSelector(errorSelector, (error) => error.errorCode);
exports.errorMessageSelector = reselect_1.createSelector(errorSelector, (error) => error.errorMessage);
exports.componentStackSelector = reselect_1.createSelector(errorSelector, (error) => error.componentStack);
exports.correlationKeySelector = reselect_1.createSelector(errorSelector, (error) => error.correlationKey);
exports.default = errorCodeSelector;
