import React, { useCallback, useState } from 'react';

import { Panel, Text, HR } from '@tidbits/react-tidbits';
import GlobalAlertsForm, { AlertDetails } from '../GlobalAlerts/GlobalAlertsForm';
import GlobalAlertsList from '../GlobalAlerts/GlobalAlertsList';
import { useDispatch } from 'react-redux';
import { createAlertRequest } from '../../../../../store/alerts/alertsAction';

const defaultDetails = {
  title: '',
  type: 'WARNING',
  description: ''
};

const GlobalAlertsPage = () => {
  const dispatch = useDispatch();
  const [alertDetails, setAlertDetails] = useState<AlertDetails>({
    title: '',
    type: 'WARNING',
    description: ''
  });

  const handleOnPost = useCallback(() => {
    dispatch(createAlertRequest(alertDetails));
    setAlertDetails({ ...defaultDetails });
  }, [dispatch, alertDetails]);

  const handleOnChange = (value: string, field: string) => {
    const updatedAlertDetails = {
      ...alertDetails,
      [field]: value
    };
    setAlertDetails(updatedAlertDetails);
  };

  return (
    <Panel height={'100%'}>
      <Text sb="spacer15" textStyle="h3Emph">
        Global Alerts
      </Text>
      <HR />
      <GlobalAlertsForm
        alertDetails={alertDetails}
        handleOnChange={handleOnChange}
        handleOnPost={handleOnPost}
      />
      <HR />
      <GlobalAlertsList />
    </Panel>
  );
};

export default GlobalAlertsPage;
