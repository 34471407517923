export class RSQLFilterFormatSimple {
  selector: string;
  comparison: string;
  arguments: string | string[];

  public constructor(selector: string, comparison: string, argumentsToFilter: string | string[]) {
    this.selector = selector;
    this.comparison = comparison;
    this.arguments = argumentsToFilter;
  }
}
