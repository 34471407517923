import React, { FC } from 'react';
import { Modal, Button } from '@tidbits/react-tidbits';

import './modalButtonsBottom.scss';
import i18n from '../../../i18n';
import TRANSLATE_KEYS from '../../../public/locales/translateKeys';

export type SuccessColor = 'standard' | 'destructive';
export interface ModalButtonsBottomProps {
  closeModal?: () => void;
  onSuccess(data?: object): void;
  successLabel?: string;
  closeLabel?: string;
  successColor?: SuccessColor;
  successButtonDisabled?: boolean;
}

const ModalButtonsBottom: FC<ModalButtonsBottomProps> = ({
  closeModal,
  onSuccess,
  successLabel = i18n.t(TRANSLATE_KEYS.submit),
  closeLabel = i18n.t(TRANSLATE_KEYS.cancel_dialog),
  successColor = 'standard',
  successButtonDisabled = false
}) => (
  <Modal.Footer className="mpe-modal-buttons-bottom" id="ModalButtonsBottom">
    {closeModal && (
      <Button
        mr={10}
        mt={0}
        mb={0}
        large
        variant="standard"
        onClick={closeModal}
        id="ModalButtonsBottomClose"
      >
        {closeLabel}
      </Button>
    )}
    {onSuccess && (
      <Button
        disabled={successButtonDisabled}
        variant={successColor}
        large
        mt={0}
        mb={0}
        primary={!successButtonDisabled}
        onClick={onSuccess}
        id="ModalButtonsBottomSuccess"
      >
        {successLabel}
      </Button>
    )}
  </Modal.Footer>
);

export default ModalButtonsBottom;
