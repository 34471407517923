const splitStringAndJoinItWithSpaceOnUppercase = (str) => {
  let result = str.replace(/([A-Z])/g, ' $1').trim();
  return result.replace(/\s+/g, ' ');
};

const mapDisplayNamesToHumanReadableStrings = (str) => {
  if (str === str.toUpperCase()) {
    // ignore if all letters are uppercase
    return str;
  }

  switch (str) {
    case 'TiltStationSet':
      return 'Music Playlist';
    case 'ARItem':
      return 'AR Item';
    default:
      return splitStringAndJoinItWithSpaceOnUppercase(str);
  }
};

export const mapAvailableTermsV2toV1 = (availableTerms) => {
  const availableTermsMap = new Map();
  availableTerms.forEach(({ field, results }) => {
    // TODO: rdar://124631848 code clean up
    if (field === 'classifier_text_general') {
      let resultTokens = new Set();
      Object.keys(results).forEach((result) => {
        if (result) {
          const possibleTokens = result.split(',').map((token) => token.trim());
          resultTokens = new Set([...resultTokens, ...possibleTokens]);
        }
      });
      availableTermsMap.set(
        field,
        Array.from(resultTokens).map((result) => ({ key: result, displayName: result }))
      );
    } else if (field === 'contentType') {
      // if this logic has to be implemented on other fields
      // make sure to create a function that will check for field equality as argument to if condition
      availableTermsMap.set(
        field,
        Object.keys(results).map((result) => ({
          key: result,
          displayName: mapDisplayNamesToHumanReadableStrings(result)
        }))
      );
    } else {
      availableTermsMap.set(
        field,
        Object.keys(results).map((result) => ({ key: result, displayName: result }))
      );
    }
  });
  return Object.fromEntries(availableTermsMap);
};
