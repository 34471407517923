export const GET_TASK = 'GET_TASK';
export const GET_TASK_REQUEST = `${GET_TASK}_REQUEST`;
export const GET_TASK_SUCCESS = `${GET_TASK}_SUCCESS`;
export const GET_TASK_FAILURE = `${GET_TASK}_FAILURE`;

export const GET_COLLABORATION = 'GET_COLLABORATION';
export const GET_COLLABORATION_REQUEST = `${GET_COLLABORATION}_REQUEST`;
export const GET_COLLABORATION_SUCCESS = `${GET_COLLABORATION}_SUCCESS`;
export const GET_COLLABORATION_FAILURE = `${GET_COLLABORATION}_FAILURE`;

export const GET_COLLABORATIONS = 'GET_COLLABORATIONS';
export const GET_COLLABORATIONS_REQUEST = `${GET_COLLABORATIONS}_REQUEST`;
export const GET_COLLABORATIONS_SUCCESS = `${GET_COLLABORATIONS}_SUCCESS`;
export const GET_COLLABORATIONS_FAILURE = `${GET_COLLABORATIONS}_FAILURE`;

export const GET_COLLABORATION_SUMMARY = 'GET_COLLABORATION_SUMMARY';
export const GET_COLLABORATION_SUMMARY_REQUEST = `${GET_COLLABORATION_SUMMARY}_REQUEST`;
export const GET_COLLABORATION_SUMMARY_SUCCESS = `${GET_COLLABORATION_SUMMARY}_SUCCESS`;
export const GET_COLLABORATION_SUMMARY_FAILURE = `${GET_COLLABORATION_SUMMARY}_FAILURE`;

export const ADVANCE_TASK = 'ADVANCE_TASK';
export const ADVANCE_TASK_REQUEST = `${ADVANCE_TASK}_REQUEST`;
export const ADVANCE_TASK_SUCCESS = `${ADVANCE_TASK}_SUCCESS`;
export const ADVANCE_TASK_FAILURE = `${ADVANCE_TASK}_FAILURE`;

export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_REQUEST = `${UPDATE_TASK}_REQUEST`;
export const UPDATE_TASK_SUCCESS = `${UPDATE_TASK}_SUCCESS`;
export const UPDATE_TASK_FAILURE = `${UPDATE_TASK}_FAILURE`;

export const UPDATE_BULK_TASK = 'UPDATE_BULK_TASK';
export const UPDATE_BULK_TASK_REQUEST = `${UPDATE_BULK_TASK}_REQUEST`;
export const UPDATE_BULK_TASK_SUCCESS = `${UPDATE_BULK_TASK}_SUCCESS`;
export const UPDATE_BULK_TASK_FAILURE = `${UPDATE_BULK_TASK}_FAILURE`;

export const UPDATE_TASK_REASSIGNMENT_SUCCESS = `${UPDATE_TASK}_REASSIGNMENT_SUCCESS`;

export const UPDATE_SHELF_TEXT_TO_UPDATE = 'UPDATE_SHELF_TEXT_TO_UPDATE';
export const UPDATE_SHELF_TEXT_TO_UPDATE_REQUEST = `${UPDATE_SHELF_TEXT_TO_UPDATE}_REQUEST`;
export const UPDATE_SHELF_TEXT_TO_UPDATE_SUCCESS = `${UPDATE_SHELF_TEXT_TO_UPDATE}_SUCCESS`;
export const UPDATE_SHELF_TEXT_TO_UPDATE_FAILURE = `${UPDATE_SHELF_TEXT_TO_UPDATE}_FAILURE`;

export const ADD_TASK_COMMENT = 'ADD_TASK_COMMENT';
export const ADD_TASK_COMMENT_REQUEST = `${ADD_TASK_COMMENT}_REQUEST`;
export const ADD_TASK_COMMENT_SUCCESS = `${ADD_TASK_COMMENT}_SUCCESS`;
export const ADD_TASK_COMMENT_FAILURE = `${ADD_TASK_COMMENT}_FAILURE`;

export const EDIT_TASK_COMMENT = 'EDIT_TASK_COMMENT';
export const EDIT_TASK_COMMENT_REQUEST = `${EDIT_TASK_COMMENT}_REQUEST`;
export const EDIT_TASK_COMMENT_SUCCESS = `${EDIT_TASK_COMMENT}_SUCCESS`;
export const EDIT_TASK_COMMENT_FAILURE = `${EDIT_TASK_COMMENT}_FAILURE`;

export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_READ_REQUEST = `${MARK_AS_READ}_REQUEST`;
export const MARK_AS_READ_SUCCESS = `${MARK_AS_READ}_SUCCESS`;
export const MARK_AS_READ_FAILURE = `${MARK_AS_READ}_FAILURE`;

export const GET_CONFIDENCE_SCORE = 'GET_CONFIDENCE_SCORE';
export const GET_CONFIDENCE_SCORE_REQUEST = `${GET_CONFIDENCE_SCORE}_REQUEST`;
export const GET_CONFIDENCE_SCORE_SUCCESS = `${GET_CONFIDENCE_SCORE}_SUCCESS`;
export const GET_CONFIDENCE_SCORE_FAILURE = `${GET_CONFIDENCE_SCORE}_FAILURE`;
export const CANCEL_CONFIDENCE_SCORE_REQUEST = 'CANCEL_CONFIDENCE_SCORE_REQUEST';

export const GET_TASK_CONFIDENCE_SCORE = 'GET_TASK_CONFIDENCE_SCORE';
export const GET_TASK_CONFIDENCE_SCORE_REQUEST = `${GET_TASK_CONFIDENCE_SCORE}_REQUEST`;
export const GET_TASK_CONFIDENCE_SCORE_SUCCESS = `${GET_TASK_CONFIDENCE_SCORE}_SUCCESS`;
export const GET_TASK_CONFIDENCE_SCORE_FAILURE = `${GET_TASK_CONFIDENCE_SCORE}_FAILURE`;

export const REMOVE_TASK_CONFIDENCE_SCORE = 'REMOVE_TASK_CONFIDENCE_SCORE';

export const GET_TASK_HISTORY = 'GET_TASK_HISTORY';
export const GET_TASK_HISTORY_REQUEST = `${GET_TASK_HISTORY}_REQUEST`;
export const GET_TASK_HISTORY_SUCCESS = `${GET_TASK_HISTORY}_SUCCESS`;
export const GET_TASK_HISTORY_FAILURE = `${GET_TASK_HISTORY}_FAILURE`;

export const SET_NEW_HIGHLIGHT = 'SET_NEW_HIGHLIGHT';
export const REMOVE_HIGHLIGHT = 'REMOVE_HIGHLIGHT';

export const SET_ACTIVE_ISSUE = 'SET_ACTIVE_ISSUE';
export const REMOVE_ACTIVE_ISSUE = 'REMOVE_ACTIVE_ISSUE';
export const INVALIDATE_CONFIDENCE_SCORE = 'INVALIDATE_CONFIDENCE_SCORE';
export const UPDATE_PLAIN_TEXT_LENGTH = 'UPDATE_PLAIN_TEXT_LENGTH';

export const UPDATE_TASK_PRIORITY = 'UPDATE_TASK_PRIORITY';

export const ACCEPT_ISSUE = 'ACCEPT_ISSUE';
export const ACCEPT_ISSUE_REQUEST = `${ACCEPT_ISSUE}_REQUEST`;
export const ACCEPT_ISSUE_SUCCESS = `${ACCEPT_ISSUE}_SUCCESS`;
export const ACCEPT_ISSUE_FAILURE = `${ACCEPT_ISSUE}_FAILURE`;

export const REJECT_ISSUE = 'REJECT_ISSUE';
export const REJECT_ISSUE_REQUEST = `${REJECT_ISSUE}_REQUEST`;
export const REJECT_ISSUE_SUCCESS = `${REJECT_ISSUE}_SUCCESS`;
export const REJECT_ISSUE_FAILURE = `${REJECT_ISSUE}_FAILURE`;

export const CLEAR_TASK = 'CLEAR_TASK';

export const UPDATE_SHELF_INFO = 'UPDATE_SHELF_INFO';
export const UPDATE_SHELF_INFO_REQUEST = `${UPDATE_SHELF_INFO}_REQUEST`;
export const UPDATE_SHELF_INFO_SUCCESS = `${UPDATE_SHELF_INFO}_SUCCESS`;
export const UPDATE_SHELF_INFO_FAILURE = `${UPDATE_SHELF_INFO}_FAILURE`;

export const UPDATE_ALIAS_CARD = 'UPDATE_ALIAS_CARD';
export const UPDATE_ALIAS_CARD_REQUEST = `${UPDATE_ALIAS_CARD}_REQUEST`;
export const UPDATE_ALIAS_CARD_SUCCESS = `${UPDATE_ALIAS_CARD}_SUCCESS`;
export const UPDATE_ALIAS_CARD_FAILURE = `${UPDATE_ALIAS_CARD}_FAILURE`;

export const UPDATE_SPOKEN_NAME_CARD = 'UPDATE_SPOKEN_NAME_CARD';
export const UPDATE_SPOKEN_NAME_CARD_REQUEST = `${UPDATE_SPOKEN_NAME_CARD}_REQUEST`;
export const UPDATE_SPOKEN_NAME_CARD_SUCCESS = `${UPDATE_SPOKEN_NAME_CARD}_SUCCESS`;
export const UPDATE_SPOKEN_NAME_CARD_FAILURE = `${UPDATE_SPOKEN_NAME_CARD}_FAILURE`;

export const UPDATE_PRONUNCIATION_CARD = 'UPDATE_PRONUNCIATION_CARD';
export const UPDATE_PRONUNCIATION_CARD_REQUEST = `${UPDATE_PRONUNCIATION_CARD}_REQUEST`;
export const UPDATE_PRONUNCIATION_CARD_SUCCESS = `${UPDATE_PRONUNCIATION_CARD}_SUCCESS`;
export const UPDATE_PRONUNCIATION_CARD_FAILURE = `${UPDATE_PRONUNCIATION_CARD}_FAILURE`;

export const UPDATE_PRONUNCIATION_CARD_SHELF = 'UPDATE_PRONUNCIATION_CARD_SHELF';
export const UPDATE_PRONUNCIATION_CARD_SHELF_REQUEST = `${UPDATE_PRONUNCIATION_CARD_SHELF}_REQUEST`;
export const UPDATE_PRONUNCIATION_CARD_SHELF_SUCCESS = `${UPDATE_PRONUNCIATION_CARD_SHELF}_SUCCESS`;
export const UPDATE_PRONUNCIATION_CARD_SHELF_FAILURE = `${UPDATE_PRONUNCIATION_CARD_SHELF}_FAILURE`;

export const CREATE_ALIAS_CARD = 'CREATE_ALIAS_CARD';
export const CREATE_ALIAS_CARD_REQUEST = `${CREATE_ALIAS_CARD}_REQUEST`;
export const CREATE_ALIAS_CARD_SUCCESS = `${CREATE_ALIAS_CARD}_SUCCESS`;
export const CREATE_ALIAS_CARD_FAILURE = `${CREATE_ALIAS_CARD}_FAILURE`;

export const DELETE_ALIAS_CARD = 'DELETE_ALIAS_CARD';
export const DELETE_ALIAS_CARD_REQUEST = `${DELETE_ALIAS_CARD}_REQUEST`;
export const DELETE_ALIAS_CARD_SUCCESS = `${DELETE_ALIAS_CARD}_SUCCESS`;
export const DELETE_ALIAS_CARD_FAILURE = `${DELETE_ALIAS_CARD}_FAILURE`;

export const RESERVE_PRONUNCIATION = 'RESERVE_PRONUNCIATION';
export const RESERVE_PRONUNCIATION_REQUEST = `${RESERVE_PRONUNCIATION}_REQUEST`;
export const RESERVE_PRONUNCIATION_SUCCESS = `${RESERVE_PRONUNCIATION}_SUCCESS`;
export const RESERVE_PRONUNCIATION_FAILURE = `${RESERVE_PRONUNCIATION}_FAILURE`;

export const COMMIT_PRONUNCIATION = 'COMMIT_PRONUNCIATION';
export const COMMIT_PRONUNCIATION_REQUEST = `${COMMIT_PRONUNCIATION}_REQUEST`;
export const COMMIT_PRONUNCIATION_SUCCESS = `${COMMIT_PRONUNCIATION}_SUCCESS`;
export const COMMIT_PRONUNCIATION_FAILURE = `${COMMIT_PRONUNCIATION}_FAILURE`;

export const REJECT_EQUIVALENCIES_CARD = 'REJECT_EQUIVALENCIES_CARD';
export const REJECT_EQUIVALENCIES_CARD_REQUEST = `${REJECT_EQUIVALENCIES_CARD}_REQUEST`;
export const REJECT_EQUIVALENCIES_CARD_SUCCESS = `${REJECT_EQUIVALENCIES_CARD}_SUCCESS`;
export const REJECT_EQUIVALENCIES_CARD_FAILURE = `${REJECT_EQUIVALENCIES_CARD}_FAILURE`;
